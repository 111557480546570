import { useHistory } from "react-router-dom";
import {
  Typography as MuiTypography,
  Card as MuiCard,
  CardActionArea as MuiCardActionArea,
  CardActions as MuiCardActions,
  CardContent as MuiCardContent,
  Tooltip as MuiTooltip,
  Slide as MuiSlide,
  Button as MuiButton,
} from "@material-ui/core";

import { requestInvoices } from "../../../../../../../../../../utils/apiRequests";

import { useGlobalState } from "../../../../../../../../../../hooks";

export default function Templates(props) {
  const history = useHistory();
  const { globalState, setGlobalState } = useGlobalState();
  return (
    <div className={"w-100 px-3 my-4"}>
      <MuiTypography variant={"h3"} className={"text-muted mb-3"}>
        {"Templates"}
      </MuiTypography>
      <div className={"row"}>
        {props.invoices.map((item, index) => {
          return (
            <MuiSlide
              key={index}
              direction={"left"}
              in={true}
              timeout={200 * index}
            >
              <div className={"my-3 col-sm-12 col-md-6 col-lg-4 col-xl-3"}>
                <MuiCard className={"p-3"} elevation={0}>
                  <MuiCardActionArea>
                    <MuiCardContent>
                      <MuiTooltip title={item.title}>
                        <MuiTypography variant={"h4"} noWrap={true}>
                          {item.title}
                        </MuiTypography>
                      </MuiTooltip>
                    </MuiCardContent>
                  </MuiCardActionArea>
                  <MuiCardActions>
                    <MuiButton
                      variant={"contained"}
                      size={"small"}
                      disableElevation={true}
                      className={"mx-1 rounded-0"}
                      onClick={() => {
                        history.push(`/invoices/${item._id}?type=template`);
                      }}
                    >
                      {"View"}
                    </MuiButton>
                    <MuiButton
                      variant={"contained"}
                      size={"small"}
                      disableElevation={true}
                      className={"mx-1 rounded-0"}
                      onClick={() => {
                        requestInvoices.templates
                          .deleteInvoiceByID(item._id)
                          .then((data) => {
                            alert("invoice deleted successfully");
                            requestInvoices.templates
                              .findAllInvoices()
                              .then((data) => {
                                setGlobalState((globalState) => {
                                  globalState.invoices.template = [...data];
                                  return {
                                    ...globalState,
                                  };
                                });
                              });
                          })
                          .catch((error) => {
                            alert(error);
                          });
                      }}
                    >
                      {"Delete"}
                    </MuiButton>
                    <MuiButton
                      variant={"contained"}
                      className={"mx-1 rounded-0"}
                      disableElevation={true}
                      size={"small"}
                      onClick={() => {
                        history.push(`/invoices/${item._id}`);
                      }}
                    >
                      {"Issue"}
                    </MuiButton>
                  </MuiCardActions>
                </MuiCard>
              </div>
            </MuiSlide>
          );
        })}
      </div>
    </div>
  );
}
