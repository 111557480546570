export const sampleFormState = {
  id: "jdq9qd8qdqd",
  firstName: "joshua",
  lastName: "ilechukwu",
  gender: "male",
  role: "student",
  class: "primary 1",
  emailAddress: "ilechuks73@gmail.com",
  phoneNumber: "232432423434",
  homeAddress:
    "sample home address for ilechukwu joshua in the testing environment",
};
