import { useState } from "react";
import {
  TextField as MuiTexField,
  Button as MuiButton,
  InputAdornment,
} from "@mui/material";

import { FindInPage as MuiFindInPageIcon } from "@mui/icons-material";

import services from "../../../../../../../services";
let debounceTimer;

export default function StudentSearch(props) {
  return (
    <div>
      <MuiTexField
        placeholder={"Search students here..."}
        size={"small"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MuiFindInPageIcon />
            </InputAdornment>
          ),
        }}
        className={"outline-none py-0"}
        onChange={(event) => {
          clearTimeout(debounceTimer);
          if (event.target.value.length > 2) {
            props.setMode("search");
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
              services.api.students
                .search(event.target.value)
                .then((data) => {
                  props.setStudents(data.data.students);
                })
                .catch((error) => {
                  alert(error.message);
                });
            }, 1000);
          } else {
            props.setMode("default");
          }
        }}
      />
    </div>
  );
}
