import img6 from "../../../../../../../assets/images/img6.png";
import img4 from "../../../../../../../assets/images/img4.png";

export const _NWR4434R = [
  // {
  //   label: "Messages",
  //   count() {
  //     return 0;
  //   },
  // },
  // {
  //   label: "Notifications",
  //   count() {
  //     return 0;
  //   },
  // },
];

export const _FJ4R34R = [
  // {
  //   label: "CLASS",
  //   image: img6,
  //   handleClick(globalState, userState, history) {
  //     const selectedClass = globalState.classes.find((_class, index) => {
  //       const selectedTeacher = _class.teachers.find((teacher, index) => {
  //         if (teacher === userState._id) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       });
  //       return selectedTeacher;
  //     });
  //     if (selectedClass) {
  //       setTimeout(() => {
  //         history.push(
  //           `/class/dashboard?ID=${selectedClass._id}&accessMode=teacher`
  //         );
  //       }, 1000);
  //     } else {
  //       alert("You are not assigned to a class");
  //     }
  //   },
  // },
  {
    label: "SIGN OUT",
    image: img4,
    handleClick(globalState, userState, history) {
      history.push("/signin");
    },
  },
];
