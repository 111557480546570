import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Fab as MuiFab,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Checkbox as MuiCheckBox,
} from "@material-ui/core";

import { GenerateInvoiceDialog, Templates } from "./components";

import { useGlobalState } from "../../../../../../../../hooks";

import {
  requestInvoices,
  requestFee__getAllFees,
} from "../../../../../../../../utils/apiRequests";

import { Add } from "@material-ui/icons";

import "./styles.css";

export default function Invoices() {
  const history = useHistory();
  const { globalState, setGlobalState } = useGlobalState();
  const [state, setState] = useState({
    GenerateInvoiceDialog: {
      isOpen: false,
      open: function () {
        setState({
          ...state,
          GenerateInvoiceDialog: {
            ...state.GenerateInvoiceDialog,
            isOpen: true,
          },
        });
      },
      close: function () {
        setState({
          ...state,
          GenerateInvoiceDialog: {
            ...state.GenerateInvoiceDialog,
            isOpen: false,
          },
        });
      },
    },
    fees: [],
    deleteFee: function (invoiceID) {
      requestInvoices.deleteInvoiceByID(invoiceID).then((data) => {
        const newState = { ...globalState };
        newState.fees = newState.fees.filter((item) => {
          return item._id !== invoiceID;
        });
        setGlobalState({ ...newState });
      });
    },
  });

  useEffect(() => {
    requestInvoices.findAllInvoices().then((data) => {
      setGlobalState((globalState) => {
        globalState.invoices.default = [...data];
        return {
          ...globalState,
        };
      });
    });

    requestInvoices.templates.findAllInvoices().then((data) => {
      setGlobalState((globalState) => {
        globalState.invoices.template = [...data];
        return {
          ...globalState,
        };
      });
    });

    if (globalState.fees.length < 1) {
      requestFee__getAllFees().then((data) => {
        if (data.length > 0) {
          setGlobalState((globalState) => {
            return {
              ...globalState,
              fees: data,
            };
          });
        }
        console.log(data);
      });
    }
  }, []);

  return (
    <MuiGrid className={"Invoices"}>
      <Templates invoices={globalState.invoices.template} />
      <MuiTable stickyHeader={true} className={"w-100 table table-striped"}>
        <MuiTableHead>
          <MuiTableRow>
            <MuiTableCell>
              <MuiCheckBox indeterminate={true} />
            </MuiTableCell>
            <MuiTableCell>{"Invoice ID"}</MuiTableCell>
            {/* <MuiTableCell>{"Issuee's Full Name"}</MuiTableCell> */}
            <MuiTableCell>{"Date Issued"}</MuiTableCell>
            <MuiTableCell>{"Payment Status"}</MuiTableCell>
            <MuiTableCell>{"Actions"}</MuiTableCell>
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          {globalState.invoices.default.map((item, index) => {
            return (
              <MuiTableRow key={index}>
                <MuiTableCell>
                  <MuiCheckBox />
                </MuiTableCell>
                <MuiTableCell>{`${item._id}`}</MuiTableCell>
                {/* <MuiTableCell>
                  {item.issuedTo
                    ? `${item.issuedTo.firstName} ${item.issuedTo.lastName}`
                    : "N/A"}
                </MuiTableCell> */}
                <MuiTableCell>{`${item.issuedAt.split("T")[0]}`}</MuiTableCell>
                <MuiTableCell>{`${item.status}`}</MuiTableCell>
                <MuiTableCell>
                  <button
                    className={"btn btn-sm btn-primary mx-1"}
                    onClick={() => {
                      history.push(`/invoices/${item._id}?type=default`);
                    }}
                  >
                    {"View"}
                  </button>
                  <button
                    className={"btn btn-sm btn-danger mx-1"}
                    onClick={() => {
                      requestInvoices
                        .deleteInvoiceByID(item._id)
                        .then((data) => {
                          requestInvoices.findAllInvoices().then((data) => {
                            if (data.length > 0) {
                              setGlobalState((globalState) => {
                                globalState.invoices.default = [...data];
                                return {
                                  ...globalState,
                                };
                              });
                            }
                          });
                          alert("invoice deleted successfully");
                        })
                        .catch((error) => {
                          alert(error);
                        });
                    }}
                  >
                    {"Delete"}
                  </button>
                </MuiTableCell>
              </MuiTableRow>
            );
          })}
        </MuiTableBody>
      </MuiTable>
      <MuiFab
        color={"primary"}
        className={"MuiFab1"}
        onClick={() => {
          state.GenerateInvoiceDialog.open();
        }}
      >
        <Add />
      </MuiFab>
      <GenerateInvoiceDialog state={state.GenerateInvoiceDialog} />
    </MuiGrid>
  );
}
