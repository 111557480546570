import state from ".";

function GlobalState(props) {
  return (
    <state.ComponentsState>
      <state.AdministratorState>{props.children}</state.AdministratorState>
    </state.ComponentsState>
  );
}

export default GlobalState;
