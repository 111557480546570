import { ThemeProvider, createMuiTheme } from "@material-ui/core";

export function ThemeState(props) {
  const theme = createMuiTheme({
    overrides: {
      // MuiButton: {
      //   root: {
      //     textTransform: "none",
      //   },
      //   contained:{
      //     backgroundColor: "green",
      //     "&:hover":{
      //       backgroundColor: "yellow"
      //     }
      //   }
      //},
    },
    // palette: {
    //   primary: {

    //   },
    //   green:{

    //   }
    // }
  });
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
