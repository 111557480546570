import { object, string } from "yup";

export const validationSchema = object({
  firstName: string().required("First Name is required!"),
  lastName: string().required("Last Name is required!"),
  gender: string().required("You didn't select a gender!"),
  image: string().required("Please select an image for upload!"),
  password: string().required("You need to create a password!"),
  repeatPassword: string().required("Password fields don't match!"),
  class: string(),
  address: string().required("Address is required!"),
  maritalStatus: string().required("Please select a marital status!"),
});
