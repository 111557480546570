import { Switch, Route, Redirect } from "react-router-dom";
import {
  Overview,
  Notifications,
  Announcements,
  Results,
  Invoices,
  Profile,
} from "./pages";
import { useUserState } from "../../../../hooks";

export default function Dashboard() {
  const { userState, setUserState } = useUserState();

  return (
    <>
      {userState ? (
        <Switch>
          <Route
            exact
            path={"/student/dashboard/overview"}
            component={Overview}
          />
          <Route
            exact
            path={"/student/dashboard/notifications"}
            component={Notifications}
          />
          <Route path={"/student/dashboard/results"} component={Results} />
          <Route
            path={"/student/dashboard/announcements"}
            component={Announcements}
          />
          <Route path={"/student/dashboard/profile"} component={Profile} />
          <Route
            exact
            path={"/student/dashboard/invoices"}
            component={Invoices}
          />
        </Switch>
      ) : (
        <Redirect to={"/signin"} />
      )}
    </>
  );
}
