import { useContext } from "react";

import contexts from "../contexts";

function useComponents(props) {
  const [componentsState, setComponentsState] = useContext(
    contexts.componentsContext
  );

  const loaderV1 = {
    open: function () {
      setComponentsState((componentsState) => {
        return { ...componentsState, loaderV1: { isOpen: true } };
      });
    },
    close: function () {
      setComponentsState((componentsState) => {
        return { ...componentsState, loaderV1: { isOpen: false } };
      });
    },
  };

  const sidebarV1 = {
    open: function () {
      setComponentsState((componentsState) => {
        return { ...componentsState, sidebarV1: { isOpen: true } };
      });
    },
    close: function () {
      setComponentsState((componentsState) => {
        return { ...componentsState, sidebarV1: { isOpen: false } };
      });
    },
  };

  const alertV1 = {
    open: function (message = "", severity = "info") {
      setComponentsState((componentsState) => {
        return {
          ...componentsState,
          alertV1: { isOpen: true, message },
        };
      });
    },
    close: function () {
      setComponentsState((componentsState) => {
        return {
          ...componentsState,
          alertV1: { isOpen: false, message: "", severity: "info" },
        };
      });
    },
  };

  const dialogV1 = {
    open: function (content, actions) {
      setComponentsState((componentsState) => {
        return {
          ...componentsState,
          dialogV1: { isOpen: true, content, actions },
        };
      });
    },
    close: function () {
      setComponentsState((componentsState) => {
        return { ...componentsState, dialogV1: { isOpen: false } };
      });
    },
  };

  return {
    componentsState,
    setComponentsState,
    loaderV1,
    sidebarV1,
    alertV1,
    dialogV1,
  };
}

export default useComponents;
