import { useState } from "react";
import {
  Typography as MuiTypography,
  TextField as MuiTextField,
  Grid as MuiGrid,
  Button as MuiButton,
} from "@mui/material";

export default function ProfileTab() {
  return (
    <div className={"w-[100%] p-[20px]"}>
      <MuiGrid container={true} spacing={2}>
        <MuiGrid item={true} xs={4}>
          <MuiTypography>First name</MuiTypography>
          <MuiTextField variant={"outlined"} className={"w-[100%]"} />
        </MuiGrid>
        <MuiGrid item={true} xs={4}>
          <MuiTypography>Last name</MuiTypography>
          <MuiTextField variant={"outlined"} className={"w-[100%]"} />
        </MuiGrid>
        <MuiGrid item={true} xs={4}>
          <MuiTypography>Email Address</MuiTypography>
          <MuiTextField
            variant={"outlined"}
            disabled={true}
            className={"w-[100%]"}
          />
        </MuiGrid>
        <MuiGrid item={true} xs={12}>
          <MuiTypography>Phone number</MuiTypography>
          <MuiTextField variant={"outlined"} className={"w-[100%]"} />
        </MuiGrid>
        <MuiGrid item={true} xs={12}>
          <MuiTypography>Home address</MuiTypography>
          <MuiTextField variant={"outlined"} className={"w-[100%]"} />
        </MuiGrid>
      </MuiGrid>
      <div className={"mt-[50px]"}>
        <MuiButton variant={"contained"}>{"Save changes"}</MuiButton>
      </div>
    </div>
  );
}
