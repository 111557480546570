import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalState } from "../../../../../../hooks";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Typography as MuiTypography,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableFooter as MuiTableFooter,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  Paper as MuiPaper,
  Checkbox as MuiCheckbox,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
} from "@material-ui/core";
import {
  Skeleton as MuiSkeleton,
  Avatar as MuiAvatar,
  TablePagination as MuiTablePagination,
} from "@mui/material";

import services from "../../../../../../services";

import { LayoutV2 } from "../../../../components";

import {
  Cached as MuiCachedIcon,
  Preview as MuiPreviewIcon,
  DeleteForever as MuiDeleteForeverIcon,
} from "@mui/icons-material";

import { ResultSearch, ResultFilter } from "./components";

function trffe(selectedResults, result) {
  const val = selectedResults.some(
    (selectedResult) => selectedResult["_id"] === result._id
  );

  console.log(val);
  return val;
}

function Results() {
  const history = useHistory();
  const [mode, setMode] = useState("default");
  const [loadingStates, setLoadingStates] = useState([true]);
  const { globalState, setGlobalState } = useGlobalState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [selectedResults, setSelectedResults] = useState([]);
  const [results, setResults] = useState([]);
  const [totalNumberOfResults, setTotalNumberOfResults] = useState(0);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (mode === "default") {
      setLoadingStates((loadingStates) => {
        let newLoadingStates = [...loadingStates];
        newLoadingStates[0] = true;
        return [...newLoadingStates];
      });
      services.api.results
        .findAll(page, count)
        .then((data) => {
          setResults(data.data.results);
          setTotalNumberOfResults(data.data.totalCount);
          setLoadingStates((loadingStates) => {
            let newLoadingStates = [...loadingStates];
            newLoadingStates[0] = false;
            return [...newLoadingStates];
          });
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }, [page, count, mode]);

  useEffect(() => {
    if (mode === "filter") {
      setLoadingStates((loadingStates) => {
        let newLoadingStates = [...loadingStates];
        newLoadingStates[0] = true;
        return [...newLoadingStates];
      });
      services.api.results
        .find(filters, page, count)
        .then((data) => {
          setResults(data.data.results);
          setTotalNumberOfResults(data.data.totalCount);
          setLoadingStates((loadingStates) => {
            let newLoadingStates = [...loadingStates];
            newLoadingStates[0] = false;
            return [...newLoadingStates];
          });
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }, [page, count, filters]);

  return (
    <LayoutV2
      title={"Results"}
      actions={
        <>
          {/*<MuiButton*/}
          {/*  size={"small"}*/}
          {/*  variant={"contained"}*/}
          {/*  className={*/}
          {/*    "rounded-sm font-poppins text-[14px] font-light text-capitalize"*/}
          {/*  }*/}
          {/*  startIcon={<MuiAddBoxIcon />}*/}
          {/*  onClick={() => {*/}
          {/*    history.push("/student/create");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {"create"}*/}
          {/*</MuiButton>*/}
        </>
      }
    >
      <MuiGrid container={true} item={true}>
        <MuiGrid className={"px-[30px] mt-[30px] w-[100%]"}>
          <MuiTableContainer component={MuiPaper}>
            <div
              className={
                "pl-[25px] pr-[25px] py-[10px] flex items-center justify-between"
              }
            >
              {selectedResults.length < 1 ? (
                <>
                  <ResultSearch setMode={setMode} setResults={setResults} />
                </>
              ) : (
                <MuiTypography
                  className={"text-[25px] font-poppins font-light"}
                >
                  {`${selectedResults.length} selected`}
                </MuiTypography>
              )}

              <div>
                {selectedResults.length === 1 && (
                  <MuiButton
                    size={"small"}
                    variant={"contained"}
                    className={
                      "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                    }
                    startIcon={<MuiDeleteForeverIcon />}
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you really sure you want to delete this result?"
                        )
                      ) {
                        services.api.results
                          .deleteOne(selectedResults[0]._id)
                          .then((data) => {
                            alert(data.message);
                          })
                          .catch((error) => {
                            alert(error.message);
                          });
                      }
                    }}
                  >
                    {"Delete"}
                  </MuiButton>
                )}
                {selectedResults.length === 1 && (
                  <MuiButton
                    size={"small"}
                    variant={"contained"}
                    className={
                      "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                    }
                    startIcon={<MuiPreviewIcon />}
                    onClick={() => {
                      history.push(`/results/${selectedResults[0]._id}`);
                    }}
                  >
                    {"View"}
                  </MuiButton>
                )}
                {selectedResults.length === 1 && (
                  <MuiButton
                    size={"small"}
                    variant={"contained"}
                    className={
                      "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                    }
                    startIcon={<MuiPreviewIcon />}
                    onClick={() => {
                      history.push(
                        `/admin/dashboard/student?ID=${selectedResults[0].student._id.replace(
                          /\//g,
                          "-"
                        )}`
                      );
                    }}
                  >
                    {"View Student"}
                  </MuiButton>
                )}
                <MuiButton
                  size={"small"}
                  variant={"contained"}
                  className={
                    "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                  }
                  startIcon={<MuiCachedIcon />}
                  onClick={() => {
                    setLoadingStates((loadingStates) => {
                      let newLoadingStates = [...loadingStates];
                      newLoadingStates[0] = true;
                      return [...newLoadingStates];
                    });

                    services.api.results
                      .findAll(page, count)
                      .then((data) => {
                        setResults(data.data.results);
                        setTotalNumberOfResults(data.data.totalCount);
                        setLoadingStates((loadingStates) => {
                          let newLoadingStates = [...loadingStates];
                          newLoadingStates[0] = false;
                          return [...newLoadingStates];
                        });
                      })
                      .catch((error) => {
                        alert(error.message);
                      });
                    setPage(1);
                  }}
                >
                  {"Reload"}
                </MuiButton>
              </div>
            </div>
            <ResultFilter
              setMode={setMode}
              setFilters={setFilters}
              setPage={setPage}
            />
            <MuiTable>
              <MuiTableHead>
                <MuiTableRow>
                  <MuiTableCell>
                    <MuiCheckbox disabled={true} />
                  </MuiTableCell>
                
                  <MuiTableCell colSpan={2}>Student</MuiTableCell>
                  <MuiTableCell>Class</MuiTableCell>
                  <MuiTableCell>Type</MuiTableCell>
                  <MuiTableCell>Session</MuiTableCell>
                  <MuiTableCell>Term</MuiTableCell>
                  <MuiTableCell>Status</MuiTableCell>
                </MuiTableRow>
              </MuiTableHead>
              <MuiTableBody>
                {results.map((item, index) => {
                  if (loadingStates[0] === true) {
                    return (
                      <MuiTableRow key={index}>
                        <MuiTableCell>
                          <MuiCheckbox checked={false} disabled={true} />
                        </MuiTableCell>  
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                      </MuiTableRow>
                    );
                  } else {
                    return (
                      <MuiTableRow key={index} className={"cursor-pointer"}>
                        <MuiTableCell>
                          {trffe(selectedResults, item) === true ? (
                            <MuiCheckbox
                              checked={true}
                              onClick={(event) => {
                                setSelectedResults((selectedResults) => {
                                  let state = [...selectedResults];
                                  state = state.filter((item2) => {
                                    return item._id !== item2._id;
                                  });
                                  return state;
                                });
                              }}
                            />
                          ) : (
                            <MuiCheckbox
                              checked={false}
                              onClick={(event) => {
                                setSelectedResults([...selectedResults, item]);
                              }}
                            />
                          )}
                        </MuiTableCell> 
                        <MuiTableCell className={"text-capitalize"}>
                          {item?.student.firstName}
                        </MuiTableCell>
                        <MuiTableCell className={"text-capitalize"}>
                          {item?.student.lastName}
                        </MuiTableCell>{" "}
                        <MuiTableCell>
                          <MuiTypography
                            noWrap={true}
                            className={"text-[14px]"}
                          >
                            {item?.class?.name}
                          </MuiTypography>
                        </MuiTableCell>
                        {item.type === "midTerm" ? (
                          <MuiTableCell>
                            <MuiTypography
                              noWrap={true}
                              className={"text-[14px]"}
                            >
                              Mid Term
                            </MuiTypography>
                          </MuiTableCell>
                        ) : (
                          <MuiTableCell className={""}>
                            <MuiTypography
                              noWrap={true}
                              className={"text-[14px]"}
                            >
                              End of Term
                            </MuiTypography>
                          </MuiTableCell>
                        )}
                        <MuiTableCell>{item.session}</MuiTableCell>
                        <MuiTableCell>{item.term}</MuiTableCell>
                        {item.isApproved ? (
                          <MuiTableCell className={"text-[green]"}>
                            Approved
                          </MuiTableCell>
                        ) : (
                          <MuiTableCell className={"text-[red]"}>
                            Not Approved
                          </MuiTableCell>
                        )}
                      </MuiTableRow>
                    );
                  }
                })}
              </MuiTableBody>
              <MuiTableFooter>
                <MuiTableRow>
                  {mode === "default" || mode === "filter" ? (
                    <MuiTablePagination
                      rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                      colSpan={8}
                      count={totalNumberOfResults}
                      rowsPerPage={count}
                      page={page - 1}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={(event, page) => {
                        if (loadingStates[0] === true) {
                          return false;
                        }
                        setPage(page + 1);
                      }}
                      onRowsPerPageChange={(event) => {
                        setPage(1);
                        setCount(parseInt(event.target.value));
                      }}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  ) : (
                    ""
                  )}
                </MuiTableRow>
              </MuiTableFooter>
            </MuiTable>
          </MuiTableContainer>
        </MuiGrid>
      </MuiGrid>
    </LayoutV2>
  );
}

export default Results;
