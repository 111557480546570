import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import img16 from "../../../../../../../assets/images/img16.jpg";

import {
  Grid as MuiGrid,
  Button as MuiButton,
  Paper as MuiPaper,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Avatar as MuiAvatar,
  List as MuiList,
  ListItem as MuiListItem,
  Zoom as MuiZoom,
} from "@material-ui/core";

import { AvatarGroup as MuiAvatarGroup } from "@material-ui/lab";

import "./styles.scss";

import { _H38D9D3 } from "./script";
import {
  useUserState,
  useClassState,
  useGlobalState,
} from "../../../../../../hooks";

import { requestAnnouncements } from "../../../../../../utils/apiRequests";

export default function Overview() {
  const { globalState, setGlobalState } = useGlobalState();
  const { userState } = useUserState();
  const history = useHistory();
  const { classState } = useClassState();

  const [announcements, setAnnouncements] = useState(globalState.announcements);

  useEffect(() => {
    if (globalState.announcements.length === 0) {
      requestAnnouncements
        .findAllAnnouncements()
        .then((data) => {
          setGlobalState((globalState) => {
            return {
              ...globalState,
              announcements: [...data],
            };
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, []);

  useEffect(() => {
    setAnnouncements((announcements) => {
      return [...globalState.announcements];
    });
  }, [globalState]);

  return (
    <MuiGrid container={true} justify={"center"} className={"Class__Overview"}>
      <MuiGrid container={true} className={"px-3 pb-3"}>
        <MuiZoom in={true} timeout={400}>
          <MuiGrid
            className={"MuiGrid1 position-static mx-3 my-3 overflow-hidden"}
            style={{ height: 300 }}
          >
            <MuiTypography
              variant={"h3"}
              className={"text-white position-absolute p-3"}
            >
              {classState.name}
            </MuiTypography>
            <img src={img16} alt={"class jumbotron"} className={"img1 w-100"} />
          </MuiGrid>
        </MuiZoom>
      </MuiGrid>
      <MuiGrid container={true} className={"px-3"}>
        {_H38D9D3.map((item, index) => {
          return (
            <MuiZoom in={true} timeout={600}>
              <MuiGrid item={true} xs={3} className={"p-3"} key={index}>
                <MuiPaper className={"p-3"}>
                  <MuiTypography noWrap={true} variant={"h5"}>
                    {item.label}
                  </MuiTypography>
                  <MuiTypography noWrap={true} variant={"h2"}>
                    {item.count(classState, announcements)}
                  </MuiTypography>
                </MuiPaper>
              </MuiGrid>
            </MuiZoom>
          );
        })}
      </MuiGrid>
      <MuiGrid container={true} className={"px-3"}>
        <MuiZoom in={true} timeout={600}>
          <MuiGrid item={true} xs={3} className={"d-flex flex-column h-100"}>
            <MuiGrid className={"pt-4 px-3"}>
              <MuiTypography variant={"h5"} color={"primary"}>
                {"Announcements"}
              </MuiTypography>
              <MuiDivider />
            </MuiGrid>
            <MuiGrid item={true} xs={12} className={"py-4 px-3 flex-grow-1"}>
              <MuiList>
                {announcements.length === 0 ? (
                  <MuiTypography variant={"h5"} className={"text-muted"}>
                    {"No announcements to display"}
                  </MuiTypography>
                ) : (
                  globalState.announcements?.map((item, index) => {
                    if (index < 3) {
                      return (
                        <MuiListItem key={index} className="d-block w-100 p-0">
                          <MuiTypography className={"w-100 fs-18-px"}>
                            {item.title}
                          </MuiTypography>
                          <MuiTypography
                            className={"w-100 fs-14-px text-muted"}
                          >
                            {item.message}
                          </MuiTypography>
                        </MuiListItem>
                      );
                    }
                  })
                )}
              </MuiList>
            </MuiGrid>
            <MuiGrid className={"w-100"}>
              <MuiButton
                className={"w-100"}
                onClick={() => {
                  history.push("/class/dashboard/announcements");
                }}
              >
                {"View All announcements"}
              </MuiButton>
            </MuiGrid>
          </MuiGrid>
        </MuiZoom>
        <MuiGrid item={true} xs={9} className={"h-100"}>
          <MuiZoom in={true} timeout={800}>
            <MuiGrid className={"d-flex flex-column"}>
              <MuiGrid className={"pt-4 px-3"}>
                <MuiTypography variant={"h5"} color={"primary"}>
                  {"Students"}
                </MuiTypography>
                <MuiDivider />
              </MuiGrid>
              <MuiGrid container={true} className={"flex-grow-1 p-3"}>
                <MuiAvatarGroup max={10}>
                  {classState.students.map((item, index) => {
                    return (
                      <MuiAvatar>
                        <img
                          alt={"student profile phot"}
                          src={`${
                            process.env.REACT_APP_API_BASE_URL
                          }/uploads/images/profile/${item._id.replaceAll(
                            "/",
                            "-"
                          )}.jpg`}
                          className={"w-100"}
                        />
                      </MuiAvatar>
                    );
                  })}
                </MuiAvatarGroup>
              </MuiGrid>
              <MuiGrid className={"w-100"}>
                <MuiButton
                  className={"w-100"}
                  onClick={() => {
                    history.push("/class/dashboard/students");
                  }}
                >
                  {"View All students"}
                </MuiButton>
              </MuiGrid>
            </MuiGrid>
          </MuiZoom>
          <MuiZoom in={true} timeout={800}>
            <MuiGrid className={"d-flex flex-column"}>
              <MuiGrid className={"pt-4 px-3"}>
                <MuiTypography variant={"h5"} color={"primary"}>
                  {"Teachers"}
                </MuiTypography>
                <MuiDivider />
              </MuiGrid>
              <MuiGrid container={true} className={"flex-grow-1 p-3"}>
                <MuiAvatarGroup max={10}>
                  {classState.teachers.map((item, index) => {
                    return (
                      <MuiAvatar>
                        <img
                          alt={"teacher profile phot"}
                          src={`${
                            process.env.REACT_APP_API_BASE_URL
                          }/uploads/images/profile/${item._id.replaceAll(
                            "/",
                            "-"
                          )}.jpg`}
                          className={"w-100"}
                        />
                      </MuiAvatar>
                    );
                  })}
                </MuiAvatarGroup>
              </MuiGrid>
              <MuiGrid className={"w-100"}>
                <MuiButton
                  className={"w-100"}
                  onClick={() => {
                    history.push("/class/dashboard/teachers");
                  }}
                >
                  {"View All TEACHERS"}
                </MuiButton>
              </MuiGrid>
            </MuiGrid>
          </MuiZoom>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}
