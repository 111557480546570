import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Header, Spreadsheet } from "./components";
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Paper as MuiPaper,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  Button as MuiButton,
  Divider as MuiDivider,
  Snackbar as MuiSnackbar,
  TextareaAutosize as MuiTextareaAutosize,
  CircularProgress as MuiCircularProgress,
  Chip as MuiChip,
} from "@material-ui/core";

import { Spreadsheet as RsSpreadsheet } from "react-spreadsheet";

import {
  requestAddResult,
  requestTerm,
  requestSession,
  requestClasses,
} from "../../../../utils/apiRequests";
import { useGlobalState, useUserState } from "../../../../hooks";

import { _A1, _A2, _B1 } from "./scripts";

import services from "../../../../services";

export default function Update() {
  const history = useHistory();
  const { userState } = useUserState();
  const [_class, setClass] = useState({ subjects: [] });

  const [session, setSession] = useState("");
  const [term, setTerm] = useState("");
  const [resultType, setResultType] = useState("endOfTerm");
  const [spreadsheetData, setSpreadsheetData] = useState({
    midTerm: {
      columns: ["Subject", "Score"],
      rows: [],
    },
    endOfTerm: {
      columns: ["Subject", "Test", "Exam"],
      rows: [],
    },
  });

  const [electivesData, setElectivesData] = useState([]);

  const [submitProgress, setSubmitProgress] = useState(false);

  const [formState, setFormState] = useState(_A2);

  const [wizardDialog, setWizardDialog] = useState(_A1);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
    handleClose: function () {
      setSnackbarState({
        ...snackbarState,
        open: false,
        message: "",
        severity: "",
      });
    },
    handleOpen: function (message, severity) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: message,
        severity: severity,
      });
    },
  });

  useEffect(() => {
    services.api.results
      .findOne(new URLSearchParams(window.location.search).get("id"))
      .then((data) => {
        setFormState(data.data.result);

        setFormState((formState) => {
          let newState = { ...formState };
          let scoreSheet;

          if (data.data.result.type === "midTerm") {
            scoreSheet = JSON.parse(data.data.result.scoreSheet).map((item) =>
              item.splice(0, 2)
            );
          }

          if (data.data.result.type === "endOfTerm") {
            scoreSheet = JSON.parse(data.data.result.scoreSheet).map((item) =>
              item.splice(0, 3)
            );
          }

          newState.scoreSheet = JSON.stringify(scoreSheet);
          return { ...newState };
        });

        setSpreadsheetData((spreadsheetData) => {
          let newState = { ...spreadsheetData };
          if (data.data.result.type === "midTerm") {
            newState[data.data.result.type].rows = JSON.parse(
              data.data.result.scoreSheet
            )
              .splice(1)
              .map((item) => item.splice(0, 2));
          }

          if (data.data.result.type === "endOfTerm") {
            newState[data.data.result.type].rows = JSON.parse(
              data.data.result.scoreSheet
            )
              .splice(1)
              .map((item) => item.splice(0, 3));
          }
          return { ...newState };
        });

        // setElectivesData((electivesData) => {
        //   let newState = [...electivesData];
        //   console.log(data.data.electives);
        //   newState = [
        //     data.data.results?.electives?.map((item) => [
        //       { value: item.title },
        //       item.grade,
        //     ]),
        //   ];
        //   console.log("electivessss", newState);
        //   return [];
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    _B1(
      userState,
      history,
      requestTerm,
      requestSession,
      setTerm,
      setFormState,
      setSession
    );
  }, []);

  useEffect(() => {
    let data = [];
    electivesData.forEach((elective, index) => {
      data.push({ title: elective[0].value, grade: elective[1].value });
    });
    setFormState({ ...formState, electives: data });
  }, [electivesData]);

  useEffect(() => {}, [formState]);

  return (
    <MuiGrid container={true} justifyContent={"center"} className={"mt-5 pt-5"}>
      <Header />
      <MuiGrid item={true} xs={11} sm={9} md={8} lg={7} xl={6}>
        <MuiPaper elevation={8} className={"mb-5 px-3 py-4"}>
          <MuiTypography variant={"h5"}>
            Please fill in all details correctly
          </MuiTypography>
          <MuiDivider className={"mb-3"} />
          <MuiTypography className={"mt-3"}>School</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={formState.school}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, school: e.target.value });
            }}
          >
            <MuiMenuItem value={"Park British School"}>
              {"Park British School"}
            </MuiMenuItem>
            <MuiMenuItem value={"Park Royal College"}>
              {"Park Royal College"}
            </MuiMenuItem>
          </MuiSelect>
          <MuiTypography className={"mt-3"}>Title</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={formState.title}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, title: e.target.value });
            }}
          >
            <MuiMenuItem
              value={"PROVISIONAL RESULTS INFORMATION FOR SPRING TERM TEST"}
            >
              {"PROVISIONAL RESULTS INFORMATION FOR SPRING TERM TEST"}
            </MuiMenuItem>
            <MuiMenuItem
              value={
                "PROVISIONAL RESULTS INFORMATION FOR SPRING TERM EXAMINATION"
              }
            >
              {"PROVISIONAL RESULTS INFORMATION FOR SPRING TERM EXAMINATION"}
            </MuiMenuItem>
            <MuiMenuItem
              value={"PROVISIONAL RESULTS INFORMATION FOR AUTUMN TERM TEST"}
            >
              {"PROVISIONAL RESULTS INFORMATION FOR AUTUMN TERM TEST"}
            </MuiMenuItem>
            <MuiMenuItem
              value={
                "PROVISIONAL RESULTS INFORMATION FOR AUTUMN TERM EXAMINATION"
              }
            >
              {"PROVISIONAL RESULTS INFORMATION FOR AUTUMN TERM EXAMINATION"}
            </MuiMenuItem>
            <MuiMenuItem
              value={"PROVISIONAL RESULTS INFORMATION FOR SUMMER TERM TEST"}
            >
              {"PROVISIONAL RESULTS INFORMATION FOR SUMMER TERM TEST"}
            </MuiMenuItem>
            <MuiMenuItem
              value={
                "PROVISIONAL RESULTS INFORMATION FOR SUMMER TERM EXAMINATION"
              }
            >
              {"PROVISIONAL RESULTS INFORMATION FOR SUMMER TERM EXAMINATION"}
            </MuiMenuItem>
          </MuiSelect>

          <MuiTypography className={"mt-2"}>Student ID</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            value={formState.student?._id}
            disabled
          />
          <MuiTypography className={"mt-3"}>Class</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            value={formState.class?.name}
            disabled
          />
          <MuiTypography className={"mt-3"}>Session</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            value={formState.session}
          />
          <MuiTypography className={"mt-3"}>Term</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            value={formState.term}
          />
          <MuiDivider />
          <MuiTypography className={"mt-3"}>Grading Scale</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={formState.gradingScale}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, gradingScale: e.target.value });
            }}
          >
            <MuiMenuItem value={"foundation"}>{"Foundation"}</MuiMenuItem>
            <MuiMenuItem value={"pbs"}>{"PBS"}</MuiMenuItem>
            <MuiMenuItem value={"prc"}>{"PRC-junior"}</MuiMenuItem>
            <MuiMenuItem value={"igcse"}>{"IGCSE"}</MuiMenuItem>
            <MuiMenuItem value={"waec"}>{"WAEC"}</MuiMenuItem>
            <MuiMenuItem value={"aslevel"}>{"AS LEVEL"}</MuiMenuItem>
          </MuiSelect>
          <MuiTypography className={"mt-3"}>Result Type</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={formState.type}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, type: e.target.value });
              setResultType(e.target.value);
            }}
            disabled={true}
          >
            <MuiMenuItem value={"midTerm"}>{"Mid-Term"}</MuiMenuItem>
            <MuiMenuItem value={"endOfTerm"}>{"Full-Term"}</MuiMenuItem>
          </MuiSelect>

          {_class ? (
            <Spreadsheet
              scoresheetData={spreadsheetData}
              setScoresheetData={(data) => {
                setFormState({
                  ...formState,
                  scoreSheet: JSON.stringify(data),
                });
                setSpreadsheetData((spreadsheetData) => {
                  // console.log("SPSHEET DATA", data.slice(1));
                  let newState = { ...spreadsheetData };
                  newState.endOfTerm.rows = data.slice(1);
                  return { ...newState };
                });
              }}
              type={formState.type}
            />
          ) : (
            ""
          )}

          {_class
            ? _class?.subjects?.map((item, index) => {
                return (
                  <MuiChip
                    key={index}
                    label={item}
                    className={"me-1 mb-2"}
                    onDelete={() => {
                      setSpreadsheetData((spreadsheetData) => {
                        return {
                          ...spreadsheetData,
                          endOfTerm: {
                            ...spreadsheetData.endOfTerm,
                            rows: [
                              ...spreadsheetData.endOfTerm.rows.filter(
                                (item2, index2) => {
                                  return item2[0].value !== item;
                                }
                              ),
                            ],
                          },
                          midTerm: {
                            ...spreadsheetData.midTerm,
                            rows: [
                              ...spreadsheetData.midTerm.rows.filter(
                                (item2, index2) => {
                                  return item2[0].value !== item;
                                }
                              ),
                            ],
                          },
                        };
                      });

                      setClass((_class) => {
                        return {
                          ..._class,
                          subjects: _class.subjects.filter(
                            (value, index2, arr) => {
                              return value !== item;
                            }
                          ),
                        };
                      });
                    }}
                  />
                );
              })
            : ""}
          <MuiDivider />
          {/* <MuiTypography variant={"h5"}>Electives</MuiTypography>
          <RsSpreadsheet
            data={electivesData}
            onChange={(data) => {
              setElectivesData(data);
            }}
            columnLabels={["Title", "Grade"]}
          />
          <MuiGrid className={"my-2"}>
            <MuiButton
              size={"small"}
              onClick={() => {
                setElectivesData([
                  ...electivesData,
                  [{ value: "" }, { value: "" }],
                ]);
              }}
            >
              {"Add"}
            </MuiButton>
            <MuiButton
              size={"small"}
              onClick={() => {
                let state = [...electivesData];
                state.pop();
                setElectivesData([...state]);
              }}
            >
              {"Remove"}
            </MuiButton>
          </MuiGrid> */}

          <MuiDivider />
          <MuiTypography className={"mt-3"}>Teacher's Remark</MuiTypography>
          <MuiTextareaAutosize
            className={"w-100 form-control"}
            variant={"outlined"}
            type={"text"}
            minRows={5}
            value={formState.teachersRemark || ""}
            onChange={(e) => {
              setFormState({ ...formState, teachersRemark: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Comments</MuiTypography>
          <MuiTextareaAutosize
            className={"w-100 form-control"}
            variant={"outlined"}
            type={"text"}
            minRows={5}
            value={formState.comments || ""}
            onChange={(e) => {
              setFormState({ ...formState, comments: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Principal's Remark</MuiTypography>
          <MuiTextareaAutosize
            className={"w-100 form-control"}
            variant={"outlined"}
            type={"text"}
            minRows={5}
            value={formState.principalsRemark || ""}
            disabled={localStorage.getItem("administrator") ? false : true}
            onChange={(e) => {
              setFormState({ ...formState, principalsRemark: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Resumption Date</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            onChange={(e) => {
              setFormState({ ...formState, resumptionDate: e.target.value });
            }}
          />
          <MuiDivider />
          <MuiButton
            className={"mt-3"}
            color={"primary"}
            variant={"contained"}
            disabled={submitProgress}
            onClick={() => {
              setSubmitProgress(true);

              console.log(formState);

              services.api.results
                .updateOne(
                  new URLSearchParams(window.location.search).get("id"),
                  "update",
                  {
                    ...formState,
                    class: formState.class._id,
                    student: formState.student._id,
                  }
                )
                .then((data) => {
                  // if (data.error) {
                  //   snackbarState.handleOpen(
                  //     `Something went wrong, Try again`,
                  //     "error"
                  //   );
                  //   setTimeout(() => {
                  //     setSubmitProgress(false);
                  //   }, 2000);
                  // } else {
                  //   snackbarState.handleOpen(
                  //     `Result succesfully uploaded.`,
                  //     "success"
                  //   );
                  setTimeout(() => {
                    setSubmitProgress(false);
                  }, 2000);
                  // }
                  alert(data.message);
                })
                .catch((error) => {
                  alert(error.message);
                  setTimeout(() => {
                    setSubmitProgress(false);
                  }, 2000);
                });
            }}
          >
            {submitProgress ? (
              <MuiCircularProgress size={24} color="secondary" />
            ) : (
              "upload"
            )}
          </MuiButton>
        </MuiPaper>
      </MuiGrid>

      <MuiSnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarState.open}
        message={snackbarState.message}
        action={
          <>
            <MuiButton
              color="secondary"
              size="small"
              onClick={snackbarState.handleClose}
            >
              {"CLOSE"}
            </MuiButton>
          </>
        }
      />
    </MuiGrid>
  );
}
