import React from "react";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
  Typography as MuiTypography,
} from "@material-ui/core";

import { Home as MuiHomeIcon } from "@material-ui/icons";

export default function Breadcrumbs(props) {
  return (
    <MuiBreadcrumbs seperator={">"}>
      <MuiLink>
        <MuiHomeIcon />
        <MuiTypography variant={"inherit"}>{"Home"}</MuiTypography>
      </MuiLink>
      <MuiLink>
        <MuiTypography variant={"inherit"}>{"Dashboard"}</MuiTypography>
      </MuiLink>
      {props.paths.map((path, index) => {
        return (
          <MuiLink key={index}>
            <MuiTypography>{path.label}</MuiTypography>
          </MuiLink>
        );
      })}
    </MuiBreadcrumbs>
  );
}
