import { useEffect, useState } from "react";
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
} from "@material-ui/core";
import services from "../../../../../../../services";

export default function ResultMetrics() {
  const [student, setStudent] = useState({});
  useEffect(() => {
    services.api.students
      .findOne(localStorage.getItem("student"))
      .then((data) => {
        setStudent(data.data.student);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);

  return (
    <MuiGrid item={true} xs={12} sm={6} md={4} lg={3} xl={2}>
      <MuiPaper elevation={0} square className={"px-[20px] h-100 py-[20px]"}>
        <MuiTypography
          noWrap={true}
          className={"font-poppins text-[25px] font-light"}
        >
          {"Results"}
        </MuiTypography>
        <MuiTypography noWrap={true} variant="h3">
          {student.results?.length}
        </MuiTypography>
      </MuiPaper>
    </MuiGrid>
  );
}
