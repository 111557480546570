import React from "react";
import { Grid as MuiGrid } from "@material-ui/core";
import { useScreenBreakpoint } from "../../../../hooks";

import HeaderV1 from "../HeaderV1";
import SidebarV1 from "../SidebarV1";

export default function LayoutV1(props) {
  return (
    <MuiGrid
      container={true}
      className={"h-[100vh] overflow-hidden bg-[#ebeef2]"}
    >
      <SidebarV1 />

      <MuiGrid
        item={true}
        wrap={"nowrap"}
        xs={12}
        sm={12}
        md={10}
        lg={10}
        xl={10}
        className="relative"
      >
        <HeaderV1 />
        <MuiGrid
          item={true}
          className={"h-screen overflow-auto pt-[60px] pb-[100px]"}
        >
          {props.children}
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}
