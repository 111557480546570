import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Button as MuiButton,
  TextField as MuiTextField,
} from "@material-ui/core";

import { Paper as MuiPaper, Avatar as MuiAvatar } from "@mui/material";

import { LayoutV1 } from "../../../../../../components";

import { Edit as MuiEditIcon } from "@material-ui/icons";

import services from "../../../../../../../../services";

export default function Root() {
  const history = useHistory();
  const [administrator, setAdministrator] = useState({});
  useEffect(() => {
    services.api.administrators
      .findOne(new URLSearchParams(window.location.search).get("id"))
      .then((data) => {
        setAdministrator(data.data.administrator);
      })
      .catch((error) => {
        alert(`Error: ${error.message}`);
      });
  }, []);

  return (
    <LayoutV1>
      <MuiGrid
        container={true}
        justifyContent={"center"}
        className={"mt-[30px]"}
      >
        <MuiGrid item={true} xs={9}>
          <MuiPaper className={"p-[30px]"}>
            <MuiGrid container={true} className={""}>
              <MuiGrid
                container={true}
                item={true}
                xl={12}
                lg={12}
                className={"px-3 my-4"}
              >
                <MuiGrid
                  container={true}
                  item={true}
                  xl={2}
                  lg={3}
                  md={5}
                  sm={8}
                  xs={11}
                >
                  <label htmlFor={"image_update"}>
                    <MuiAvatar
                      className={""}
                      src={administrator.image}
                      className={"h-[200px] w-[200px] cursor-pointer"}
                    />
                  </label>

                  <MuiTextField
                    variant={"outlined"}
                    type={"file"}
                    multiple={false}
                    id={"image_update"}
                    className={"hidden"}
                    onChange={(event) => {
                      const selectedFile = event.target.files[0];
                      if (selectedFile) {
                        const reader = new FileReader();

                        reader.onload = function (event) {
                          const base64Data = event.target.result.split(",")[1];
                          services.api.administrators.single
                            .update(
                              new URLSearchParams(window.location.search).get(
                                "id"
                              ),
                              "update_image",
                              { image: base64Data }
                            )
                            .then((data) => {
                              alert(data.message);
                              services.api.administrators
                                .findOne(
                                  new URLSearchParams(
                                    window.location.search
                                  ).get("id")
                                )
                                .then((data) => {
                                  setAdministrator(data.data.administrator);
                                })
                                .catch((error) => {
                                  alert(`Error: ${error.message}`);
                                });
                            })
                            .catch((error) => {
                              alert(error.message);
                            });
                        };
                        reader.readAsDataURL(selectedFile);
                      }
                    }}
                  />
                </MuiGrid>

                <MuiGrid
                  container={true}
                  item={true}
                  xl={8}
                  lg={7}
                  md={5}
                  sm={8}
                  xs={11}
                  direction={"column"}
                >
                  <MuiTypography variant={"h3"} noWrap={true}>
                    {`${
                      administrator.firstName?.charAt(0).toUpperCase() +
                      administrator.firstName?.slice(1)
                    } ${
                      administrator.lastName?.charAt(0).toUpperCase() +
                      administrator.lastName?.slice(1)
                    }`}
                  </MuiTypography>
                  <MuiTypography variant={"h4"} className={"text-primary"}>
                    {`${administrator.role}`}
                  </MuiTypography>
                </MuiGrid>
              </MuiGrid>
              <MuiGrid
                container={true}
                item={true}
                xl={12}
                lg={12}
                className={"my-4"}
              >
                <MuiTable>
                  <MuiTableBody>
                    <MuiTableRow>
                      <MuiTableCell>Administrator ID</MuiTableCell>
                      <MuiTableCell>{administrator._id}</MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>First Name</MuiTableCell>
                      <MuiTableCell>{administrator.firstName}</MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>Last Name</MuiTableCell>
                      <MuiTableCell>{administrator.lastName}</MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>Gender</MuiTableCell>
                      <MuiTableCell>{administrator.gender}</MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>Email Address</MuiTableCell>
                      <MuiTableCell>{administrator.email}</MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>Phone Number</MuiTableCell>
                      <MuiTableCell>{administrator.phoneNumber}</MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>Home Address</MuiTableCell>
                      <MuiTableCell>{administrator.homeAddress}</MuiTableCell>
                    </MuiTableRow>
                  </MuiTableBody>
                </MuiTable>
                <MuiGrid className={"my-5 w-100"}>
                  <MuiButton
                    variant={"contained"}
                    startIcon={<MuiEditIcon />}
                    onClick={() =>
                      history.push(
                        `/admin/dashboard/administrator/update?id=${new URLSearchParams(
                          window.location.search
                        ).get("id")}`
                      )
                    }
                  >
                    {"Update Profile"}
                  </MuiButton>
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </MuiPaper>
        </MuiGrid>
      </MuiGrid>
    </LayoutV1>
  );
}
