import { useState } from "react";
import { Tabs as MuiTabs, Tab as MuiTab } from "@mui/material";

export default function NavigationTab(props) {
  const [tab, setTab] = useState("profile");
  console.log(props.children);
  return (
    <div>
      <MuiTabs
        value={tab}
        onChange={(event, value) => {
          setTab(value);
        }}
      >
        <MuiTab value={"timeline"} label={"Timeline"}></MuiTab>
        <MuiTab value={"profile"} label={"Profile"}></MuiTab>
        <MuiTab
          value={"password_and_security"}
          label={"Password & Security"}
        ></MuiTab>
      </MuiTabs>
      {tab === "timeline" && props.children[0]}
      {tab === "profile" && props.children[1]}
      {tab === "password_and_security" && props.children[2]}
    </div>
  );
}
