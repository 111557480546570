import {
  Button as MuiButton,
  Divider as MuiDivider,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Modal as MuiModal,
  Paper as MuiPaper,
  Select as MuiSelect,
  Typography as MuiTypography,
} from "@material-ui/core";
import { IconButton as MuiIconButton } from "@mui/material";
import { Edit as MuiEditIcon } from "@mui/icons-material";
import { useState, useEffect } from "react";
import services from "../../../../../../../services";

export default function Term() {
  const [mode, setMode] = useState("default");
  const [term, setTerm] = useState("n/a");
  useEffect(() => {
    services.api.variables
      .get("term")
      .then((data) => {
        setTerm(data.data.variable.value);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return (
    <>
      <MuiGrid item={true} xs={12} sm={6} md={4} lg={3} xl={2}>
        <MuiPaper
          square
          elevation={0}
          className={"p-[10px] sm:p-[20px] flex justify-between sm:block h-100"}
        >
          <MuiTypography
            noWrap={true}
            className={"font-poppins text-[25px] font-light"}
          >
            {"Term"}
          </MuiTypography>
          <div className={"flex items-center"}>
            <MuiTypography
              noWrap={true}
              className={"flex-grow-1 text-[25px] sm:text-[50px]"}
            >
              {term}
            </MuiTypography>
            <MuiIconButton
              onClick={() => {
                setMode("set");
              }}
            >
              <MuiEditIcon className={"text-[18px] sm:text-[24px]"} />
            </MuiIconButton>
          </div>
        </MuiPaper>
      </MuiGrid>
      <CreateTermModal
        open={mode === "set"}
        handleClose={() => {
          setMode("default");
        }}
      />
    </>
  );
}

function CreateTermModal(props) {
  const [formState, setFormState] = useState({ term: "autumn" });
  return (
    <MuiModal open={props.open} onClose={props.handleClose}>
      <MuiGrid
        container
        justifyContent={"center"}
        alignItems={"center"}
        className={"h-100"}
      >
        <MuiGrid
          item={true}
          className={"bg-white p-3"}
          xs={10}
          sm={8}
          md={5}
          lg={3}
        >
          <MuiDivider />
          <h3>SET CURRENT TERM</h3>
          <MuiDivider className={"mb-3"} />
          <MuiGrid>
            <MuiSelect
              fullWidth={true}
              id="class-select"
              value={formState.term}
              variant={"outlined"}
              onChange={(e) => {
                setFormState({ ...formState, term: e.target.value });
              }}
            >
              <MuiMenuItem value={"autumn"}>{"Autumn"}</MuiMenuItem>
              <MuiMenuItem value={"summer"}>{"Summer"}</MuiMenuItem>
              <MuiMenuItem value={"spring"}>{"Spring"}</MuiMenuItem>
            </MuiSelect>
          </MuiGrid>
          <MuiDivider className={"mt-3 mb-2"} />
          <MuiButton
            size={"small"}
            className={"bg-danger me-3 text-white"}
            onClick={props.handleClose}
          >
            Cancel
          </MuiButton>
          <MuiButton
            size={"small"}
            className={"bg-primary text-white"}
            onClick={() => {
              if (formState.term.length < 1) {
                alert("Select a term");
                return false;
              }
              services.api.variables
                .set("term", { value: formState.term })
                .then((data) => {
                  alert(data.message);
                })
                .catch((error) => {
                  alert(error.message);
                });
            }}
          >
            Proceed
          </MuiButton>
          <MuiDivider className={"mt-2"} />
        </MuiGrid>
      </MuiGrid>
    </MuiModal>
  );
}
