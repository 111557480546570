import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  CircularProgress as MuiCircularProgress,
  Typography as MuiTypography,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Button as MuiButton,
} from "@material-ui/core";

import { Edit as MuiEditIcon } from "@material-ui/icons";

import { Header } from "./components";
import { requestTeacher } from "../../utils/apiRequests";
import { useGlobalState } from "../../hooks";
import { useUserState } from "../../hooks/";

export default function TeacherProfile() {
  const params = useParams();
  const history = useHistory();
  const { userState } = useUserState();
  const { globalState, getClasses } = useGlobalState();
  const [profileData, setProfileData] = useState(null);
  useEffect(() => {
    getClasses();
    requestTeacher(params.teacherID).then((data) => setProfileData(data));
  }, []);
  useEffect(() => {
    console.log(profileData);
  }, [profileData]);

  function getTeachersClass(teacherID) {
    const selectedClass = globalState.classes.find((_class, index) => {
      const selectedTeacher = _class.teachers.find((student, index) => {
        if (student._id === teacherID) {
          return true;
        } else {
          return false;
        }
      });
      return selectedTeacher;
    });
    if (selectedClass) {
      return selectedClass.name;
    } else {
      return "Not Assigned";
    }
  }

  return (
    <MuiGrid
      container={true}
      justify={"center"}
      className={"position-absolute w-100 pt-4 mt-5"}
    >
      <Header title={"Teacher Profile"} />
      {profileData ? (
        <MuiGrid container={true} item={true} xs={7} className={"bg-light"}>
          <MuiGrid container={true} justify={"center"}>
            <MuiGrid
              container={true}
              className={"rounded-circle overflow-hidden"}
              style={{
                minWidth: "300px",
                maxWidth: "300px",
                height: "300px",
              }}
            >
              <img
                src={`${
                  process.env.REACT_APP_API_BASE_URL
                }/uploads/images/profile/${profileData._id.replaceAll(
                  "/",
                  "-"
                )}.jpg`}
                alt={"avatar"}
                className={"w-100"}
              />
            </MuiGrid>
            <MuiGrid
              container={true}
              item={true}
              xs={6}
              direction={"column"}
              justify={"center"}
              className={"px-3"}
            >
              <MuiTypography variant={"h3"} className={"fw-bold"}>
                {`${
                  profileData.firstName.charAt(0).toUpperCase() +
                  profileData.firstName.slice(1)
                } ${
                  profileData.lastName.charAt(0).toUpperCase() +
                  profileData.lastName.slice(1)
                }`}
              </MuiTypography>
              <MuiTypography variant={"h4"} className={"fw-bold text-primary"}>
                {`${profileData.role}`}
              </MuiTypography>
              <MuiTypography
                variant={"body1"}
                className={`${
                  profileData.status === "active"
                    ? "text-success"
                    : "text-danger"
                }`}
              >
                {`${profileData.status.toUpperCase()}`}
              </MuiTypography>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container={true} className={""}>
            <MuiTable>
              <MuiTableBody>
                <MuiTableRow>
                  <MuiTableCell>Teacher ID</MuiTableCell>
                  <MuiTableCell>{profileData._id}</MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>First Name</MuiTableCell>
                  <MuiTableCell>{profileData.firstName}</MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>Last Name</MuiTableCell>
                  <MuiTableCell>{profileData.lastName}</MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>Email</MuiTableCell>
                  <MuiTableCell>{profileData.email}</MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>Gender</MuiTableCell>
                  <MuiTableCell>{profileData.gender}</MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>Class</MuiTableCell>
                  <MuiTableCell>
                    {getTeachersClass(profileData._id)}
                  </MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>Address</MuiTableCell>
                  <MuiTableCell>{profileData.address}</MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>Marital Status</MuiTableCell>
                  <MuiTableCell>{profileData.maritalStatus}</MuiTableCell>
                </MuiTableRow>
              </MuiTableBody>
            </MuiTable>
            <MuiGrid className={"my-3"}>
              {userState.role === "admin" ? (
                <MuiButton
                  variant={"contained"}
                  startIcon={<MuiEditIcon />}
                  onClick={() =>
                    history.push(
                      `/teachers/profile/${profileData._id.replaceAll(
                        "/",
                        "-"
                      )}/edit`
                    )
                  }
                >
                  {"Edit Profile"}
                </MuiButton>
              ) : (
                ""
              )}
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      ) : (
        <MuiGrid>
          <MuiCircularProgress />
        </MuiGrid>
      )}
    </MuiGrid>
  );
}
