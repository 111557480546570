import { useState } from "react";

import contexts from "../contexts";

function ComponentsState(props) {
  const inititalComponentsState = {
    loaderV1: {
      isOpen: false,
    },
    alertV1: {
      isOpen: false,
      message: "",
      severity: "info",
    },
    sidebarV1: {
      isOpen: false,
    },
    dialogV1: {
      isOpen: false,
    },
  };

  const [componentsState, setComponentsState] = useState(
    inititalComponentsState
  );

  return (
    <contexts.componentsContext.Provider
      value={[componentsState, setComponentsState]}
    >
      {props.children}
    </contexts.componentsContext.Provider>
  );
}

export default ComponentsState;
