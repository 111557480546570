import { useState } from "react";
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Paper as MuiPaper,
  Button as MuiButton,
  Divider as MuiDivider,
  Snackbar as MuiSnackbar,
} from "@material-ui/core";

import { Header } from "./components";

import { requestAddClass } from "../../utils/apiRequests";

export default function CreateClass() {
  const [formState, setFormState] = useState({});
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
    handleClose: function () {
      setSnackbarState({
        ...snackbarState,
        open: false,
        message: "",
        severity: "",
      });
    },
    handleOpen: function (message, severity) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: message,
        severity: severity,
      });
    },
  });

  return (
    <MuiGrid
      container={true}
      justify={"center"}
      className={"position-absolute w-100 h-100 py-5 my-5"}
    >
      <Header title={"Create Class"} />
      <MuiGrid item={true} xs={10} sm={9} md={8} lg={7} xl={6}>
        <MuiPaper className={"p-3"}>
          <MuiTypography variant={"h5"}>Please fill correctly</MuiTypography>
          <MuiDivider className={"mb-5"} />
          <MuiTypography className={"mt-3"}>Class Name</MuiTypography>
          <MuiTextField
            fullWidth={true}
            id={"first-name-inout"}
            size={"small"}
            type={"text"}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, name: e.target.value });
            }}
          />
          <MuiDivider className={"my-4"} />
          <MuiButton
            className={"mb-3"}
            color={"primary"}
            variant={"contained"}
            onClick={() => {
              requestAddClass(formState).then((data) => {
                if (data.error) {
                  snackbarState.handleOpen(
                    `Something went wrong, Try again`,
                    "error"
                  );
                } else {
                  snackbarState.handleOpen(
                    `Class added successfully`,
                    "success"
                  );
                }
              });
            }}
          >
            Submit
          </MuiButton>
        </MuiPaper>
      </MuiGrid>

      <MuiSnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarState.open}
        message={snackbarState.message}
        action={
          <>
            <MuiButton
              color="secondary"
              size="small"
              onClick={snackbarState.handleClose}
            >
              {"CLOSE"}
            </MuiButton>
          </>
        }
      />
    </MuiGrid>
  );
}
