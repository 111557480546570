import { useState, useEffect } from "react";
import {
  Box as MuiBox,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Typography as MuiTypography,
  Avatar as MuiAvatar,
  IconButton as MuiIconButton,
} from "@mui/material";
import services from "../../../../../../../services";
import {
  BlockOutlined as MuiBlockOutlinedIcon,
  DeleteOutlined as MuiDeleteOutlinedIcon,
  VisibilityOutlined as MuiVisibilityOutlinedIcon,
} from "@mui/icons-material";

export default function ManageTeachers() {
  const [teachers, setTeachers] = useState([]);
  useEffect(() => {
    services.api.students
      .findAll(1, 10)
      .then((data) => {
        setTeachers(data.data.students);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return (
    <MuiGrid item={true} xs={12}>
      <MuiPaper elevation={0} className={"h-[100%]] rounded-0"}>
        <MuiBox className={"px-[30px] py-[10px]"}>
          <MuiTypography className={"font-poppins font-light text-[16px]"}>
            Manage Teachers
          </MuiTypography>
        </MuiBox>
        <MuiDivider className={"bg-[black]"} />
        <MuiBox className={`px-[30px]`}>
          {teachers.map((student, index) => {
            return (
              <MuiGrid
                container={true}
                alignItems={"center"}
                className={"w-[100%] py-[8px]"}
              >
                <MuiGrid item={true} xs={1}>
                  <MuiTypography>{index + 1}</MuiTypography>
                </MuiGrid>
                <MuiGrid
                  item={true}
                  xs={2}
                  className={"px-[5px] overflow-x-hidden"}
                >
                  <MuiAvatar
                    src={
                      student.image
                        ? student.image
                        : `https://eportal.parkbritishschool.com/api/uploads/images/profile/${student._id.replaceAll(
                            "/",
                            "-"
                          )}.jpg`
                    }
                  />
                </MuiGrid>
                <MuiGrid item={true} xs={3} className={"px-[5px]"}>
                  <MuiTypography
                    noWrap={true}
                    className={"font-dm-sans text-[14px]"}
                  >
                    {`${
                      student.firstName?.charAt(0).toUpperCase() +
                      student.firstName?.slice(1)
                    } ${
                      student.lastName?.charAt(0).toUpperCase() +
                      student.lastName?.slice(1)
                    }`}
                  </MuiTypography>
                </MuiGrid>
                <MuiGrid item={true} xs={3} className={"px-[5px]"}>
                  <MuiTypography
                    noWrap={true}
                    className={
                      "font-dm-sans font-light text-[gray] text-[14px]"
                    }
                  >
                    {student.class?.name ? student.class.name : "Not Assigned"}
                  </MuiTypography>
                </MuiGrid>
                <MuiGrid item={true} xs={3} className={"px-[5px]"}>
                  <div className={"w-[100%] flex justify-end"}>
                    <MuiIconButton className={"p-[5px]"}>
                      <MuiVisibilityOutlinedIcon className={"text-[blue]"} />
                    </MuiIconButton>
                    <MuiIconButton className={"p-[5px]"}>
                      <MuiBlockOutlinedIcon className={"text-[red]"} />
                    </MuiIconButton>
                    <MuiIconButton className={"p-[5px]"}>
                      <MuiDeleteOutlinedIcon className={"text-[black]"} />
                    </MuiIconButton>
                  </div>
                </MuiGrid>
              </MuiGrid>
            );
          })}
        </MuiBox>
      </MuiPaper>
    </MuiGrid>
  );
}
