export const _H38D9D3 = [
  {
    label: "Students",
    count: (classState) => {
      return classState.students.length;
    },
  },
  {
    label: "Teachers",
    count: (classState) => {
      return classState.teachers.length;
    },
  },
  {
    label: "Subjects",
    count: (classState) => {
      return classState.subjects.length;
    },
  },
  {
    label: "Announcements",
    count: (classState, announcements) => {
      return announcements.length;
    },
  },
];

export function _HD334DD4(announcements, classID) {
  return [
    ...announcements.filter((item, index) => {
      return item.visibilty === "class" && item.class === classID;
    }),
  ];
}
