import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Container as MuiContainer,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import { TextField, Avatar } from "@mui/material";
import { useUserState } from "../../../../hooks";
import joi from "joi";

import services from "../../../../services";
import { enqueueSnackbar } from "notistack";

export default function SignIn() {
  const { userState, setUserState } = useUserState();
  const history = useHistory();
  const [formData, setFormData] = useState({ emailAddress: "", password: "" });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState([false]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {}, [formData]);

  return (
    <>
      <div className={"z-[99999] fixed right-2 top-2 w-min"}>
        <div className={"flex gap-2 text-sm font-light"}>
          <p
            onClick={() => {
              history.push("/sign-in");
            }}
            className={"hover:underline cursor-pointer hover:text-blue-600"}
          >
            Student
          </p>
          <p>|</p>
          <p
            onClick={() => {
              history.push("/admin/signin");
            }}
            className={"hover:underline cursor-pointer hover:text-blue-600"}
          >
            Administrator
          </p>
        </div>
      </div>
      <MuiGrid
        container={true}
        direction={"column"}
        alignContent={"center"}
        justifyContent={"center"}
        className={"!fixed !top-0 !bottom-0 !right-0 !left-0 !bg-gray-300"}
      >
        <MuiContainer
          className={"!min-w-[250px] !max-w-[350px]"}
          disableGutters={true}
        >
          <Avatar
            variant={"rounded"}
            src={"https://avatars.githubusercontent.com/u/95208501?s=200&v=4"}
            className={"!w-[80px] !h-[80px] mx-auto !border !border-gray-400"}
          />
          <MuiPaper className={"!px-6 !py-3 !mt-6"}>
            <MuiGrid
              className={""}
              container={true}
              direction={"column"}
              alignContent={"center"}
            >
              <p className={"text-center text-lg text-gray-500"}>{"Welcome"}</p>
              <p className={"text-center text-gray-500 font-light"}>
                {"Please enter your sign-in credentials."}
              </p>
              <p className={"text-sm mt-6"}>Email address</p>
              <TextField
                className={"my-2"}
                size={"small"}
                value={formData.emailAddress}
                placeholder={"Enter email address here"}
                type={"text"}
                error={!!errors[0]}
                variant={"outlined"}
                helperText={errors[0] || ""}
                onChange={(e) => {
                  setFormData((formData) => {
                    formData.emailAddress = e.target.value;
                    return { ...formData };
                  });
                }}
              />
              <p className={"text-sm mt-3"}>Password</p>

              <TextField
                variant={"outlined"}
                className={""}
                error={!!errors[1]}
                helperText={errors[0] || ""}
                size={"small"}
                placeholder={"Enter password..."}
                value={formData.password}
                type={passwordVisibility ? "text" : "password"}
                onChange={(e) => {
                  setFormData((formData) => {
                    formData.password = e.target.value;
                    return { ...formData };
                  });
                }}
              />

              <button
                className={"!mt-6 !mb-3 !w-full bg-amber-900 py-2 rounded-md"}
                onClick={async () => {
                  let validation;
                  validation = await joi
                    .string()
                    .required()
                    .email({ tlds: { allow: false } })
                    .lowercase()
                    .trim()
                    .messages({
                      "string.base": "Required",
                      "string.empty": "Required",
                      "string.email": "Must be valid email address",
                      "any.required": "Required",
                    })
                    .validate(formData.emailAddress);

                  if (validation.error) {
                    setErrors((errors) => {
                      errors[0] = validation.error.message;
                      return [...errors];
                    });
                    return false;
                  }
                  validation = await joi
                    .string()
                    .required()
                    .messages({
                      "string.base": "Required",
                      "string.empty": "Required",
                      "any.required": "Required",
                    })
                    .validate(formData.password);

                  if (validation.error) {
                    setErrors((errors) => {
                      errors[1] = validation.error.message;
                      return [...errors];
                    });
                    return false;
                  }
                  setIsLoading((isLoading) => {
                    isLoading[0] = true;
                    return [...isLoading];
                  });

                  services.api.auth.signIn
                    .teacher(formData)
                    .then((data) => {
                      enqueueSnackbar("Success!");
                      setUserState({
                        ...data.data.teacher,
                        accessToken: data.data.accessToken,
                      });
                      sessionStorage.setItem(
                        "access-token",
                        data.data.accessToken
                      );
                      localStorage.setItem("teacher", data.data.teacher._id);
                      history.push("/teacher/dashboard/overview");
                    })
                    .catch((error) => {
                      enqueueSnackbar(error.message);
                      setIsLoading((isLoading) => {
                        isLoading[0] = false;
                        return [...isLoading];
                      });
                    });
                }}
              >
                {isLoading[0] === true ? (
                  <MuiCircularProgress size={20} className={"!text-white"} />
                ) : (
                  <p className={"text-white"}>{"Sign In"}</p>
                )}
              </button>
            </MuiGrid>
          </MuiPaper>
        </MuiContainer>
      </MuiGrid>
    </>
  );
}
