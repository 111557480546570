import { useEffect, useState } from "react";
import {
  Avatar as MuiAvatar,
  Typography as MuiTypography,
  Divider as MuiDivider,
} from "@mui/material";

import services from "../../../../../../../services";

export default function ProfileOverview() {
  const [administrator, setAdministrator] = useState({});
  useEffect(() => {
    services.api.administrators
      .findOne(localStorage.getItem("administrator"))
      .then((data) => {
        setAdministrator(data.data.administrator);
      })
      .catch((error) => {
        alert(`Error: ${error.message}`);
      });
  }, []);
  return (
    <div className={"w-[100%] bg-[white]"}>
      <div className={"py-[40px] flex flex-column items-center"}>
        <MuiAvatar
          src={administrator.image}
          className={"w-[150px] h-[150px] my-[5px]"}
        />
        <MuiTypography
          className={
            "my-[5px] font-poppins font-light text-[30px] text-capitalize"
          }
        >
          {`${administrator.firstName} ${administrator.lastName}`}
        </MuiTypography>
        <MuiTypography
          className={"my-[5px] font-poppins font-light text-[20px]"}
        >
          {"Administrator"}
        </MuiTypography>
      </div>
      <MuiDivider className={"bg-[black]"} />
      <div className={"w-[100%] py-[40px] px-[30px]"}>
        <div className={"mb-[5px]"}>
          <MuiTypography className={"font-poppins font-light text-[13px]"}>
            {"Email address"}
          </MuiTypography>
          <MuiTypography className={"font-poppins font-light text-[16px]"}>
            {administrator.email}
          </MuiTypography>
        </div>
        <div className={"my-[5px]"}>
          <MuiTypography className={"font-poppins font-light text-[13px]"}>
            {"Phone number"}
          </MuiTypography>
          <MuiTypography className={"font-poppins font-light text-[16px]"}>
            {administrator.phoneNumber || "N/A"}
          </MuiTypography>
        </div>
        <div className={"mt-[5px]"}>
          <MuiTypography className={"font-poppins font-light text-[13px]"}>
            {"Home address"}
          </MuiTypography>
          <MuiTypography className={"font-poppins font-light text-[16px]"}>
            {administrator.homeAddress || "N/A"}
          </MuiTypography>
        </div>
      </div>
    </div>
  );
}
