import { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  Zoom as MuiZoom,
} from "@material-ui/core";

import { useUserState, useGlobalState } from "../../../../../../hooks";
import { requestAnnouncements } from "../../../../../../utils/apiRequests";

import { Header, Drawer } from "../../components";

export default function Announcements(props) {
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { userState, setUserState } = useUserState();
  const { globalState, setGlobalState } = useGlobalState();
  const [announcementsData, setAnnouncementsData] = useState([]);

  useEffect(() => {
    requestAnnouncements.findAllAnnouncements().then((data) => {
      setGlobalState((globalState) => {
        return {
          ...globalState,
          announcements: [...data],
        };
      });
    });
  }, []);

  useEffect(() => {
    setAnnouncementsData((announcements) => {
      return globalState.announcements.filter((item, index) => {
        return item.visibility === "student" || item.visibility === "general";
      });
    });
  }, [globalState.announcements]);

  return (
    <MuiGrid container={true} className={"w-100"}>
      <Header
        drawerOpen={openDrawer}
        setDrawerOpen={setOpenDrawer}
        title={"Announcements"}
      />
      <div className={"w-100"}>
        <Drawer drawerOpen={openDrawer} setDrawerOpen={setOpenDrawer} />
        <MuiGrid container={true} justify={"flex-end"} className={"bg-light"}>
          <MuiGrid container={true} item={true}>
            <div className={"w-100 p-3"}>
              <MuiTable>
                <MuiTableHead>
                  <MuiTableRow>
                    <MuiTableCell className={"bg-color-primary-color"}>
                      <MuiTypography variant={"h5"} className={"text-white"}>
                        {"Announcements"}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                </MuiTableHead>
                <MuiTableBody>
                  {globalState.announcements.length === 0 ? (
                    <MuiZoom in={true} timeout={800}>
                      <MuiTableRow>
                        <MuiTableCell>
                          <MuiTypography
                            align={"center"}
                            variant={"h2"}
                            className={"text-muted"}
                          >
                            {"Nothing to see here."}
                          </MuiTypography>
                        </MuiTableCell>
                      </MuiTableRow>
                    </MuiZoom>
                  ) : (
                    <Fragment>
                      {globalState.announcements.map((item, index) => {
                        return (
                          <MuiZoom in={true} timeout={800 + index * 500}>
                            <MuiTableRow key={index}>
                              <MuiTableCell>
                                <MuiGrid>
                                  <MuiTypography className={"w-100 fs-18-px"}>
                                    {item.title}
                                  </MuiTypography>
                                  <MuiTypography
                                    className={"w-100 fs-14-px text-muted"}
                                  >
                                    {item.message}
                                  </MuiTypography>
                                </MuiGrid>
                              </MuiTableCell>
                            </MuiTableRow>
                          </MuiZoom>
                        );
                      })}
                    </Fragment>
                  )}
                </MuiTableBody>
              </MuiTable>
            </div>
          </MuiGrid>
        </MuiGrid>
      </div>
    </MuiGrid>
  );
}
