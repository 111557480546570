import "./styles.css";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Hidden as MuiHidden,
  Button as MuiButton,
} from "@material-ui/core";

function LandingPage() {
  const history = useHistory();
  return (
    <MuiGrid className={`bg-light position-static LandingPage`}>
      <MuiGrid
        className={"position-fixed w-100 bottom-0 end-0 top-0 start-0"}
        container={true}
      >
        <MuiGrid
          item={true}
          lg={6}
          xs={12}
          sm={12}
          md={7}
          className={"h-100 d-flex flex-column justify-content-center"}
        >
          <MuiHidden only={["md", "lg", "xl"]}>
            <div className={"d-flex justify-content-center flex-column mb-5"}>
              <h1 className={"fw-bold text-center ff-font-family-4"}>
                {"SCHOOL"}
              </h1>
              <h1 className={"text-center ff-primary-font-1"}>{"E-PORTAL"}</h1>
            </div>
          </MuiHidden>

          <div className={"mb-5 w-100 d-flex justify-content-center"}>
            <img
              src={
                "https://res.cloudinary.com/geergregrgege/image/upload/v1690647537/assets/images/undraw_site_stats_re_ejgy_sgzqx0.svg"
              }
              alt={"reading person"}
              width={250}
            />
          </div>

          <div className={"w-100 d-flex justify-content-center"}>
            <MuiButton
              variant={"contained"}
              className={"mx-[5px] bg-[#700510] text-[white]"}
              onClick={() => {
                history.push("/signIn");
              }}
            >
              {"Sign In"}
            </MuiButton>
            <MuiButton
              variant={"contained"}
              className={"mx-[5px] bg-[#700510] text-[white]"}
              onClick={() => {
                history.push("/admin/signin");
              }}
            >
              {"Administrator"}
            </MuiButton>
          </div>
        </MuiGrid>
        <MuiHidden only={["xs", "sm"]}>
          <MuiGrid
            item={true}
            lg={6}
            md={5}
            className={"h-100 bg-color-1 position-relative"}
          >
            <div
              className={"position-absolute bottom-0 end-0 top-0 start-0 div1"}
            ></div>
            <div
              className={
                "position-absolute bottom-0 end-0 top-0 start-0 d-flex justify-content-center flex-column"
              }
            >
              <h1
                className={
                  "fw-bold text-[#700510] text-[45px] text-center ff-font-family-4"
                }
              >
                {"SCHOOL"}
              </h1>
              <h1
                className={
                  "text-center text-[#700510] text-[45px] ff-primary-font-1"
                }
              >
                {"E-PORTAL"}
              </h1>
            </div>
          </MuiGrid>
        </MuiHidden>
      </MuiGrid>
    </MuiGrid>
  );
}

export default LandingPage;
