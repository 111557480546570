import { useState } from "react";
import { Typography as MuiTypography } from "@mui/material";

export default function TimelineTab() {
  return (
    <div className={"w-[100%] py-[30px]"}>
      <MuiTypography align={"center"} className={"text-[40px]"}>
        {"..."}
      </MuiTypography>
    </div>
  );
}
