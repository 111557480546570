import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import {
  SignInPage,
  LandingPage,
  Student,
  Teacher,
  PageNotFound,
  Admin,
  CreateTeacher,
  CreateStudent,
  UploadResult,
  ResultPage,
  CreateClass,
  Class,
  StudentProfile,
  TeacherProfile,
  EditStudent,
  EditTeacher,
  EditResult,
  Invoice,
  Result,
} from "./pages";

import SignIn from "./pages/SignInPage";
import Authorize from "./pages/authorize";
import { UserState } from "./state/userState";
import { GlobalState } from "./state/globalState";
import { ThemeState } from "./state/themeState";
import { SnackbarProvider } from "notistack";

export default function App() {
  return (
    <SnackbarProvider maxSnack={15}>
      <GlobalState>
        <UserState>
          <ThemeState>
            <BrowserRouter>
              <Switch>
                <Route
                  exact
                  path={"/"}
                  component={() => <Redirect to={"sign-in"} />}
                />
                <Route
                    exact
                    path={"/signin"}
                    component={() => <Redirect to={"sign-in"} />}
                />
                <Route path={"/sign-in"} component={SignIn} />
                <Route path={"/authorize"} component={Authorize} />
                <Route path={"/admin"} component={Admin} />
                <Route path={"/student"} component={Student} />
                <Route path={"/teacher"} component={Teacher} />
                <Route path={"/result"} component={Result} />
                <Route
                  path={"/students/profile/:studentID/edit"}
                  component={EditStudent}
                />
                <Route
                  path={"/students/profile/:studentID"}
                  component={StudentProfile}
                />
                <Route path={"/students/new"} component={CreateStudent} />

                <Route
                  path={"/teachers/profile/:teacherID/edit"}
                  component={EditTeacher}
                />
                <Route
                  path={"/teachers/profile/:teacherID"}
                  component={TeacherProfile}
                />

                <Route path={"/teachers/new"} component={CreateTeacher} />
                <Route path={"/results/upload"} component={UploadResult} />
                <Route path={"/results/:resultID"} component={ResultPage} />
                <Route path={"/classes/new"} component={CreateClass} />
                <Route path={"/class"} component={Class} />
                <Route path={"/invoices/:invoiceID"} component={Invoice} />
                <Route component={PageNotFound} />
              </Switch>
            </BrowserRouter>
          </ThemeState>
        </UserState>
      </GlobalState>
    </SnackbarProvider>
  );
}
