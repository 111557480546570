import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Typography as MuiTypography,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from "@material-ui/core";

import { useUserState, useClassState } from "../../../../../../hooks";
import { requestStudents } from "../../../../../../utils/apiRequests";

export default function Results() {
  const { classState } = useClassState();
  const { userState } = useUserState();
  const history = useHistory();
  const [results, setResults] = useState([]);
  const params = useParams();

  useEffect(() => {
    classState.students.forEach((student, index) => {
      requestStudents.results
        .findAllResults(student._id.replace(/\//g, "-"))
        .then((data) => {
          console.log(data);
          setTimeout(() => {
            setResults((results) => {
              return [...results, ...data];
            });
          }, 500 * index);
        });
    });
  }, []);

  return (
    <MuiGrid container={true} justify={"center"} className={"px-3"}>
      <MuiGrid container={true} className={"MuiGrid1 w-100 py-3"}>
        <MuiGrid className={"flex-grow-1"}>
          <MuiTypography variant={"h6"} className={"MuiTypography1 h-100"}>
            {"Results"}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item={true}>
          <MuiButton className={"bg-white mx-1"} onClick={() => {}}>
            <MuiTypography variant={"button"} className={"mx-1"}>
              {"Refresh"}
            </MuiTypography>
          </MuiButton>
        </MuiGrid>
      </MuiGrid>
      <MuiGrid container={true}>
        {classState.students.map((student, index) => {
          return (
            <MuiTable key={index} className={"border mb-3"}>
              <MuiTableHead>
                <MuiTableRow>
                  <MuiTableCell
                    colSpan={5}
                    className={"bg-info border-0 fw-bold"}
                  >
                    <MuiTypography>
                      {`${student.firstName.toUpperCase()} ${student.lastName.toUpperCase()}`}
                    </MuiTypography>
                  </MuiTableCell>
                  <MuiTableCell className={"bg-info border-0 fw-bold"}>
                    <MuiButton
                      variant={"outlined"}
                      color={"secondary"}
                      onClick={() => {
                        history.push(
                          `/result/create?studentID=${student._id}&classID=${classState._id}`
                        );
                      }}
                    >
                      {"Create"}
                    </MuiButton>
                  </MuiTableCell>
                </MuiTableRow>
              </MuiTableHead>
              <MuiTableBody>
                {results?.map((result, index) => {
                  if (result.student === student._id) {
                    return (
                      <MuiTableRow key={index}>
                        <MuiTableCell>{result._id}</MuiTableCell>
                        <MuiTableCell>{result.term}</MuiTableCell>
                        <MuiTableCell>{result.session}</MuiTableCell>
                        <MuiTableCell>
                          {result.isApproved ? "Approved" : "Not Approved"}
                        </MuiTableCell>
                        <MuiTableCell>
                          {new Date(result.uploadedAt).toLocaleString()}
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiButton
                            size={"small"}
                            onClick={() => {
                              history.push(`/results/${result._id}`);
                            }}
                          >
                            {"View"}
                          </MuiButton>
                        </MuiTableCell>
                      </MuiTableRow>
                    );
                  }
                })}
              </MuiTableBody>
            </MuiTable>
          );
        })}
      </MuiGrid>
    </MuiGrid>
  );
}
