import { useState, Fragment } from "react";
import {
  Grid as MuiGrid,
  Dialog as MuiDialog,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider as MuiDivider,
  FormControlLabel as MuiFormControlLabel,
  Checkbox as MuiCheckbox,
  IconButton as MuiIconButton,
} from "@material-ui/core";

import { Add } from "@material-ui/icons";

import { requestFee__createFee } from "../../../../../../../../../../utils/apiRequests";

export default function GenerateFeeDialog(props) {
  const [state, setState] = useState({
    title: "",
    hasPriceVariety: false,
    prices: [{ variant: "", price: 0 }],
    price: 0,
    isgrouped: false,
    groupName: "",
  });
  return (
    <MuiDialog open={props.state.isOpen} onClose={() => props.state.close()}>
      <MuiGrid className={"px-3 py-5"}>
        <MuiGrid className={"mb-3"}>
          <MuiTextField
            type={"text"}
            variant={"filled"}
            label={"Title"}
            className={"w-100"}
            InputLabelProps={{ shrink: true }}
            value={state.title}
            onChange={(event) => {
              const newState = { ...state };
              newState.title = event.target.value;
              setState({ ...newState });
            }}
          />
          <MuiFormControlLabel
            label={"With varying prices"}
            labelPlacement={"end"}
            control={
              <MuiCheckbox
                checked={state.hasPriceVariety}
                onChange={() => {
                  setState({
                    ...state,
                    hasPriceVariety: !state.hasPriceVariety,
                  });
                }}
              />
            }
          />
        </MuiGrid>
        {state.hasPriceVariety === false && (
          <MuiGrid className={"mb-3"}>
            <MuiTextField
              type={"number"}
              variant={"filled"}
              label={"Price"}
              InputLabelProps={{ shrink: true }}
              value={state.price}
              onChange={(event) => {
                const newState = { ...state };
                newState.price = event.target.value;
                setState({ ...newState });
              }}
            />
          </MuiGrid>
        )}
        {state.hasPriceVariety === true && (
          <MuiGrid container={true} spacing={2}>
            {state.prices.map((item, index) => {
              return (
                <Fragment key={index}>
                  <MuiGrid item={true} xs={6}>
                    <MuiTextField
                      type={"text"}
                      variant={"filled"}
                      label={"Variant"}
                      value={item.variant}
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) => {
                        const newState = { ...state };
                        newState.prices[index].variant = event.target.value;
                        setState({ ...newState });
                      }}
                    />
                  </MuiGrid>
                  <MuiGrid item={true} xs={6}>
                    <MuiTextField
                      type={"number"}
                      variant={"filled"}
                      label={"Price"}
                      value={item.price}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => {
                        const newState = { ...state };
                        newState.prices[index].price = event.target.value;
                        setState({ ...newState });
                      }}
                    />
                  </MuiGrid>
                </Fragment>
              );
            })}
            <MuiGrid item={true} xs={6}>
              <MuiIconButton
                size={"small"}
                color={"primary"}
                onClick={() => {
                  const newState = { ...state };
                  newState.prices.push({ variant: "", price: 0 });
                  setState({ ...newState });
                }}
              >
                <Add />
              </MuiIconButton>
            </MuiGrid>
          </MuiGrid>
        )}

        <MuiDivider className={"my-2"} />
        <MuiGrid>
          <MuiButton
            variant={"contained"}
            color={"primary"}
            className={"me-2"}
            onClick={() => {
              requestFee__createFee(state).then((data) => {
                console.log(data);
              });
              props.state.close();
            }}
          >
            {"Save"}
          </MuiButton>
          <MuiButton
            variant={"contained"}
            color={"secondary"}
            className={"me-2"}
            onClick={() => {
              setState({
                title: "",
                hasPriceVariety: false,
                prices: [{ variant: "", price: 0 }],
                price: 0,
                isgrouped: false,
                groupName: "",
              });
            }}
          >
            {"Reset"}
          </MuiButton>
        </MuiGrid>
      </MuiGrid>
    </MuiDialog>
  );
}
