import { globalContext } from "../state/globalState";
import { useContext } from "react";
import {
  getAllClasses,
  getAllStudents,
  getAllTeachers,
  requestAllResults,
  requestTerm,
  requestSession,
} from "../utils/apiRequests";

export default function useGlobalState(params) {
  const { globalState, setGlobalState } = useContext(globalContext);

  function getClasses() {
    getAllClasses().then((data) => {
      if (data.error) {
        console.log(data.error.message);
      } else {
        globalState.classes = data;
        setGlobalState({ ...globalState });
      }
    });
  }

  function getStudents() {
    getAllStudents().then((data) => {
      let newState = globalState;
      newState.students = [...data];
      setGlobalState({ ...newState });
    });
  }
  function getTeachers() {
    getAllTeachers().then((data) => {
      let state = globalState;
      state.teachers = [...data];
      setGlobalState({ ...state });
    });
  }

  function getResults() {
    requestAllResults().then((data) => {
      let state = { ...globalState };
      state.results = [...data];
      setGlobalState({ ...state });
    });
  }

  function getTerm() {
    requestTerm().then((data) => {
      let state = globalState;
      state.term = data.term;
      setGlobalState({ ...state });
    });
  }

  function getSession() {
    requestSession().then((data) => {
      let state = globalState;
      state.session = data.session;
      setGlobalState({ ...state });
    });
  }

  return {
    globalState,
    setGlobalState,
    getClasses,
    getStudents,
    getTeachers,
    getResults,
    getTerm,
    getSession,
  };
}
