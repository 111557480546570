import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  Button as MuiButton,
  Divider as MuiDivider,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText as MuiFormHelperText,
  Snackbar as MuiSnackbar,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";

import { Paper as MuiPaper } from "@mui/material";

import { Save as MuiSaveIcon } from "@material-ui/icons";

import { useGlobalState } from "../../../../../../hooks";
import { LayoutV1 } from "../../../../../../components";
import services from "../../../../../../../../services";
export default function Update() {
  const [student, setStudent] = useState({});
  const [formState, setFormState] = useState({});

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
    handleClose: function () {
      setSnackbarState({
        ...snackbarState,
        open: false,
        message: "",
        severity: "",
      });
    },
    handleOpen: function (message, severity) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: message,
        severity: severity,
      });
    },
  });

  const [submitProgress, setSubmitProgress] = useState(false);

  useEffect(() => {
    services.api.administrators
      .findOne(new URLSearchParams(window.location.search).get("id"))
      .then((data) => {
        setStudent(data.data.administrator);
        setFormState((formState) => {
          let newFormState = { ...formState };
          newFormState.gender = data.data.administrator.gender;
          return { ...newFormState };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <LayoutV1>
      <MuiGrid
        container={true}
        justifyContent={"center"}
        className={"mt-[30px]"}
      >
        <MuiGrid item={true} xs={10} sm={9} md={8} lg={7} xl={6}>
          <MuiPaper className={"p-[30px]"}>
            <div className={"p-3"}>
              <form onSubmit={(e) => e.preventDefault()}>
                <MuiTypography variant={"h5"}>
                  Please fill correctly
                </MuiTypography>
                <MuiDivider className={"mb-5"} />
                <MuiTypography className={"mt-3"}>First Name</MuiTypography>
                <MuiTextField
                  name={"firstName"}
                  placeholder={student.firstName}
                  fullWidth={true}
                  size={"small"}
                  type={"text"}
                  variant={"outlined"}
                  onChange={(e) => {
                    formState["firstName"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                />
                <MuiTypography className={"mt-3"}>Last Name</MuiTypography>
                <MuiTextField
                  name={"lastName"}
                  fullWidth={true}
                  size={"small"}
                  type={"text"}
                  variant={"outlined"}
                  placeholder={student.lastName}
                  onChange={(e) => {
                    formState["lastName"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                />
                <MuiTypography className={"mt-3"}>Email Address</MuiTypography>
                <MuiTextField
                  name={"emailAddress"}
                  fullWidth={true}
                  placeholder={student.email || "N/A"}
                  size={"small"}
                  type={"text"}
                  variant={"outlined"}
                  onChange={(e) => {
                    formState["emailAddress"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                />
                <MuiTypography className={"mt-3"}>Phone Number</MuiTypography>
                <MuiTextField
                  name={"phoneNumber"}
                  fullWidth={true}
                  size={"small"}
                  type={"tel"}
                  variant={"outlined"}
                  placeholder={student.phoneNumber || "N/A"}
                  onChange={(e) => {
                    formState["phoneNumber"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                />
                <MuiTypography className={"mt-3"}>Gender</MuiTypography>
                <MuiSelect
                  size={"small"}
                  fullWidth={true}
                  variant={"outlined"}
                  value={formState.gender || "male"}
                  onChange={(e) => {
                    formState["gender"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                >
                  <MuiMenuItem value={"male"}>Male</MuiMenuItem>
                  <MuiMenuItem value={"female"}>Female</MuiMenuItem>
                </MuiSelect>
                <MuiTypography className={"mt-3"}>Home Address</MuiTypography>
                <MuiTextareaAutosize
                  className={"w-100 form-control"}
                  minRows={4}
                  placeholder={student.homeAddress || "N/A"}
                  onChange={(e) => {
                    formState["address"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                />
                <MuiTypography className={"mt-3"}>
                  Upload new profile image
                </MuiTypography>
                <MuiTextField
                  variant={"outlined"}
                  type={"file"}
                  multiple={false}
                  onChange={(e) => {
                    const reader = new FileReader();
                    reader.readAsBinaryString(e.target.files[0]);
                    reader.onload = function () {
                      formState["image"] = btoa(reader.result);
                      setFormState({ ...formState });
                    };
                  }}
                />
                <MuiFormHelperText>
                  *image must be in portrait mode
                </MuiFormHelperText>

                <MuiDivider className={"my-4"} />

                <MuiButton
                  className={"mb-3"}
                  type={"submit"}
                  color={"primary"}
                  variant={"contained"}
                  disabled={submitProgress}
                  endIcon={<MuiSaveIcon />}
                  onClick={async () => {
                    setSubmitProgress(true);
                    services.api.administrators
                      .updateOne(
                        new URLSearchParams(window.location.search).get("id"),
                        "update",
                        formState
                      )
                      .then((data) => {
                        alert(data.message);
                        setSubmitProgress(false);
                      })
                      .catch((error) => {
                        console.log(error.message);
                        setSubmitProgress(false);
                      });
                  }}
                >
                  {submitProgress ? (
                    <MuiCircularProgress size={24} color="secondary" />
                  ) : (
                    "Update"
                  )}
                </MuiButton>
              </form>
            </div>
          </MuiPaper>
        </MuiGrid>

        <MuiSnackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbarState.open}
          message={snackbarState.message}
          action={
            <>
              <MuiButton
                color="secondary"
                size="small"
                onClick={snackbarState.handleClose}
              >
                {"CLOSE"}
              </MuiButton>
            </>
          }
        />
      </MuiGrid>
    </LayoutV1>
  );
}
