import { useState, useEffect } from "react";
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Paper as MuiPaper,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  Button as MuiButton,
  Divider as MuiDivider,
  TextareaAutosize as MuiTextareaAutosize,
  Snackbar as MuiSnackbar,
  OutlinedInput as MuiOutlinedInput,
  IconButton as MuiIconButton,
  InputAdornment as MuiInputAdornment,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";

import {
  Visibility as MuiVisibilityIcon,
  VisibilityOff as MuiVisibilityOffIcon,
} from "@material-ui/icons";

import { Header } from "./components";
import { validationSchema } from "./validationSchema";

import { useGlobalState } from "../../../../hooks";
import { requestTeachers } from "../../../../utils/apiRequests";

export default function Create() {
  const [formState, setFormState] = useState({
    gender: "male",
    maritalStatus: "single",
    class: "",
  });
  const { globalState, getClasses } = useGlobalState();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
    handleClose: function () {
      setSnackbarState({
        ...snackbarState,
        open: false,
        message: "",
        severity: "",
      });
    },
    handleOpen: function (message, severity) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: message,
        severity: severity,
      });
    },
  });

  const [submitProgress, setSubmitProgress] = useState(false);

  useEffect(() => {
    getClasses();
  }, []);
  return (
    <MuiGrid
      container={true}
      justifyContent={"center"}
      className={"position-absolute w-100 h-100 py-5 my-5"}
    >
      <Header title={"Create Teacher"} />
      <MuiGrid item={true} xs={10} sm={9} md={8} lg={7} xl={6}>
        <MuiPaper className={"p-3"}>
          <MuiTypography variant={"h5"}>Please fill correctly</MuiTypography>
          <MuiDivider className={"mb-5"} />
          <MuiTypography className={"mt-3"}>First Name</MuiTypography>
          <MuiTextField
            fullWidth={true}
            id={"first-name-inout"}
            size={"small"}
            type={"text"}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, firstName: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Last Name</MuiTypography>
          <MuiTextField
            size={"small"}
            type={"text"}
            variant={"outlined"}
            fullWidth={true}
            onChange={(e) => {
              setFormState({ ...formState, lastName: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Email Address</MuiTypography>
          <MuiTextField
            fullWidth={true}
            size={"small"}
            type={"text"}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, email: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Gender</MuiTypography>
          <MuiSelect
            fullWidth={true}
            size={"small"}
            variant={"outlined"}
            value={formState.gender}
            onChange={(e) => {
              setFormState({ ...formState, gender: e.target.value });
            }}
          >
            <MuiMenuItem value={"male"}>Male</MuiMenuItem>
            <MuiMenuItem value={"female"}>Female</MuiMenuItem>
          </MuiSelect>
          <MuiTypography className={"mt-3"}>Marital Status</MuiTypography>
          <MuiSelect
            fullWidth={true}
            value={formState.maritalStatus}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, maritalStatus: e.target.value });
            }}
          >
            <MuiMenuItem value={"single"}>Single</MuiMenuItem>
            <MuiMenuItem value={"married"}>Married</MuiMenuItem>
          </MuiSelect>

          <MuiTypography className={"mt-3"}>Class</MuiTypography>
          <MuiSelect
            fullWidth={true}
            variant={"outlined"}
            value={formState.class}
            onChange={(e) => {
              setFormState({ ...formState, class: e.target.value });
            }}
          >
            {globalState.classes.map((item, index) => {
              return (
                <MuiMenuItem key={index} value={item._id}>
                  {item.name}
                </MuiMenuItem>
              );
            })}
          </MuiSelect>

          <MuiTypography className={"mt-3"}>Address</MuiTypography>
          <MuiTextareaAutosize
            className={"w-100 form-control"}
            onChange={(e) => {
              setFormState({ ...formState, address: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Upload Image</MuiTypography>
          <MuiTextField
            variant={"outlined"}
            type={"file"}
            multiple={false}
            onChange={(e) => {
              const reader = new FileReader();
              reader.readAsBinaryString(e.target.files[0]);
              reader.onload = function () {
                formState["image"] = btoa(reader.result);
                setFormState({ ...formState });
              };
            }}
          />
          <MuiTypography className={"mt-3"}>Password</MuiTypography>
          <MuiOutlinedInput
            className={"my-2"}
            placeholder={"Password"}
            fullWidth={true}
            type={passwordVisibility ? "text" : "password"}
            onChange={(e) => {
              formState["password"] = e.target.value;
              setFormState({ ...formState });
            }}
            endAdornment={
              <MuiInputAdornment position="end">
                <MuiIconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setPasswordVisibility(!passwordVisibility);
                  }}
                >
                  {passwordVisibility ? (
                    <MuiVisibilityOffIcon />
                  ) : (
                    <MuiVisibilityIcon />
                  )}
                </MuiIconButton>
              </MuiInputAdornment>
            }
          />
          <MuiTypography className={"mt-3"}>Repeat Password</MuiTypography>
          <MuiOutlinedInput
            className={"my-2"}
            placeholder={"Repeat Password"}
            fullWidth={true}
            type={passwordVisibility ? "text" : "password"}
            onChange={(e) => {
              formState["repeatPassword"] = e.target.value;
              setFormState({ ...formState });
            }}
            endAdornment={
              <MuiInputAdornment position="end">
                <MuiIconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setPasswordVisibility(!passwordVisibility);
                  }}
                >
                  {passwordVisibility ? (
                    <MuiVisibilityOffIcon />
                  ) : (
                    <MuiVisibilityIcon />
                  )}
                </MuiIconButton>
              </MuiInputAdornment>
            }
          />
          <MuiDivider className={"my-4"} />
          <MuiButton
            className={"mb-3 rounded-0"}
            color={"primary"}
            disableElevation={true}
            variant={"contained"}
            onClick={async () => {
              setSubmitProgress(true);
              const result = await validationSchema
                .validate(formState, { abortEarly: false })
                .catch((err) => {
                  return {
                    error: {
                      message: err.inner[0].message,
                    },
                  };
                });
              if (result.error) {
                snackbarState.handleOpen(`${result.error.message}`, "error");
                setTimeout(() => {
                  setSubmitProgress(false);
                }, 2000);
              } else {
                if (formState.password !== formState.repeatPassword) {
                  snackbarState.handleOpen(
                    "Password fields don't match!",
                    "error"
                  );
                  setTimeout(() => {
                    setSubmitProgress(false);
                  }, 2000);
                  return false;
                } else {
                  requestTeachers
                    .createTeacher(formState)
                    .then((data) => {
                      snackbarState.handleOpen(
                        `Teacher created successfully`,
                        "success"
                      );
                      setTimeout(() => {
                        setSubmitProgress(false);
                      }, 2000);
                    })
                    .catch((error) => {
                      snackbarState.handleOpen(error, "error");
                      setTimeout(() => {
                        setSubmitProgress(false);
                      }, 2000);
                    });
                }
              }
            }}
          >
            {submitProgress ? (
              <MuiCircularProgress size={24} color="secondary" />
            ) : (
              "Submit"
            )}
          </MuiButton>
        </MuiPaper>
      </MuiGrid>
      <MuiSnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarState.open}
        message={snackbarState.message}
        action={
          <>
            <MuiButton
              color="secondary"
              size="small"
              onClick={snackbarState.handleClose}
            >
              {"CLOSE"}
            </MuiButton>
          </>
        }
      />
    </MuiGrid>
  );
}
