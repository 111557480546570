import io from "socket.io-client";

let socket = { isConnected: false };
function useWebsocket() {
  if (socket.isConnected === false) {
    socket = io(`${process.env.REACT_APP_API_BASE_URL}`, {
      path: "/api/socket.io",
      transports: [
        "websocket",
        "flashsocket",
        "htmlfile",
        "xhr-polling",
        "jsonp-polling",
        "polling",
      ],
    });
  }
  socket.on("connection_success", () => {
    console.log("SUCCESS CONFIRMATION RECEIVED");
  });
  return {
    socket,
  };
}

export default useWebsocket;
