import { useState, useEffect } from "react";
import {
  ProfileOverview,
  NavigationTab,
  ProfileTab,
  TimelineTab,
  PasswordAndSecurityTab,
} from "./components";
import { Button as MuiButton, Grid as MuiGrid } from "@material-ui/core";

import { LayoutV1 } from "../../../../components";

export default function Profile() {
  return (
    <LayoutV1>
      <MuiGrid container={true} spacing={2} className={"w-[100%] p-[30px]"}>
        <MuiGrid item={true} xs={4}>
          <ProfileOverview />
        </MuiGrid>
        <MuiGrid item={true} xs={8}>
          <div className={"bg-[white] w-[100%]"}>
            <NavigationTab>
              <TimelineTab />
              <ProfileTab />
              <PasswordAndSecurityTab />
            </NavigationTab>
          </div>
        </MuiGrid>
      </MuiGrid>
    </LayoutV1>
  );
}
