import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid as MuiGrid, Button as MuiButton } from "@material-ui/core";

import { Paper as MuiPaper, Avatar as MuiAvatar } from "@mui/material";

import { LayoutV1 } from "../../../../../../components";
import { useWebsocket } from "../../../../../../hooks";

import { C1 } from "./components";

import { Add, Send as MuiSendIcon } from "@material-ui/icons";

import services from "../../../../../../../../services";

export default function Root() {
  const history = useHistory();
  const [administrator, setAdministrator] = useState({});
  const [mode, setMode] = useState("default");
  const [conversations, setConversations] = useState([]);
  const [conversation, setConversation] = useState(null);
  const { socket } = useWebsocket();
  useEffect(() => {
    if (!localStorage.getItem("administrator")) {
      history.replace("/admin/signin");
      return false;
    }
    services.api.administrators
      .findOne(localStorage.getItem("administrator"))
      .then((data) => {
        setAdministrator(data.data.administrator);
        data.data.administrator.conversations.forEach((conversation) => {
          services.api.conversations.findOne(conversation._id).then((data) => {
            setConversations([...conversations, data.data.conversation]);
          });
        });
        console.log(data.data.administrator);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);

  return (
    <LayoutV1>
      <div className={"p-[10px] w-[100%]"}>
        <MuiGrid container={true}>
          <MuiGrid item={true} xs={12}>
            <MuiPaper className={""}>
              <MuiGrid container={true} className={""}>
                <MuiGrid item={true} xs={12}>
                  <div className={"p-[20px]"}>
                    <h2>Chats</h2>
                  </div>
                </MuiGrid>
                <MuiGrid item={true} xs={4}>
                  {mode === "new_conversation" ? (
                    <C1 setMode={setMode} />
                  ) : (
                    <div>
                      <div className={"w-[100%] p-[10px]"}>
                        <MuiButton
                          startIcon={<Add />}
                          variant={"contained"}
                          className={"w-[100%]"}
                          onClick={() => {
                            setMode("new_conversation");
                          }}
                        >
                          New Conversation
                        </MuiButton>
                      </div>
                      {administrator.conversations?.map(
                        (conversation, index) => {
                          return (
                            <MuiButton
                              key={index}
                              variant={"outlined"}
                              className={"rounded-none py-[20px] w-[100%]"}
                            >
                              {conversation.members
                                .filter((member) => {
                                  return (
                                    member.id !==
                                    sessionStorage.getItem("administrator")
                                  );
                                })
                                .map((member, index) => {
                                  return member.id;
                                })}
                            </MuiButton>
                          );
                        }
                      )}
                    </div>
                  )}
                </MuiGrid>
                <MuiGrid item={true} xs={8}>
                  <div
                    className={"h-[100%] w-[100%] flex flex-col justify-end"}
                  >
                    {[...Array(10)].map((item, index) => {
                      return <h1>test</h1>;
                    })}
                    <div className={"py-[20px] px-[10px] flex"}>
                      <input
                        type={"text"}
                        className={
                          "flex-grow-1 border border-[#747474] rounded-sm outline-none"
                        }
                      />
                      <MuiButton
                        variant={"contained"}
                        startIcon={<MuiSendIcon />}
                        className={"bg-[#8eff3a]"}
                      >
                        Send
                      </MuiButton>
                    </div>
                  </div>
                </MuiGrid>
              </MuiGrid>
            </MuiPaper>
          </MuiGrid>
        </MuiGrid>
      </div>
    </LayoutV1>
  );
}
