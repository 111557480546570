import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid as MuiGrid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { Badge as MuiBadge } from "@material-ui/core";

import {
  Notifications,
  ExpandLess as MuiExpandLessIcon,
} from "@material-ui/icons";

import { useWidth, useUserState } from "../../../../../../hooks";

export default function Header(props) {
  const { userState } = useUserState();
  const history = useHistory();
  const width = useWidth();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

  const titleArray = ["Overview", "Notifications", "Check Result"];

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (userState.notifications) {
      userState.notifications.every((item, index) => {
        if (item.isRead === false) {
          setHasUnreadNotifications(true);
          return false;
        }
        setHasUnreadNotifications(false);
        return true;
      });
    }
  }, [userState]);

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar>
        <MuiGrid container={true} alignItems={"center"}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              props.setDrawerOpen(!props.drawerOpen);
            }}
          >
            {props.drawerOpen ? <MuiExpandLessIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" className={"mt-1"}>
            {props.title}
          </Typography>
        </MuiGrid>
        <MuiGrid className={"d-flex"}>
          <IconButton className={"text-white"}>
            <MuiBadge
              variant={"dot"}
              color={"secondary"}
              invisible={!hasUnreadNotifications}
            >
              <Notifications />
            </MuiBadge>
          </IconButton>

          <IconButton onClick={handleMenu} color="inherit">
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                history.push(
                  `/students/profile/${userState._id.replaceAll("/", "-")}`
                );
              }}
            >
              Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/signin");
                sessionStorage.clear();
                window.location.reload();
              }}
            >
              Sign Out
            </MenuItem>
          </Menu>
        </MuiGrid>
      </Toolbar>
    </AppBar>
  );
}
