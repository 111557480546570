import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Typography as MuiTypography,
  TableContainer as MuiTableContainer,
  Paper as MuiPaper,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Checkbox as MuiCheckbox,
  TableBody as MuiTableBody,
  TableFooter as MuiTableFooter,
  Hidden as MuiHidden,
} from "@material-ui/core";

import {
  AddBox as MuiAddBoxIcon,
  Block as MuiBlockIcon,
  Cached as MuiCachedIcon,
  Delete as MuiDeleteIcon,
  Preview as MuiPreviewIcon,
  Key as MuiKeyIcon,
} from "@mui/icons-material";
import { requestTeachers } from "../../../../../../utils/apiRequests";
import { useGlobalState } from "../../../../../../hooks";

import {
  Avatar as MuiAvatar,
  Skeleton as MuiSkeleton,
  TablePagination as MuiTablePagination,
} from "@mui/material";

import { LayoutV2 } from "../../../../components";
import services from "../../../../../../services";
function trffe(selectedAdministrators, administrator) {
  const val = selectedAdministrators.some(
    (selectedAdministrator) =>
      selectedAdministrator["_id"] === administrator._id
  );
  console.log(val);
  return val;
}

export default function Teachers() {
  const { globalState, setGlobalState } = useGlobalState();
  const history = useHistory();
  const [teachers, setTeachers] = useState(globalState.teachers);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [loadingStates, setLoadingStates] = useState([true]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [mode, setMode] = useState("default");

  useEffect(() => {
    if (mode === "default") {
      setLoadingStates((loadingStates) => {
        let newLoadingStates = [...loadingStates];
        newLoadingStates[0] = true;
        return [...newLoadingStates];
      });
      services.api.teachers
        .findAll(page, count)
        .then((data) => {
          setTeachers(data.data.teachers);
          setTotalCount(data.data.totalCount);
          setLoadingStates((loadingStates) => {
            let newLoadingStates = [...loadingStates];
            newLoadingStates[0] = false;
            return [...newLoadingStates];
          });
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }, [page, count, mode]);

  return (
    <LayoutV2
      title={"Teachers"}
      actions={
        <>
          <MuiButton
            size={"small"}
            variant={"contained"}
            className={
              "rounded-sm font-poppins text-[14px] font-light text-capitalize"
            }
            startIcon={<MuiAddBoxIcon />}
            onClick={() => {
              history.push("/teacher/create");
            }}
          >
            {"create"}
          </MuiButton>
        </>
      }
    >
      <MuiGrid container={true}>
        <MuiGrid className={"px-[30px] mt-[30px] w-[100%]"}>
          <MuiTableContainer component={MuiPaper}>
            <div
              className={
                "pl-[25px] pr-[25px] py-[10px] flex items-center justify-between"
              }
            >
              {selectedTeachers.length < 1 ? (
                <p>{"  "}</p>
              ) : (
                <MuiTypography
                  className={"text-[25px] font-poppins font-light"}
                >
                  {`${selectedTeachers.length} selected`}
                </MuiTypography>
              )}

              <div>
                {selectedTeachers.length === 1 && (
                  <MuiButton
                    size={"small"}
                    variant={"contained"}
                    className={
                      "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                    }
                    startIcon={<MuiBlockIcon />}
                    onClick={() => {
                      if (selectedTeachers[0].status === "active") {
                        services.api.teachers
                          .updateOne(selectedTeachers[0]._id, "ban", {})
                          .then((data) => {
                            alert(data.message);
                          })
                          .catch((error) => {
                            alert(error.message);
                          });
                      } else {
                        services.api.teachers
                          .updateOne(selectedTeachers[0]._id, "unban", {})
                          .then((data) => {
                            alert(data.message);
                          })
                          .catch((error) => {
                            alert(error.message);
                          });
                      }
                      setSelectedTeachers([]);
                      services.api.teachers
                        .findAll(page, count)
                        .then((data) => {
                          setTeachers(data.data.teachers);
                          setTotalCount(data.data.totalCount);
                          setLoadingStates((loadingStates) => {
                            let newLoadingStates = [...loadingStates];
                            newLoadingStates[0] = false;
                            return [...newLoadingStates];
                          });
                        })
                        .catch((error) => {
                          alert(error.message);
                        });
                    }}
                  >
                    {selectedTeachers[0].status === "active" ? "Ban" : "Unban"}
                  </MuiButton>
                )}
                {selectedTeachers.length === 1 && (
                  <MuiButton
                    size={"small"}
                    variant={"contained"}
                    className={
                      "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                    }
                    startIcon={<MuiPreviewIcon />}
                    onClick={() => {
                      history.push(
                        `/admin/dashboard/teacher?id=${selectedTeachers[0]._id}`
                      );
                    }}
                  >
                    {"View"}
                  </MuiButton>
                )}

                {selectedTeachers.length === 1 && (
                  <>
                    <MuiButton
                      size={"small"}
                      variant={"contained"}
                      className={
                        "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                      }
                      startIcon={<MuiDeleteIcon />}
                      onClick={() => {
                        services.api.teachers
                          .deleteOne(selectedTeachers[0]._id)
                          .then((data) => {
                            alert(data.message);
                          })
                          .catch((error) => {
                            alert(error.message);
                          });
                        setSelectedTeachers([]);
                        services.api.teachers
                          .findAll(page, count)
                          .then((data) => {
                            setTeachers(data.data.teachers);
                            setTotalCount(data.data.totalCount);
                            setLoadingStates((loadingStates) => {
                              let newLoadingStates = [...loadingStates];
                              newLoadingStates[0] = false;
                              return [...newLoadingStates];
                            });
                          })
                          .catch((error) => {
                            alert(error.message);
                          });
                      }}
                    >
                      {"Delete"}
                    </MuiButton>
                    <MuiButton
                      size={"small"}
                      variant={"contained"}
                      startIcon={<MuiKeyIcon />}
                      className={
                        "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                      }
                      onClick={() => {
                        const data = prompt("Enter new password", "password");
                        if (!data) {
                          return false;
                        }
                        services.api.teachers
                          .updateOne(
                            selectedTeachers[0]._id,
                            "update_password",
                            { password: data }
                          )
                          .then((data) => {
                            alert(data.message);
                          })
                          .catch((error) => {
                            alert(error.message);
                          });
                      }}
                    >
                      {"Update Password"}
                    </MuiButton>
                  </>
                )}
                <MuiButton
                  size={"small"}
                  variant={"contained"}
                  className={
                    "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                  }
                  startIcon={<MuiCachedIcon />}
                  onClick={() => {
                    setLoadingStates((loadingStates) => {
                      let newLoadingStates = [...loadingStates];
                      newLoadingStates[0] = true;
                      return [...newLoadingStates];
                    });

                    services.api.teachers
                      .findAll(page, count)
                      .then((data) => {
                        setTeachers(data.data.teachers);
                        setTotalCount(data.data.totalCount);
                        setLoadingStates((loadingStates) => {
                          let newLoadingStates = [...loadingStates];
                          newLoadingStates[0] = false;
                          return [...newLoadingStates];
                        });
                      })
                      .catch((error) => {
                        alert(error.message);
                      });
                    setPage(1);
                  }}
                >
                  {"Reload"}
                </MuiButton>
              </div>
            </div>
            <MuiTable>
              <MuiTableHead>
                <MuiTableRow>
                  <MuiTableCell>
                    <MuiCheckbox disabled={true} />
                  </MuiTableCell>
                  <MuiTableCell>
                    <MuiAvatar />
                  </MuiTableCell>
                  <MuiHidden only={["xs", "sm"]}>
                    <MuiTableCell>ID</MuiTableCell>
                  </MuiHidden>

                  <MuiTableCell>First Name</MuiTableCell>
                  <MuiTableCell>Last Name</MuiTableCell>
                  <MuiTableCell>Gender</MuiTableCell>
                  <MuiTableCell>Classes</MuiTableCell>
                  <MuiTableCell>Status</MuiTableCell>
                </MuiTableRow>
              </MuiTableHead>
              <MuiTableBody>
                {teachers.map((item, index) => {
                  if (loadingStates[0] === true) {
                    return (
                      <MuiTableRow key={index}>
                        <MuiTableCell>
                          <MuiCheckbox checked={false} disabled={true} />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton
                            variant="circular"
                            width={40}
                            height={40}
                          />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                      </MuiTableRow>
                    );
                  } else {
                    return (
                      <MuiTableRow
                        key={index}
                        className={"cursor-pointer"}
                        onClick={(event) => {
                          if (trffe(selectedTeachers, item) === true) {
                            setSelectedTeachers((selectedTeachers) => {
                              let state = [...selectedTeachers];
                              state = state.filter((item2) => {
                                return item._id !== item2._id;
                              });
                              return state;
                            });
                          } else {
                            setSelectedTeachers([...selectedTeachers, item]);
                          }
                        }}
                      >
                        <MuiTableCell>
                          {trffe(selectedTeachers, item) === true ? (
                            <MuiCheckbox checked={true} />
                          ) : (
                            <MuiCheckbox checked={false} />
                          )}
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiAvatar
                            src={`https://ilechuks73.com.ng/uploads/images/profile/${item._id}.jpg`}
                          />
                        </MuiTableCell>

                        <MuiHidden only={["xs", "sm"]}>
                          <MuiTableCell>
                            {item?._id?.toUpperCase()}
                          </MuiTableCell>
                        </MuiHidden>

                        <MuiTableCell className={"text-capitalize"}>
                          {item?.firstName}
                        </MuiTableCell>
                        <MuiTableCell className={"text-capitalize"}>
                          {item?.lastName}
                        </MuiTableCell>
                        <MuiTableCell>{item?.gender}</MuiTableCell>
                        <MuiTableCell>
                          {(function () {
                            return item.classes
                              .map((_class) => _class.name)
                              .join(", ");
                          })()}
                        </MuiTableCell>
                        <MuiTableCell>
                          {item?.status?.toUpperCase()}
                        </MuiTableCell>
                      </MuiTableRow>
                    );
                  }
                })}
              </MuiTableBody>
              <MuiTableFooter>
                <MuiTableRow>
                  <MuiTablePagination
                    rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    colSpan={8}
                    count={totalCount}
                    rowsPerPage={count}
                    page={page - 1}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={(event, page) => {
                      if (loadingStates[0] === true) {
                        return false;
                      }
                      setPage(page + 1);
                    }}
                    onRowsPerPageChange={(event) => {
                      setPage(1);
                      setCount(parseInt(event.target.value));
                    }}
                    showFirstButton={true}
                    showLastButton={true}
                    // ActionsComponent={TablePaginationActions}
                  />
                </MuiTableRow>
              </MuiTableFooter>
            </MuiTable>
          </MuiTableContainer>
        </MuiGrid>
      </MuiGrid>
    </LayoutV2>
  );
}
