import { useContext } from "react";

import contexts from "../contexts";

function useAdministratorState() {
  const [administratorState, setAdministratorState] = useContext(
    contexts.administratorContext
  );

  return [administratorState, setAdministratorState];
}

export default useAdministratorState;
