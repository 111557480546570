import { useState, useEffect } from "react";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Dialog as MuiDialog,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider as MuiDivider,
  FormControlLabel as MuiFormControlLabel,
  IconButton as MuiIconButton,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Select as MuiSelect,
  ListItem as MuiListItem,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  Checkbox as MuiCheckBox,
  List as MuiList,
  ListItemAvatar as MuiListItemAvatar,
  ListItemText as MuiListItemText,
  Avatar as MuiAvatar,
} from "@material-ui/core";

import {
  Add as MuiAddIcon,
  DeleteOutline as MuiDeleteOutlineIcon,
} from "@material-ui/icons";

import { useGlobalState } from "../../../../../../../../../../hooks";

import {
  requestFee__getAllFees,
  requestInvoices,
  requestStudents,
} from "../../../../../../../../../../utils/apiRequests";

import "./styles.css";

export default function GenerateInvoiceDialog(props) {
  const { globalState, setGlobalState } = useGlobalState();
  const [isTemplate, setIsTemplate] = useState(false);
  const [total, setTotal] = useState(0);
  const [formState, setFormState] = useState({
    issuedTo: "",
    fees: [],
  });
  const [lastSearchDuration, setLastSearchDuration] = useState(Date.now());
  const [studentSearchResult, setStudentSearchResult] = useState([]);

  const [menuState, setMenuState] = useState({
    isOpen: false,
    anchorElement: null,
    handleOpen: function (anchorElement) {
      setMenuState({
        ...menuState,
        isOpen: true,
        anchorElement: anchorElement,
      });
    },
    handleClose: function () {
      setMenuState({
        ...menuState,
        isOpen: false,
        anchorElement: null,
      });
    },
  });

  useEffect(() => {
    if (globalState.fees.length < 1) {
      requestFee__getAllFees().then((data) => {
        setGlobalState((globalState) => {
          return {
            ...globalState,
            fees: [...data],
          };
        });
      });
    }
  }, [globalState.fees.length, setGlobalState]);

  useEffect(() => {
    let sum = 0;
    formState.fees.forEach((item) => {
      sum += item.amount;
    });
    setTotal(sum);
  }, [formState]);

  return (
    <MuiDialog
      open={props.state.isOpen}
      onClose={() => props.state.close()}
      className={"GenerateInvoiceDialog"}
    >
      <MuiGrid container direction={"column"} className={"MuiGrid1"}>
        <MuiGrid className={"mb-3"}>
          <div className={"w-100"}>
            <MuiFormControlLabel
              label={"Template"}
              control={<MuiCheckBox checked={isTemplate} />}
              onClick={() => {
                if (isTemplate) {
                  setIsTemplate(false);
                  setFormState((formState) => {
                    return { fees: [...formState.fees], type: "DEFAULT" };
                  });
                } else {
                  setIsTemplate(true);
                  setFormState((formState) => {
                    return { fees: [...formState.fees], type: "TEMPLATE" };
                  });
                }
              }}
            />
          </div>
          <MuiDivider className={"my-2 bg-dark w-100"} />
          <MuiTable>
            <MuiTableHead>
              <MuiTableRow>
                <MuiTableCell className={"px-0 text-white"}>
                  {"_______"}
                </MuiTableCell>
                <MuiTableCell>Item</MuiTableCell>
                <MuiTableCell>Amount</MuiTableCell>
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody>
              {formState.fees.map((item, index) => {
                return (
                  <MuiTableRow key={index}>
                    <MuiTableCell className={"px-0"}>
                      <MuiIconButton
                        size={"small"}
                        className={"bg-danger text-white"}
                        onClick={() => {
                          setFormState((formState) => {
                            return {
                              ...formState,
                              fees: [
                                ...formState.fees.filter((fees, index2) => {
                                  return index2 !== index;
                                }),
                              ],
                            };
                          });
                        }}
                      >
                        <MuiDeleteOutlineIcon />
                      </MuiIconButton>
                    </MuiTableCell>
                    <MuiTableCell>
                      <MuiGrid>
                        {item.title.length < 1 && (
                          <MuiSelect
                            displayEmpty={true}
                            value={""}
                            className={"w-100"}
                          >
                            <MuiListItem value={""} disabled={true}>
                              {"Select a fee"}
                            </MuiListItem>
                            {globalState.fees.map((fee, index3) => {
                              return (
                                <MuiListItem
                                  onClick={(event) => {
                                    if (fee.hasPriceVariety === true) {
                                      setFormState((formState) => {
                                        formState.fees[index].title = fee.title;
                                        formState.fees[
                                          index
                                        ].hasPriceVariety = true;
                                        return {
                                          ...formState,
                                        };
                                      });
                                    }
                                    if (fee.hasPriceVariety === false) {
                                      setFormState((formState) => {
                                        formState.fees[index].title = fee.title;
                                        formState.fees[index].amount =
                                          fee.price;
                                        return {
                                          ...formState,
                                        };
                                      });
                                    }
                                  }}
                                >
                                  {fee.title}
                                </MuiListItem>
                              );
                            })}
                          </MuiSelect>
                        )}
                        {item.title.length > 0 && item.title}
                      </MuiGrid>
                      <MuiGrid>
                        {item.hasPriceVariety === true && (
                          <>
                            {item.subTitle.length < 1 && (
                              <MuiSelect
                                className={"w-100"}
                                displayEmpty={true}
                                value={""}
                              >
                                <MuiListItem disabled={true} value={""}>
                                  Select a variant
                                </MuiListItem>
                                {[
                                  ...globalState.fees.filter(
                                    (item4) => item4.title === item.title
                                  )[0].prices,
                                ].map((item5, index5) => {
                                  return (
                                    <MuiListItem
                                      onClick={(event) => {
                                        setFormState((formState) => {
                                          formState.fees[index].subTitle =
                                            item5.variant;
                                          formState.fees[index].amount =
                                            item5.price;
                                          return {
                                            ...formState,
                                          };
                                        });
                                      }}
                                    >
                                      {item5.variant}
                                    </MuiListItem>
                                  );
                                })}
                              </MuiSelect>
                            )}
                            {item.subTitle}
                          </>
                        )}
                      </MuiGrid>
                    </MuiTableCell>
                    <MuiTableCell className={"fw-bold fs-5"}>
                      {item.amount.toLocaleString("en-us")}
                    </MuiTableCell>
                  </MuiTableRow>
                );
              })}
              <MuiTableRow>
                <MuiTableCell colSpan={3} className={"px-0"}>
                  <MuiIconButton
                    size={"small"}
                    className={"bg-success text-white"}
                    onClick={() => {
                      setFormState((formState) => {
                        return {
                          ...formState,
                          fees: [
                            ...formState.fees,
                            {
                              title: "",
                              subTitle: "",
                              amount: 0,
                            },
                          ],
                        };
                      });
                    }}
                  >
                    <MuiAddIcon />
                  </MuiIconButton>
                </MuiTableCell>
              </MuiTableRow>
              <MuiTableRow>
                <MuiTableCell colSpan={2} className={"fs-4 fw-bold px-0"}>
                  {"Total"}
                </MuiTableCell>
                <MuiTableCell>{total.toLocaleString()}</MuiTableCell>
              </MuiTableRow>
            </MuiTableBody>
          </MuiTable>
        </MuiGrid>
        <MuiDivider className={"my-2"} />
        {isTemplate ? (
          ""
        ) : (
          <MuiGrid>
            <MuiTypography className={"mb-3"}>{"Issue to"}</MuiTypography>
            <MuiTextField
              value={formState.issuedTo}
              type={"text"}
              variant={"outlined"}
              className={"w-100"}
              onClick={(event) => menuState.handleOpen(event.currentTarget)}
              onChange={(event) => {
                if (event.target.value.length > 2) {
                  requestStudents
                    .search({ search: event.target.value })
                    .then((data) => {
                      if (data.length < 20) {
                        setStudentSearchResult([...data]);
                      } else {
                        setStudentSearchResult([]);
                      }
                    });
                } else {
                  setStudentSearchResult([]);
                }

                setFormState((formState) => {
                  return {
                    ...formState,
                    issuedTo: event.target.value,
                  };
                });
              }}
            />
            <MuiList className={"bg-light"}>
              {studentSearchResult.length === 0 ? (
                <MuiTypography
                  variant={"h3"}
                  align={"center"}
                  className={"text-muted"}
                >
                  {"No students found"}
                </MuiTypography>
              ) : (
                studentSearchResult.map((student, index) => {
                  if (index < 14) {
                    return (
                      <MuiListItem
                        key={index}
                        role={"button"}
                        onClick={() => {
                          setFormState((formState) => {
                            return {
                              ...formState,
                              issuedTo: student._id,
                            };
                          });
                        }}
                      >
                        <MuiListItemAvatar>
                          <MuiAvatar
                            alt="Remy Sharp"
                            src={`${
                              process.env.REACT_APP_API_BASE_URL
                            }/uploads/images/profile/${student._id.replaceAll(
                              "/",
                              "-"
                            )}.jpg`}
                          />
                        </MuiListItemAvatar>
                        <MuiListItemText
                          primary={`${student.lastName} ${student.firstName}`}
                          secondary={`${student._id}`}
                        />
                      </MuiListItem>
                    );
                  }
                })
              )}
              <MuiButton
                size={"small"}
                variant={"contained"}
                className={"d-block mx-auto bg-success text-white my-2"}
                onClick={() => {
                  requestStudents
                    .search({ search: formState.issuedTo })
                    .then((data) => {
                      if (data.length < 20) {
                        setStudentSearchResult([...data]);
                      } else {
                        setStudentSearchResult([]);
                      }
                    });
                }}
              >
                {"Search again"}
              </MuiButton>
            </MuiList>
            <MuiDivider className={"my-2"} />
          </MuiGrid>
        )}

        {isTemplate ? (
          <MuiGrid>
            <MuiTypography>{"Title"}</MuiTypography>
            <MuiTextField
              type={"text"}
              variant={"filled"}
              placeholder={"Title"}
              className={"w-100"}
              onChange={(event) => {
                setFormState((formState) => {
                  return {
                    ...formState,
                    title: event.target.value,
                  };
                });
              }}
            />
            <MuiDivider className={"my-2"} />
          </MuiGrid>
        ) : (
          ""
        )}

        {isTemplate ? (
          ""
        ) : (
          <MuiGrid>
            <MuiTypography>{"Status"}</MuiTypography>
            <MuiRadioGroup
              defaultValue={"Unpaid"}
              onChange={(event) => {
                setFormState((formState) => {
                  return {
                    ...formState,
                    status: event.target.value,
                  };
                });
              }}
            >
              <MuiFormControlLabel
                value="Unpaid"
                control={<MuiRadio color="primary" />}
                label="Unpaid"
                className={"text-warning"}
              />
              <MuiFormControlLabel
                value="PartPayment"
                control={<MuiRadio color="primary" />}
                label="Part-Payment"
                className={"text-success"}
              />
              <MuiFormControlLabel
                value="Paid"
                control={<MuiRadio color="primary" />}
                label="Paid"
                className={"text-success"}
              />
              <MuiFormControlLabel
                value="Overdue"
                control={<MuiRadio color="primary" />}
                label="Overdue"
                className={"text-danger"}
              />
            </MuiRadioGroup>
            <MuiDivider className={"my-2"} />
          </MuiGrid>
        )}

        <MuiGrid>
          <MuiButton
            variant={"contained"}
            color={"primary"}
            className={"me-2"}
            onClick={() => {
              if (isTemplate) {
                requestInvoices.templates
                  .createInvoice(formState)
                  .then((data) => {
                    props.state.close();
                    alert("Template created successfully!");
                  });
              } else {
                requestInvoices.createInvoice(formState).then((data) => {
                  props.state.close();
                  requestInvoices.findAllInvoices().then((data) => {
                    if (data.length > 0) {
                      setGlobalState((globalState) => {
                        globalState.invoices.default = [...data];
                        return {
                          ...globalState,
                        };
                      });
                    }
                  });

                  alert("Invoice successfully created");
                });
              }
            }}
          >
            {isTemplate ? "Save" : "Issue"}
          </MuiButton>
          <MuiButton
            variant={"contained"}
            color={"secondary"}
            className={"me-2"}
          >
            {"Reset"}
          </MuiButton>
        </MuiGrid>
      </MuiGrid>
    </MuiDialog>
  );
}
