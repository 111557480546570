import { useEffect, useState, Fragment } from "react";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Typography as MuiTypography,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Grow as MuiGrow,
  Zoom as MuiZoom,
  Collapse as MuiCollapse,
  TextField as MuiTextField,
  TextareaAutosize as MuiTextareaAutosize,
} from "@material-ui/core";

import { useUserState, useGlobalState } from "../../../../../../hooks";
import { requestAnnouncements } from "../../../../../../utils/apiRequests";

export default function Announcements() {
  const { userState } = useUserState();
  const { globalState, setGlobalState } = useGlobalState();
  const [announcements, setAnnouncements] = useState(globalState.announcements);

  useEffect(() => {
    if (globalState.announcements.length === 0) {
      requestAnnouncements
        .findAllAnnouncements()
        .then((data) => {
          setGlobalState((globalState) => {
            return {
              ...globalState,
              announcements: [...data],
            };
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, []);

  useEffect(() => {
    setAnnouncements((announcements) => {
      return [...globalState.announcements];
    });
  }, [globalState]);

  const [createAnnouncement, setCreateAnnouncement] = useState(false);
  const [formState, setFormState] = useState({ title: "", message: "" });

  return (
    <MuiGrid container={true} className={"Class_Announcements pt-4 px-3"}>
      <MuiGrid container={true}>
        <MuiGrid container={true}>
          <MuiGrid container={true} className={"MuiGrid1 w-100 p-2"}>
            <MuiGrid className={"flex-grow-1"}>
              <MuiTypography variant={"h6"} className={"MuiTypography1 h-100"}>
                {"Announcements"}
              </MuiTypography>
            </MuiGrid>
            <MuiGrid item={true}>
              <MuiButton className={"bg-white mx-1"} onClick={() => {}}>
                <MuiTypography
                  variant={"button"}
                  className={"mx-1"}
                  onClick={() => {
                    requestAnnouncements
                      .findAllAnnouncements()
                      .then((data) => {
                        setGlobalState((globalState) => {
                          return {
                            ...globalState,
                            announcements: [...data],
                          };
                        });
                      })
                      .catch((error) => {
                        alert(error);
                      });
                  }}
                >
                  {"Refresh"}
                </MuiTypography>
              </MuiButton>
              <MuiButton className={"bg-white mx-1"}>
                <MuiTypography
                  variant={"button"}
                  className={"mx-1"}
                  onClick={(event) => {
                    setCreateAnnouncement(true);
                  }}
                >
                  {"Create"}
                </MuiTypography>
              </MuiButton>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>

        <MuiCollapse in={createAnnouncement} className={"w-100"}>
          <MuiGrid className={"w-100 py-4"}>
            <MuiTypography className={"mt-3 mb-1"}>{"Title"}</MuiTypography>
            <MuiTextField
              variant={"outlined"}
              className={"w-100"}
              onChange={(e) => {
                setFormState({
                  ...formState,
                  title: e.target.value,
                });
              }}
            />
            <MuiTypography className={"mt-3 mb-1"}>{"Message"}</MuiTypography>
            <MuiTextareaAutosize
              className={"w-100 form-control"}
              variant={"outlined"}
              type={"text"}
              minRows={3}
              onChange={(e) => {
                setFormState({
                  ...formState,
                  message: e.target.value,
                });
              }}
            />
            <MuiGrid className={"py-3"}>
              <MuiButton
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  requestAnnouncements
                    .createAnnouncement({
                      ...formState,
                      createdBy: { id: userState._id, role: "admin" },
                    })
                    .then((data) => {
                      requestAnnouncements
                        .findAllAnnouncements()
                        .then((data) => {
                          setGlobalState((globalState) => {
                            return {
                              ...globalState,
                              announcements: [...data],
                            };
                          });
                        })
                        .catch((error) => {
                          alert(error);
                        });
                      alert("Announcement created successfully");
                    })
                    .catch((error) => alert(error));
                }}
              >
                {"Submit"}
              </MuiButton>

              <MuiButton
                variant={"contained"}
                color={"secondary"}
                className={"mx-3"}
                onClick={() => {
                  setCreateAnnouncement(false);
                }}
              >
                {"Close"}
              </MuiButton>
            </MuiGrid>
          </MuiGrid>
        </MuiCollapse>

        <MuiTable className={" border"}>
          <MuiTableHead>
            <MuiTableRow>
              <MuiTableCell colSpan={2} className={"bg-info border-0 fw-bold"}>
                <MuiTypography>{"Announcements"}</MuiTypography>
              </MuiTableCell>
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody>
            {announcements.length === 0 ? (
              <MuiGrow in={true} timeout={1500}>
                <MuiTableRow>
                  <MuiTableCell colSpan={4}>
                    <MuiTypography
                      variant={"h1"}
                      className={"text-muted text-center"}
                    >
                      {"No announcements to show"}
                    </MuiTypography>
                  </MuiTableCell>
                </MuiTableRow>
              </MuiGrow>
            ) : (
              <Fragment>
                {announcements.map((announcement, index) => {
                  return (
                    <MuiZoom key={index} in={true} timeout={200 + index * 100}>
                      <MuiTableRow>
                        <MuiTableCell>
                          <MuiGrid>
                            <MuiTypography variant={"h6"} className={"fw-bold"}>
                              {announcement.title}
                            </MuiTypography>
                            <MuiTypography>
                              {announcement.message}
                            </MuiTypography>
                          </MuiGrid>
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiButton
                            onClick={() => {
                              requestAnnouncements
                                .deleteAnnouncementByID(announcement._id)
                                .then(() => {
                                  requestAnnouncements
                                    .findAllAnnouncements()
                                    .then((data) => {
                                      setGlobalState((globalState) => {
                                        return {
                                          ...globalState,
                                          announcements: [...data],
                                        };
                                      });
                                    })
                                    .catch((error) => {
                                      alert(error);
                                    });
                                  alert(
                                    "Deleted Successfully, You can refresh the table"
                                  );
                                })
                                .catch((error) => {
                                  alert(error);
                                });
                            }}
                          >
                            {"Delete"}
                          </MuiButton>
                          {/*  <MuiButton>Hide</MuiButton>*/}
                        </MuiTableCell>
                      </MuiTableRow>
                    </MuiZoom>
                  );
                })}
              </Fragment>
            )}
          </MuiTableBody>
        </MuiTable>
      </MuiGrid>
    </MuiGrid>
  );
}
