import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Paper as MuiPaper,
  Slide as MuiSlide,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Button as MuiButton,
} from "@material-ui/core";

export default function PasswordReset() {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [formState, setFormState] = useState({
    emailAddress: "",
    passwordResetPin: "",
    password: "",
  });
  return (
    <MuiGrid
      container={true}
      className={"vh-100"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <MuiGrid item={true} md={4}>
        <MuiPaper className={"px-3 py-5"}>
          <MuiDivider />
          <h1 className={"text-center"}>Password Reset</h1>
          <MuiDivider />
          <MuiSlide
            direction={"left"}
            in={page === 0}
            mountOnEnter
            unmountOnExit
          >
            <MuiGrid className={"d-flex flex-column"}>
              <MuiTypography className={"mx-auto mb-2"}>
                {"Enter email address"}
              </MuiTypography>
              <MuiTextField
                className={"mx-auto mb-4"}
                placeholder={"Enter email address here"}
                value={formState.emailAddress}
                type={"text"}
                variant={"outlined"}
                onChange={(event) => {}}
              />
              <MuiButton
                className={"mx-auto rounded-0 px-5"}
                color={"primary"}
                size={"large"}
                variant={"contained"}
                disableElevation={true}
                onClick={() => {
                  setPage(1);
                }}
              >
                {"submit"}
              </MuiButton>
            </MuiGrid>
          </MuiSlide>

          <MuiSlide
            direction={"left"}
            in={page === 1}
            mountOnEnter
            unmountOnExit
          >
            <MuiGrid className={"d-flex flex-column"}>
              <MuiTypography className={"mx-auto mb-2"}>
                {"enter reset pin"}
              </MuiTypography>
              <MuiTextField
                className={"mx-auto mb-4"}
                placeholder={"Enter reset pin here"}
                value={formState.passwordResetPin}
                type={"text"}
                variant={"outlined"}
                onChange={(event) => {}}
              />
              <MuiButton
                className={"mx-auto rounded-0 px-5"}
                color={"primary"}
                size={"large"}
                variant={"contained"}
                disableElevation={true}
                onClick={() => {
                  setPage(2);
                }}
              >
                {"submit"}
              </MuiButton>
            </MuiGrid>
          </MuiSlide>
          <MuiSlide
            direction={"left"}
            in={page === 2}
            mountOnEnter
            unmountOnExit
          >
            <MuiGrid className={"d-flex flex-column"}>
              <MuiTypography className={"mx-auto mb-2"}>
                {"Enter new password"}
              </MuiTypography>
              <MuiTextField
                className={"mx-auto mb-4"}
                placeholder={"Enter email address here"}
                value={formState.password}
                type={"text"}
                variant={"outlined"}
                onChange={(event) => {}}
              />
              <MuiTypography className={"mx-auto mb-2"}>
                {"repeat new password"}
              </MuiTypography>
              <MuiTextField
                className={"mx-auto mb-4"}
                placeholder={"Enter email address here"}
                value={formState.password}
                type={"text"}
                variant={"outlined"}
                onChange={(event) => {}}
              />
              <MuiButton
                className={"mx-auto rounded-0 px-5"}
                color={"primary"}
                size={"large"}
                variant={"contained"}
                disableElevation={true}
                onClick={() => {
                  console.log(history);
                  history.replace("/admin/signin");
                }}
              >
                {"submit"}
              </MuiButton>
            </MuiGrid>
          </MuiSlide>
        </MuiPaper>
      </MuiGrid>
    </MuiGrid>
  );
}
