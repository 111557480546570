export async function F1(requestClasses, classState, setClassState) {
  for (const student of classState.students) {
    await requestClasses.students.deassign(classState._id, student._id);
  }
  requestClasses
    .findClassByID(classState._id)
    .then((data) => {
      setClassState((classState) => {
        return {
          ...classState,
          ...data,
        };
      });
    })
    .catch((error) => {
      alert(error);
    });
  alert("Success!");
}
