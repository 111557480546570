import React, { useState } from "react";
import {
  Grid as MuiGrid,
  Tabs as MuiTabs,
  Tab as MuiTab,
  Slide as MuiSlide,
} from "@material-ui/core";

import { Invoices, Fees } from "./components";

export default function FeesAndInvoices() {
  const [tabValue, setTabValue] = useState(1);
  return (
    <MuiGrid
      container={true}
      item={true}
      value={tabValue}
      justifyContent={"center"}
      className={"p-3"}
    >
      <MuiGrid item={true} xs={12}>
        <MuiTabs
          onChange={(event, value) => {
            setTabValue(value);
          }}
          value={tabValue}
          className={"bg-primary text-white"}
        >
          <MuiTab value={1} label={"Invoices"} />
          <MuiTab value={2} label={"Fees"} />
        </MuiTabs>
        <MuiGrid>
          <MuiSlide
            in={tabValue === 1}
            direction={"left"}
            exit={false}
            mountOnEnter
            unmountOnExit
          >
            <MuiGrid>
              <Invoices />
            </MuiGrid>
          </MuiSlide>
          <MuiSlide
            in={tabValue === 2}
            direction={"left"}
            exit={false}
            mountOnEnter
            unmountOnExit
          >
            <MuiGrid>
              <Fees />
            </MuiGrid>
          </MuiSlide>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}
