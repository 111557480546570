import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAdministratorState, useComponents } from "../../hooks";
import {
  Box as MuiBox,
  Avatar as MuiAvatar,
  Typography as MuiTypography,
  Grid as MuiGrid,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
  Hidden as MuiHidden,
} from "@material-ui/core";

import { ChevronRightOutlined as MuiChevronRightOutlinedIcon } from "@mui/icons-material";

import { styled } from "@mui/material/styles";

import { Badge as MuiBadge } from "@mui/material";

import services from "../../../../services";
import { useScreenBreakpoint } from "../../../../hooks";

const StyledBadge = styled(MuiBadge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function HeaderV1() {
  const { sidebarV1 } = useComponents();
  const history = useHistory();
  const [administrator, setAdministrator] = useState({});
  const [administratorState] = useAdministratorState();
  useEffect(() => {
    if (!localStorage.getItem("administrator")) {
      history.replace("/admin/signin");
      return false;
    }
    services.api.administrators
      .findOne(localStorage.getItem("administrator"))
      .then((data) => {
        setAdministrator(data.data.administrator);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return (
    <MuiBox
      className={
        "absolute h-[60px] right-0 left-0 px-[10px] sm:px-[30px] bg-[white] z-50"
      }
    >
      <MuiBox className="d-flex justify-content-between align-items-center h-[100%]">
        <MuiGrid className="w-[120px]">
          <MuiHidden mdUp={true}>
            <MuiIconButton
              onClick={() => {
                sidebarV1.open();
              }}
            >
              <MuiChevronRightOutlinedIcon />
            </MuiIconButton>
          </MuiHidden>
        </MuiGrid>

        <MuiBox className={"h-100 d-flex align-items-center"}>
          <MuiTooltip title="Open Profile">
            <MuiIconButton
              onClick={() => history.push("/admin/dashboard/profile")}
              className={"p-0 border border-[grey]"}
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <MuiAvatar
                  className={""}
                  src={
                    administrator.image
                      ? administrator.image
                      : `https://eportal.parkbritishschool.com/api/uploads/images/profile/${administrator._id}.jpg`
                  }
                />
              </StyledBadge>
            </MuiIconButton>
          </MuiTooltip>
          <MuiTypography
            noWrap={true}
            className={"px-2 text-dark text-capitalize font-poppins font-light"}
          >{`${administrator?.firstName || ""} ${
            administrator?.lastName || ""
          }`}</MuiTypography>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
}
