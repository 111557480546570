import img6 from "../../../../../../../assets/images/img6.png";
import img3 from "../../../../../../../assets/images/img3.png";
import img4 from "../../../../../../../assets/images/img4.png";
import img14 from "../../../../../../../assets/images/img14.png";

export const _NWR4434R = [
  // {
  //   label: "Messages",
  //   count() {
  //     return 0;
  //   },
  // },
  // {
  //   label: "Notifications",
  //   count() {
  //     return 0;
  //   },
  // },
  {
    label: "Invoices",
    count(userState, globalState) {
      return userState.invoices.length;
    },
  },
  {
    label: "Results",
    count(userState, globalState) {
      return userState.results.length;
    },
  },
  {
    label: "Announcements",
    count(userState, globalState) {
      return globalState.announcements.length;
    },
  },
];

export const _FJ4R34R = [
  {
    label: "CLASS",
    image: img6,
    handleClick(userState, globalState, history) {
      const selectedClass = globalState.classes.find((_class, index) => {
        const selectedStudent = _class.students.find((student, index) => {
          if (student === userState._id) {
            return true;
          } else {
            return false;
          }
        });
        return selectedStudent;
      });
      if (selectedClass) {
        setTimeout(() => {
          history.push(
            `/class/dashboard?ID=${selectedClass._id}&accessMode=student`
          );
        }, 1000);
      } else {
        alert("You are not assigned to a class");
      }
    },
  },
  {
    label: "RESULTS",
    image: img3,
    handleClick(userState, globalState, history) {
      history.push("/student/dashboard/results");
    },
  },
  // {
  //   label: "MESSAGES",
  //   image: img5,
  //   handleClick(userState, globalState, history) {
  //     alert("page not ready");
  //   },
  // },
  // {
  //   label: "NOTIFICATIONS",
  //   image: img13,
  //   handleClick(userState, globalState, history) {
  //     history.push("/student/dashboard/notifications");
  //   },
  // },
  {
    label: "INVOICES",
    image: img14,
    handleClick(userState, globalState, history) {
      history.push("/student/dashboard/invoices");
    },
  },
  {
    label: "SIGN OUT",
    image: img4,
    handleClick(userState, globalState, history) {
      history.push("/");
    },
  },
];
