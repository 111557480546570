import { useState, useEffect } from "react";
import {
  Box as MuiBox,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Typography as MuiTypography,
  Avatar as MuiAvatar,
  Button as MuiButton,
  IconButton as MuiIconButton,
  Hidden as MuiHidden,
} from "@mui/material";

import {
  DeleteOutlined as MuiDeleteOutlinedIcon,
  VisibilityOutlined as MuiVisibilityOutlinedIcon,
  BlockOutlined as MuiBlockOutlinedIcon,
} from "@mui/icons-material";
import services from "../../../../../../../services";

export default function ManageStudents() {
  const [students, setStudents] = useState([]);
  useEffect(() => {
    services.api.students
      .findAll(1, 10)
      .then((data) => {
        setStudents(data.data.students);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return (
    <MuiGrid item={true} xs={12}>
      <MuiPaper elevation={0} className={"h-[100%]] rounded-0"}>
        <MuiBox className={"px-[10px] sm:px-[30px] py-[10px]"}>
          <MuiTypography className={"font-poppins font-light text-[16px]"}>
            Manage Students
          </MuiTypography>
        </MuiBox>
        <MuiDivider className={"bg-[black]"} />
        <MuiBox className={`px-[10px] sm:px-[30px]`}>
          {students.map((student, index) => {
            return (
              <MuiGrid
                container={true}
                alignItems={"center"}
                className={"w-[100%] py-[8px]"}
              >
                <MuiGrid
                  item={true}
                  xs={1}
                  sm={1}
                  className={"hidden sm:block"}
                >
                  <MuiTypography>{index + 1}</MuiTypography>
                </MuiGrid>
                <MuiGrid
                  item={true}
                  xs={2}
                  sm={2}
                  className={"px-[5px] overflow-x-hidden hidden sm:block"}
                >
                  <MuiAvatar
                    src={
                      student.image
                        ? student.image
                        : `https://eportal.parkbritishschool.com/api/uploads/images/profile/${student._id.replaceAll(
                            "/",
                            "-"
                          )}.jpg`
                    }
                  />
                </MuiGrid>
                <MuiGrid item={true} xs={5} sm={3} className={"px-[5px]"}>
                  <MuiTypography
                    noWrap={true}
                    className={"font-dm-sans text-[14px]"}
                  >
                    {`${
                      student.firstName?.charAt(0).toUpperCase() +
                      student.firstName?.slice(1)
                    } ${
                      student.lastName?.charAt(0).toUpperCase() +
                      student.lastName?.slice(1)
                    }`}
                  </MuiTypography>
                </MuiGrid>
                <MuiGrid item={true} xs={3}>
                  <MuiTypography
                    noWrap={true}
                    className={
                      "font-dm-sans font-light text-[gray] text-[14px]"
                    }
                  >
                    {student.class?.name ? student.class.name : "Not Assigned"}
                  </MuiTypography>
                </MuiGrid>
                <MuiGrid item={true} xs={4} sm={3} className={"px-[5px]"}>
                  <div className={"w-[100%] flex justify-end"}>
                    <MuiIconButton className={"p-[5px]"}>
                      <MuiVisibilityOutlinedIcon className={"text-[blue]"} />
                    </MuiIconButton>
                    <MuiIconButton className={"p-[5px]"}>
                      <MuiBlockOutlinedIcon className={"text-[red]"} />
                    </MuiIconButton>
                    <MuiIconButton className={"p-[5px]"}>
                      <MuiDeleteOutlinedIcon className={"text-[black]"} />
                    </MuiIconButton>
                  </div>
                </MuiGrid>
              </MuiGrid>
            );
          })}
        </MuiBox>
      </MuiPaper>
    </MuiGrid>
  );
}
