import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  IconButton as MuiIconButton,
} from "@mui/material";
import {
  AccountCircleOutlined as MuiAccountCircleTwoToneIcon,
  SettingsOutlined as MuiSettingsOutlinedIcon,
  CircleNotificationsOutlined as MuiCircleNotificationsOutlinedIcon,
  ExitToAppOutlined as MuiExitToAppOutlinedIcon,
} from "@mui/icons-material";
import services from "../../../../../../../services";

export default function Greeter() {
  const history = useHistory();
  const [administrator, setAdministrator] = useState({});

  useEffect(() => {
    services.api.administrators
      .findOne(localStorage.getItem("administrator"))
      .then((data) => {
        setAdministrator(data.data.administrator);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return (
    <MuiGrid item={true} xs={12} md={6}>
      <div className={"h-[100%] bg-[#707cd2] p-[30px]"}>
        <MuiTypography
          noWrap={true}
          className={
            "text-[white] font-poppins font-extralight text-[25px] sm:text-[45px]"
          }
        >
          {`${administrator.firstName} ${administrator.lastName}`}
        </MuiTypography>
        <MuiTypography
          noWrap={true}
          className={
            "text-[white] font-poppins font-extralight text-[18px] sm:text-[25px]"
          }
        >
          {new Date().toDateString()}
        </MuiTypography>
        <div className={"w-[100%] overflow-x-hidden mt-[20px] flex"}>
          <div>
            <MuiIconButton
              className={""}
              onClick={() => {
                history.push("/admin/dashboard/profile");
              }}
            >
              <MuiAccountCircleTwoToneIcon
                className={"text-[white] text-[35px] sm:text-[60px]"}
              />
            </MuiIconButton>
          </div>
          <div>
            <MuiIconButton
              className={""}
              onClick={() => {
                alert("Admin settings feature in progress!!!");
              }}
            >
              <MuiSettingsOutlinedIcon
                className={"text-[white] text-[35px] sm:text-[60px]"}
              />
            </MuiIconButton>
          </div>
          <div>
            <MuiIconButton
              className={""}
              onClick={() => {
                alert("Admin notifications feature in progress!!!");
              }}
            >
              <MuiCircleNotificationsOutlinedIcon
                className={"text-[white] text-[35px] sm:text-[60px]"}
              />
            </MuiIconButton>
          </div>
          <MuiGrid item={true}>
            <MuiIconButton
              className={""}
              onClick={() => {
                history.replace("/admin/signin");
                sessionStorage.clear();
                localStorage.clear();
              }}
            >
              <MuiExitToAppOutlinedIcon
                className={"text-[white] text-[35px] sm:text-[60px]"}
              />
            </MuiIconButton>
          </MuiGrid>
        </div>
      </div>
    </MuiGrid>
  );
}
