import { useState, useEffect } from "react";
import { Grid as MuiGrid, Fab as MuiFab } from "@material-ui/core";

import { Add } from "@material-ui/icons";
import {
  requestFee__getAllFees,
  requestFee__deleteFee,
} from "../../../../../../../../utils/apiRequests";

import { Fee, GenerateFeeDialog } from "./components";

import { useGlobalState } from "../../../../../../../../hooks";

import "./styles.css";

export default function Fees() {
  const { globalState, setGlobalState } = useGlobalState();
  const [state, setState] = useState({
    GenerateFeeDialog: {
      isOpen: false,
      open: function () {
        setState({
          ...state,
          GenerateFeeDialog: { ...state.GenerateFeeDialog, isOpen: true },
        });
      },
      close: function () {
        setState({
          ...state,
          GenerateFeeDialog: { ...state.GenerateFeeDialog, isOpen: false },
        });
      },
    },
    fees: [],
    deleteFee: function (feeID) {
      requestFee__deleteFee(feeID).then((data) => {
        const newState = { ...globalState };
        newState.fees = newState.fees.filter((item) => {
          return item._id !== feeID;
        });
        setGlobalState({ ...newState });
      });
    },
  });

  useEffect(() => {
    if (globalState.fees.length < 1) {
      requestFee__getAllFees().then((data) => {
        if (data.length > 0) {
          setGlobalState((globalState) => {
            return {
              ...globalState,
              fees: data,
            };
          });
        }
        console.log(data);
      });
    }
  }, [globalState]);
  return (
    <MuiGrid className={"Fees w-100"}>
      {globalState.fees.map((item, index) => {
        return (
          <Fee
            {...item}
            key={index}
            index={index}
            deleteFee={state.deleteFee}
          />
        );
      })}

      <MuiFab
        color={"primary"}
        className={"MuiFab1"}
        onClick={() => {
          state.GenerateFeeDialog.open();
        }}
      >
        <Add />
      </MuiFab>
      <GenerateFeeDialog state={state.GenerateFeeDialog} />
    </MuiGrid>
  );
}
