import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Header, Spreadsheet } from "./components";
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Paper as MuiPaper,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  Button as MuiButton,
  Divider as MuiDivider,
  Snackbar as MuiSnackbar,
  TextareaAutosize as MuiTextareaAutosize,
  CircularProgress as MuiCircularProgress,
  Chip as MuiChip,
} from "@material-ui/core";

import {
  requestAddResult,
  getAllClasses,
  requestTerm,
  requestSession,
} from "../../utils/apiRequests";
import { useGlobalState, useUserState } from "../../hooks";

import services from "../../services";

export default function UploadResult() {
  const history = useHistory();
  const { globalState, getClasses, getTerm, getSession } = useGlobalState();
  const { userState } = useUserState();
  const [state, setState] = useState({
    teacher: userState,
    student: null,
    class: null,
    session: null,
    term: null,
    resultType: "endOfTerm",
    spreadsheetData: {
      midTerm: {
        columns: ["Subject", "Score"],
        rows: [],
      },
      endOfTerm: {
        columns: ["Subject", "Test", "Exam"],
        rows: [],
      },
    },
  });

  const [submitProgress, setSubmitProgress] = useState(false);

  const [formState, setFormState] = useState({
    school: "",
    student: "",
    scoreSheet: "",
    term: "",
    session: "",
    class: "",
    teachersRemark: "",
    gradingScale: "",
    principalsRemark: "",
    resumptionDate: "",
    title: "",
    type: "endOfTerm",
    comments: "N/A",
  });

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
    handleClose: function () {
      setSnackbarState({
        ...snackbarState,
        open: false,
        message: "",
        severity: "",
      });
    },
    handleOpen: function (message, severity) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: message,
        severity: severity,
      });
    },
  });

  // const [scoresheetData, setScoresheetData] = useState({
  //   midTerm: {
  //     columns: ["Subject", "Score"],
  //     rows: [
  //       ...["test", "wee", "fee"].map((item) => {
  //         return (
  //           [{ value: item }, { value: "" }]
  //         )
  //       })
  //     ],
  //   },
  //   endOfTerm: {
  //     columns: ["Subject", "Test", "Exam"],
  //     rows: [
  //       state.class
  //         ?
  //         state.class.subjects.map((item) => {
  //           return (
  //             [{ value: item }, { value: "" }, { value: "" }, { value: "" }]
  //           )
  //         })
  //         :
  //         []
  //     ],
  //   }
  // })

  useEffect(() => {
    if (userState === null) {
      history.push("/signin");
    } else {
      if (userState.role !== "teacher") {
        history.push("/signin");
      }
    }
    getClasses();
    getTerm();
    getSession();
    getAllClasses().then((data) => {
      state.class = getClass(data, userState._id);
      state.spreadsheetData.endOfTerm.rows = [
        ...getClass(data, userState._id).subjects.map((item) => {
          return [{ value: item }, { value: "" }];
        }),
      ];
      state.spreadsheetData.midTerm.rows = [
        ...getClass(data, userState._id).subjects.map((item) => {
          return [{ value: item }, { value: "" }];
        }),
      ];
      setState({ ...state });
      formState.class = getClass(data, userState._id)._id;
      setFormState({ ...formState });
    });
    requestTerm().then((data) => {
      state.term = data.term;
      setState({ ...state });
      formState.term = data.term;
      setFormState({ ...formState });
    });
    requestSession().then((data) => {
      state.session = data.session;
      setState({ ...state });
      formState.session = data.session;
      setFormState({ ...formState });
    });
  }, []);

  function getClass(allClasses, teacherID) {
    const selectedClass = allClasses.find((_class, index) => {
      const selectedTeacher = _class.teachers.find((student, index) => {
        if (student._id === teacherID) {
          return true;
        } else {
          return false;
        }
      });
      return selectedTeacher;
    });
    if (selectedClass) {
      return selectedClass;
    } else {
      return "Not Assigned";
    }
  }

  return (
    <MuiGrid container={true} justify={"center"} className={"mt-5 pt-5"}>
      <Header />
      <MuiGrid item={true} xs={11} sm={9} md={8} lg={7} xl={6}>
        <MuiPaper elevation={8} className={"mb-5 px-3 py-4"}>
          <MuiTypography variant={"h5"}>
            Please fill in all details correctly
          </MuiTypography>
          <MuiDivider className={"mb-3"} />
          <MuiTypography className={"mt-3"}>School</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={formState.school}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, school: e.target.value });
            }}
          >
            <MuiMenuItem value={"Park British School"}>
              {"Park British School"}
            </MuiMenuItem>
            <MuiMenuItem value={"Park Royal College"}>
              {"Park Royal College"}
            </MuiMenuItem>
          </MuiSelect>
          <MuiTypography className={"mt-3"}>Title</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={formState.title}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, title: e.target.value });
            }}
          >
            <MuiMenuItem
              value={"PROVISIONAL RESULTS INFORMATION FOR SPRING TERM TEST"}
            >
              {"PROVISIONAL RESULTS INFORMATION FOR SPRING TERM TEST"}
            </MuiMenuItem>
            <MuiMenuItem
              value={
                "PROVISIONAL RESULTS INFORMATION FOR SPRING TERM EXAMINATION"
              }
            >
              {"PROVISIONAL RESULTS INFORMATION FOR SPRING TERM EXAMINATION"}
            </MuiMenuItem>
            <MuiMenuItem
              value={"PROVISIONAL RESULTS INFORMATION FOR AUTUMN TERM TEST"}
            >
              {"PROVISIONAL RESULTS INFORMATION FOR AUTUMN TERM TEST"}
            </MuiMenuItem>
            <MuiMenuItem
              value={
                "PROVISIONAL RESULTS INFORMATION FOR AUTUMN TERM EXAMINATION"
              }
            >
              {"PROVISIONAL RESULTS INFORMATION FOR AUTUMN TERM EXAMINATION"}
            </MuiMenuItem>
            <MuiMenuItem
              value={"PROVISIONAL RESULTS INFORMATION FOR SUMMER TERM TEST"}
            >
              {"PROVISIONAL RESULTS INFORMATION FOR SUMMER TERM TEST"}
            </MuiMenuItem>
            <MuiMenuItem
              value={
                "PROVISIONAL RESULTS INFORMATION FOR SUMMER TERM EXAMINATION"
              }
            >
              {"PROVISIONAL RESULTS INFORMATION FOR SUMMER TERM EXAMINATION"}
            </MuiMenuItem>
          </MuiSelect>

          <MuiTypography className={"mt-2"}>Student ID</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            onChange={(e) => {
              setFormState({ ...formState, student: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Class</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={state.class ? state.class._id : ""}
            variant={"outlined"}
            disabled={true}
          >
            {globalState.classes.map((item, index) => {
              return (
                <MuiMenuItem key={index} value={item._id}>
                  {item.name}
                </MuiMenuItem>
              );
            })}
          </MuiSelect>
          <MuiTypography className={"mt-3"}>Session</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            value={state.session ? state.session : ""}
            disabled
          />
          <MuiTypography className={"mt-3"}>Term</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            value={state.term ? state.term : ""}
            disabled
          />
          <MuiDivider />
          <MuiTypography className={"mt-3"}>Grading Scale</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={formState.gradingScale}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, gradingScale: e.target.value });
            }}
          >
            <MuiMenuItem value={"foundation"}>{"Foundation"}</MuiMenuItem>
            <MuiMenuItem value={"pbs"}>{"PBS"}</MuiMenuItem>
            <MuiMenuItem value={"prc"}>{"PRC-junior"}</MuiMenuItem>
            <MuiMenuItem value={"igcse"}>{"IGCSE"}</MuiMenuItem>
            <MuiMenuItem value={"waec"}>{"WAEC"}</MuiMenuItem>
            <MuiMenuItem value={"aslevel"}>{"AS LEVEL"}</MuiMenuItem>
          </MuiSelect>
          <MuiTypography className={"mt-3"}>Result Type</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={state.resultType}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, type: e.target.value });
              state.resultType = e.target.value;
              setState({ ...state });
            }}
          >
            <MuiMenuItem value={"midTerm"}>{"Mid-Term"}</MuiMenuItem>
            <MuiMenuItem value={"endOfTerm"}>{"Full-Term"}</MuiMenuItem>
          </MuiSelect>

          {state.class ? (
            <Spreadsheet
              scoresheetData={state.spreadsheetData}
              setScoresheetData={(data) => {
                setFormState({
                  ...formState,
                  scoreSheet: JSON.stringify(data),
                });
              }}
              type={state.resultType}
            />
          ) : (
            ""
          )}

          {state.class
            ? state.class.subjects.map((item, index) => {
                return (
                  <MuiChip
                    key={index}
                    label={item}
                    className={"me-1 mb-2"}
                    onDelete={() => {
                      state.class.subjects = state.class.subjects.filter(
                        (value, index2, arr) => {
                          return value !== item;
                        }
                      );
                      state.spreadsheetData.endOfTerm.rows = [
                        ...state.class.subjects.map((item) => {
                          return [{ value: item }, { value: "" }];
                        }),
                      ];
                      state.spreadsheetData.midTerm.rows = [
                        ...state.class.subjects.map((item) => {
                          return [{ value: item }, { value: "" }];
                        }),
                      ];
                      console.log(state);
                      setState({ ...state });
                    }}
                  />
                );
              })
            : ""}

          <MuiTypography className={"mt-3"}>Teacher's Remark</MuiTypography>
          <MuiTextareaAutosize
            className={"w-100 form-control"}
            variant={"outlined"}
            type={"text"}
            onChange={(e) => {
              setFormState({ ...formState, teachersRemark: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Comments</MuiTypography>
          <MuiTextareaAutosize
            className={"w-100 form-control"}
            variant={"outlined"}
            type={"text"}
            onChange={(e) => {
              setFormState({ ...formState, comments: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Principal's Remark</MuiTypography>
          <MuiTextareaAutosize
            className={"w-100 form-control"}
            variant={"outlined"}
            type={"text"}
            disabled={userState.role !== "admin" ? true : false}
            onChange={(e) => {
              setFormState({ ...formState, principalsRemark: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Resumption Date</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            onChange={(e) => {
              setFormState({ ...formState, resumptionDate: e.target.value });
            }}
          />
          <MuiDivider />
          <MuiButton
            className={"mt-3"}
            color={"primary"}
            variant={"contained"}
            disabled={submitProgress}
            onClick={() => {
              setSubmitProgress(true);
              console.log(formState);
              services.api.results
                .upload({
                  ...formState,
                })
                .then((data) => {
                  snackbarState.handleOpen(
                    `Result successfully uploaded.`,
                    "success"
                  );
                  setTimeout(() => {
                    setSubmitProgress(false);
                  }, 2000);
                })
                .catch((error) => {
                  alert(error.message);
                });
            }}
          >
            {submitProgress ? (
              <MuiCircularProgress size={24} color="secondary" />
            ) : (
              "upload"
            )}
          </MuiButton>
        </MuiPaper>
      </MuiGrid>

      <MuiSnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarState.open}
        message={snackbarState.message}
        action={
          <>
            <MuiButton
              color="secondary"
              size="small"
              onClick={snackbarState.handleClose}
            >
              {"CLOSE"}
            </MuiButton>
          </>
        }
      />
    </MuiGrid>
  );
}
