import { useState, createContext, useEffect } from "react";

export const globalContext = createContext();
export const GlobalState = (props) => {
  const initialGlobalState = {
    term: "N/A",
    session: "N/A",
    classes: [],
    students: [],
    teachers: [],
    results: [],
    administrators: [],
    fees: [],
    invoices: {
      default: [],
      template: [],
      draft: [],
    },
    announcements: [],
  };
  const cache = JSON.parse(sessionStorage.getItem("globalState"));
  if (cache === null) {
    sessionStorage.setItem("globalState", JSON.stringify(initialGlobalState));
  }

  const [globalState, setGlobalState] = useState(cache || initialGlobalState);
  useEffect(() => {
    sessionStorage.setItem("globalState", JSON.stringify(globalState));
  }, [globalState]);

  useEffect(() => {
    console.log("GLOBAL STATE==>", globalState);
  }, [globalState]);
  return (
    <globalContext.Provider value={{ globalState, setGlobalState }}>
      {props.children}
    </globalContext.Provider>
  );
};
