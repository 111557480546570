import { Switch, Route } from "react-router-dom";

import { Dashboard, Create } from "./Pages";

import { ClassState } from "../../state/classState";

export default function Class() {
  return (
    <ClassState>
      <Switch>
        <Route path={"/class/dashboard"} component={Dashboard} />
        <Route path={"/class/create"} component={Create} />
      </Switch>
    </ClassState>
  );
}
