import axios from "axios";
axios.defaults.validateStatus = function () {
  return true;
};

const baseURL = `${process.env.REACT_APP_API_BASE_URL}`;

const api = {
  students: {
    metrics: function () {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/students/metrics`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.json());
            }
            if (response.status === 400) {
              reject(response.json());
            } else {
              reject(response.json());
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    search: function (search) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/students/search?search=${search}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    findAll: function (page, count) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/students/find-all?page=${page}&count=${count}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    findOne: function (id) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/students/find-one?id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    updateOne: function (id, operation, body) {
      return new Promise((resolve, reject) => {
        fetch(
          `${baseURL}/students/update-one?operation=${operation}&id=${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
            body: JSON.stringify(body),
          }
        )
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    activityLogs: {
      findAll: function (page, count) {
        return new Promise((resolve, reject) => {
          fetch(
            `${baseURL}/students/activity-logs/find-all?page=${page}&count=${count}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem(
                  "access-token"
                )}`,
              },
            }
          )
            .then((response) => {
              if (response.status === 200) {
                resolve(response.json());
              }
              if (response.status === 400) {
                reject(response.json());
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
    },
    single: {
      results: {
        find: function (id, page, count) {
          return new Promise((resolve, reject) => {
            fetch(
              `${baseURL}/students/${id.replaceAll(
                "/",
                "-"
              )}/results/find?page=${page}&count=${count}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
              .then((response) => {
                if (response.status !== 200) {
                  response.json().then((error) => {
                    reject(error);
                  });
                } else {
                  response.json().then((data) => {
                    resolve(data);
                  });
                }
              })
              .catch((error) => reject(error));
          });
        },
      },
    },
  },
  administrators: {
    metrics: function () {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/administrators/metrics`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.json());
            }
            if (response.status === 400) {
              reject(response.json());
            } else {
              reject(response.json());
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    create: function (body) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/administrators/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    findAll: function (page, count) {
      return new Promise((resolve, reject) => {
        fetch(
          `${baseURL}/administrators/find-all?page=${page}&count=${count}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
          }
        )
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },

    findOne: function (id) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/administrators/find-one?id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    updateOne: function (id, operation, body) {
      return new Promise((resolve, reject) => {
        fetch(
          `${baseURL}/administrators/update-one?operation=${operation}&id=${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
            body: JSON.stringify(body),
          }
        )
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    deleteOne: function (id) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/administrators/delete-one?id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    single: {
      update: function (id, operation, body) {
        return new Promise((resolve, reject) => {
          fetch(
            `${baseURL}/administrators/${id}/update?operation=${operation}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem(
                  "access-token"
                )}`,
              },
              body: JSON.stringify(body),
            }
          )
            .then((response) => {
              if (response.status !== 200) {
                response.json().then((error) => {
                  reject(error);
                });
              } else {
                response.json().then((data) => {
                  resolve(data);
                });
              }
            })
            .catch((error) => reject(error));
        });
      },
    },
    activityLogs: {
      findAll: function (page, count) {
        return new Promise((resolve, reject) => {
          fetch(
            `${baseURL}/administrators/activity-logs/find-all?page=${page}&count=${count}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem(
                  "access-token"
                )}`,
              },
            }
          )
            .then((response) => {
              if (response.status === 200) {
                resolve(response.json());
              }
              if (response.status === 400) {
                reject(response.json());
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
    },
  },
  results: {
    metrics: function () {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/results/metrics`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.json());
            }
            if (response.status === 400) {
              reject(response.json());
            } else {
              reject(response.json());
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    search: function (search) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/results/search?search=${search}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    upload: function (body) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/results/upload`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    findAll: function (page, count) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/results/find-all?page=${page}&count=${count}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    find: function (filter, page, count) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/results/find?page=${page}&count=${count}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
          body: JSON.stringify(filter),
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    findOne: function (id) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/results/find-one?id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    updateOne: function (id, operation, body) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/results/update-one?id=${id}&operation=${operation}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    deleteOne: function (id) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/results/delete-one?id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
  },
  teachers: {
    metrics: function () {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/teachers/metrics`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.json());
            }
            if (response.status === 400) {
              reject(response.json());
            } else {
              reject(response.json());
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    findAll: function (page, count) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/teachers/find-all?page=${page}&count=${count}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    findOne: function (id) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/teachers/find-one?id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    updateOne: function (id, operation, body) {
      return new Promise((resolve, reject) => {
        fetch(
          `${baseURL}/teachers/update-one?operation=${operation}&id=${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
            body: JSON.stringify(body),
          }
        )
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    activityLogs: {
      findAll: function (page, count) {
        return new Promise((resolve, reject) => {
          fetch(
            `${baseURL}/teachers/activity-logs/find-all?page=${page}&count=${count}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem(
                  "access-token"
                )}`,
              },
            }
          )
            .then((response) => {
              if (response.status === 200) {
                resolve(response.json());
              }
              if (response.status === 400) {
                reject(response.json());
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
    },
  },
  classes: {
    metrics: function () {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/classes/metrics`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.json());
            }
            if (response.status === 400) {
              reject(response.json());
            } else {
              reject(response.json());
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    findAll: function (page, count) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/classes/find-all?page=${page}&count=${count}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
  },
  variables: {
    get: function (key) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/variables/get?key=${key}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.json());
            }
            if (response.status === 400) {
              reject(response.json());
            } else {
              reject(response.json());
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    set: function (key, body) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/variables/set?key=${key}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.json());
            }
            if (response.status === 400) {
              reject(response.json());
            } else {
              reject(response.json());
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  auth: {
    signIn: {
      administrator: async function (body) {
        return new Promise((resolve, reject) => {
          fetch(`${baseURL}/auth/sign-in/administrator`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
            body: JSON.stringify(body),
          })
            .then((response) => {
              if (response.status !== 200) {
                response.json().then((error) => {
                  reject(error);
                });
              } else {
                response.json().then((data) => {
                  resolve(data);
                });
              }
            })
            .catch((error) => reject(error));
        });
      },
      teacher: async function (body) {
        return new Promise((resolve, reject) => {
          fetch(`${baseURL}/auth/sign-in/teacher`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
            body: JSON.stringify(body),
          })
            .then((response) => {
              if (response.status !== 200) {
                response.json().then((error) => {
                  reject(error);
                });
              } else {
                response.json().then((data) => {
                  resolve(data);
                });
              }
            })
            .catch((error) => reject(error));
        });
      },
      student: async function (body) {
        return new Promise((resolve, reject) => {
          fetch(`${baseURL}/auth/sign-in/student`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
            body: JSON.stringify(body),
          })
            .then((response) => {
              if (response.status !== 200) {
                response.json().then((error) => {
                  reject(error);
                });
              } else {
                response.json().then((data) => {
                  resolve(data);
                });
              }
            })
            .catch((error) => reject(error));
        });
      },
    },
    signOut: {
      administrator: async function () {
        return new Promise((resolve, reject) => {
          fetch(`${baseURL}/auth/sign-out/administrator`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
          })
            .then((response) => {
              if (response.status !== 200) {
                response.json().then((error) => {
                  reject(error);
                });
              } else {
                response.json().then((data) => {
                  resolve(data);
                });
              }
            })
            .catch((error) => reject(error));
        });
      },
      teacher: async function () {
        return new Promise((resolve, reject) => {
          fetch(`${baseURL}/auth/sign-out/teacher`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
          })
            .then((response) => {
              if (response.status !== 200) {
                response.json().then((error) => {
                  reject(error);
                });
              } else {
                response.json().then((data) => {
                  resolve(data);
                });
              }
            })
            .catch((error) => reject(error));
        });
      },
      student: async function () {
        return new Promise((resolve, reject) => {
          fetch(`${baseURL}/auth/sign-in/student`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
          })
            .then((response) => {
              if (response.status !== 200) {
                response.json().then((error) => {
                  reject(error);
                });
              } else {
                response.json().then((data) => {
                  resolve(data);
                });
              }
            })
            .catch((error) => reject(error));
        });
      },
    },
  },
  conversations: {
    create: function (body) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/conversations/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            if (response.status !== 200) {
              response.json().then((error) => {
                reject(error);
              });
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
          })
          .catch((error) => reject(error));
      });
    },
    single: {
      find: function (id) {
        return new Promise((resolve, reject) => {
          fetch(`${baseURL}/conversations/${id}/find`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              if (response.status !== 200) {
                response.json().then((error) => {
                  reject(error);
                });
              } else {
                response.json().then((data) => {
                  resolve(data);
                });
              }
            })
            .catch((error) => reject(error));
        });
      },
    },
  },
  v2: {
    students: {
      single: {
        authorize: function (parameters, query) {
          return axios({
            method: "POST",
            url: `${baseURL}/v2/students/${parameters.id}/authorize?pin=${query.pin}`,
            headers: {
              "Content-Type": "application/json",
            },
          });
        },
      },
      pins: {
        scramble: function () {
          return axios({
            method: "POST",
            url: `${baseURL}/v2/students/pins/scramble`,
            headers: {
              "Content-Type": "application/json",
            },
          });
        },
      },
    },
  },
};

export default api;
