import { useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Overview, Notifications, Announcements, Results } from "./pages";
import { useWidth, useUserState } from "../../../../hooks";
import { requestTeachers } from "../../../../utils/apiRequests";

export default function Dashboard() {
  const width = useWidth();
  const history = useHistory();
  const { userState, setUserState } = useUserState();

  useEffect(() => {
    requestTeachers.findTeacher
      .byID(userState._id.replaceAll(/\//g, "-"))
      .then((data) => {
        setUserState((userState) => {
          return {
            ...userState,
            ...data,
          };
        });
      });
  }, []);

  return (
    <>
      {userState ? (
        <Switch>
          <Route
            exact
            path={"/teacher/dashboard/overview"}
            component={Overview}
          />
          <Route
            exact
            path={"/teacher/dashboard/notifications"}
            component={Notifications}
          />
          <Route
            exact
            path={"/teacher/dashboard/announcements"}
            component={Announcements}
          />
          <Route
            exact
            path={"/teacher/dashboard/results"}
            component={Results}
          />
        </Switch>
      ) : (
        <Redirect to={"/signin"} />
      )}
    </>
  );
}
