import {
  Button as MuiButton,
  Divider as MuiDivider,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Modal as MuiModal,
  Paper as MuiPaper,
  Select as MuiSelect,
  Typography as MuiTypography,
} from "@material-ui/core";
import { IconButton as MuiIconButton } from "@mui/material";
import { Edit as MuiEditIcon } from "@mui/icons-material";
import { useState, useEffect } from "react";
import services from "../../../../../../../services";

export default function Term() {
  const [mode, setMode] = useState("default");
  const [term, setTerm] = useState("n/a");
  useEffect(() => {
    services.api.variables
      .get("term")
      .then((data) => {
        setTerm(data.data.variable.value);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return (
    <>
      <MuiGrid item={true} xs={12} sm={6} md={4} lg={3} xl={2}>
        <MuiPaper square elevation={0} className={"h-100 p-[20px]"}>
          <MuiTypography
            noWrap={true}
            className={"font-poppins text-[25px] font-light"}
          >
            {"Term"}
          </MuiTypography>
          <div className={"flex items-center"}>
            <MuiTypography noWrap={true} variant="h3" className={"flex-grow-1"}>
              {term}
            </MuiTypography>
          </div>
        </MuiPaper>
      </MuiGrid>
    </>
  );
}
