import { useHistory, useParams } from "react-router-dom";
import {
  Grid as MuiGrid,
  Collapse as MuiCollapse,
  Grow as MuiGrow,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  Typography as MuiTypography,
} from "@material-ui/core";

import { _N4RUD3 } from "./script.js";

import { useClassState } from "../../../../../../hooks";

import "./styles.css";

export default function Drawer(props) {
  const params = useParams();
  const history = useHistory();
  const { classState } = useClassState();

  return (
    <MuiCollapse
      direction="bottom"
      in={props.drawerOpen}
      timeout={{ appear: 5000, enter: 1000, exit: 500 }}
      unmountOnExit
      mountOnEnter
      className={"w-100"}
    >
      <MuiGrid container={true} className={`bg-color-primary-color w-100`}>
        <MuiList
          className={`w-100`}
          styles={{
            zIndex: 9998,
          }}
        >
          {_N4RUD3.map((item, index) => {
            return (
              <MuiGrow key={index} in={true} timeout={500 + index * 300}>
                <MuiListItem
                  role={"button"}
                  className={`m-0 px-3`}
                  key={index}
                  disableGutters={true}
                  onClick={() => {
                    props.setDrawerOpen(false);
                    history.replace(item.path);
                  }}
                >
                  <MuiListItemText>
                    <MuiTypography className={`text-white pe-4`}>
                      {item.label}
                    </MuiTypography>
                  </MuiListItemText>
                </MuiListItem>
              </MuiGrow>
            );
          })}
          {(classState.accessMode === "administrator" ||
            classState.accessMode === "teacher") && (
            <MuiGrow in={true} timeout={500 + (_N4RUD3.length + 1) * 300}>
              <MuiListItem
                role={"button"}
                className={`m-0 px-3`}
                disableGutters={true}
                onClick={() => {
                  props.setDrawerOpen(false);
                  history.replace("/class/dashboard/results");
                }}
              >
                <MuiListItemText>
                  <MuiTypography className={`text-white pe-4`}>
                    {"RESULTS"}
                  </MuiTypography>
                </MuiListItemText>
              </MuiListItem>
            </MuiGrow>
          )}
        </MuiList>
      </MuiGrid>
    </MuiCollapse>
  );
}
