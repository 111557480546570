import { useState, useEffect } from "react";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Tabs as MuiTabs,
  Tab as MuiTab,
  Avatar as MuiAvatar,
  IconButton as MuiIconButton,
} from "@mui/material";
import services from "../../../../../../../services";
import {
  BlockOutlined as MuiBlockOutlinedIcon,
  DeleteOutlined as MuiDeleteOutlinedIcon,
  VisibilityOutlined as MuiVisibilityOutlinedIcon,
  DoneOutlined as MuiDoneOutlinedIcon,
} from "@mui/icons-material";

function getTimeElapsed(date) {
  const now = new Date();
  const elapsed = now - date;

  // Calculate elapsed time in seconds, minutes, hours, days, and months
  const seconds = Math.floor(elapsed / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
  }
}

export default function ManageResults() {
  const [tab, setTab] = useState("all");
  const [results, setResults] = useState([]);
  const [resultMetrics, setResultMetrics] = useState({});
  const [filters, setFilters] = useState({});

  useEffect(() => {
    services.api.results
      .metrics()
      .then((data) => {
        setResultMetrics(data.data);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);

  useEffect(() => {
    services.api.results.find(filters, 1, 10).then((data) => {
      setResults(data.data.results);
    });
  }, [filters]);

  return (
    <MuiGrid item={true} xs={12}>
      <div className={"bg-[white] w-[100%]"}>
        <div className={"px-[10px] sm:px-[30px] bg-[#707cd2] w-[100%]"}>
          <MuiTypography
            className={"font-poppins text-[white] font-light py-[30px]"}
          >
            Manage Results
          </MuiTypography>
          <MuiTabs
            className={""}
            value={tab}
            classes={{ indicator: "bg-[#2cd07e] h-[4px] rounded-full" }}
            onChange={(event, value) => {
              setTab(value);
            }}
          >
            <MuiTab
              value={"all"}
              label={"All"}
              onClick={() => {
                setFilters({});
              }}
              className={"text-white font-poppins font-light"}
            />
            <MuiTab
              value={"not_approved"}
              label={"Not Approved"}
              onClick={() => {
                setFilters({ isApproved: false });
              }}
              className={"text-white font-poppins font-light"}
            />
          </MuiTabs>
        </div>
        <div className={"py-[30px] px-[10px] sm:px-[30px] w-[100%]"}>
          {results.map((result, index) => {
            return (
              <MuiGrid
                container={true}
                alignItems={"center"}
                className={"w-[100%] py-[8px]"}
              >
                <MuiGrid item={true} xs={1} className={"hidden md:block"}>
                  <MuiTypography>{index + 1}</MuiTypography>
                </MuiGrid>
                <MuiGrid
                  item={true}
                  xs={2}
                  md={1}
                  className={"px-[5px] overflow-x-hidden hidden sm:block"}
                >
                  <MuiAvatar
                    src={
                      result.student.image
                        ? result.student.image
                        : `https://eportal.parkbritishschool.com/api/uploads/images/profile/${result.student._id.replaceAll(
                            "/",
                            "-"
                          )}.jpg`
                    }
                  />
                </MuiGrid>
                <MuiGrid
                  item={true}
                  xs={6}
                  sm={4}
                  container={true}
                  className={"px-[5px]"}
                >
                  <MuiGrid item={true} xs={12} md={6}>
                    <MuiTypography
                      noWrap={true}
                      className={"font-dm-sans text-[14px] text-capitalize"}
                    >
                      {`${result.student.firstName} ${result.student.lastName}`}
                    </MuiTypography>
                  </MuiGrid>

                  <MuiGrid item={true} xs={12} md={6}>
                    <MuiTypography
                      noWrap={true}
                      className={"font-dm-sans text-[14px] text-capitalize"}
                    >
                      {result.class?.name}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>

                <MuiGrid
                  item={true}
                  xs={6}
                  sm={3}
                  md={2}
                  className={"flex justify-end sm:block"}
                >
                  {result.isApproved ? (
                    <MuiTypography
                      className={
                        "text-[white] text-[12px] bg-[green] w-max px-[10px] py-[3px] rounded-pill"
                      }
                    >
                      Approved
                    </MuiTypography>
                  ) : (
                    <MuiTypography
                      className={
                        "text-[white] text-[12px] bg-[red] w-max px-[10px] py-[3px] rounded-pill"
                      }
                    >
                      Not Approved
                    </MuiTypography>
                  )}
                </MuiGrid>
                <MuiGrid
                  item={true}
                  xs={3}
                  md={2}
                  className={"hidden sm:flex sm:justify-end md:block"}
                >
                  <MuiTypography
                    noWrap={true}
                    className={"font-dm-sans text-[14px] text-[grey]"}
                  >
                    {`Uploaded ${getTimeElapsed(new Date(result.uploadedAt))}`}
                  </MuiTypography>
                </MuiGrid>
                <MuiGrid item={true} xs={12} md={2}>
                  <div className={"w-[100%] flex justify-end"}>
                    {result.isApproved === false && (
                      <MuiIconButton className={"p-[5px]"}>
                        <MuiDoneOutlinedIcon className={"text-[green]"} />
                      </MuiIconButton>
                    )}
                    <MuiIconButton className={"p-[5px]"}>
                      <MuiVisibilityOutlinedIcon className={"text-[blue]"} />
                    </MuiIconButton>
                    <MuiIconButton className={"p-[5px]"}>
                      <MuiDeleteOutlinedIcon className={"text-[black]"} />
                    </MuiIconButton>
                  </div>
                </MuiGrid>
              </MuiGrid>
            );
          })}
        </div>
      </div>
    </MuiGrid>
  );
}
