import { useState, createContext, useEffect } from "react";

export const classContext = createContext();
export const ClassState = (props) => {
  const initialClassState = {
    name: "N/A",
    students: [],
    teachers: [],
    results: [],
  };
  const cache = JSON.parse(sessionStorage.getItem("classState"));
  console.log("class state cache", cache);
  if (cache === null) {
    sessionStorage.setItem("classState", JSON.stringify(initialClassState));
  }

  const [classState, setClassState] = useState(cache || initialClassState);
  useEffect(() => {
    sessionStorage.setItem("classState", JSON.stringify(classState));
  }, [classState]);

  useEffect(() => {
    console.log("CLASS STATE==>", classState);
  }, [classState]);
  return (
    <classContext.Provider value={{ classState, setClassState }}>
      {props.children}
    </classContext.Provider>
  );
};
