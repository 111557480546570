import { useEffect, useState } from "react";
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
} from "@material-ui/core";
import services from "../../../../../../../services";
export default function ClassMetrics() {
  const [classMetrics, setClassMetrics] = useState({
    totalNumberOfClasses: 0,
  });
  const [selectedMetric, setSelectedMetric] = useState("totalNumberOfClasses");
  useEffect(() => {
    services.api.classes
      .metrics()
      .then((data) => {
        setClassMetrics(data.data);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return (
    <MuiGrid item={true} xs={12} sm={6}>
      <MuiPaper
        elevation={0}
        square
        className={"p-[10px] sm:p-[20px] flex justify-between sm:block h-100"}
      >
        <MuiTypography
          noWrap={true}
          className={"font-poppins text-[25px] font-light"}
        >
          {"Classes"}
        </MuiTypography>
        <MuiTypography noWrap={true} className={"text-[25px] sm:text-[50px]"}>
          {classMetrics[selectedMetric]}
        </MuiTypography>
      </MuiPaper>
    </MuiGrid>
  );
}
