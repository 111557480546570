import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  List,
  ListItemText,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography as MuiTypography,
  ListItem as MuiListItem,
  Slide as MuiSlide,
  IconButton as MuiIconButton,
  Hidden as MuiHidden,
} from "@material-ui/core";

import { useScreenBreakpoint } from "../../../../hooks";
import { useComponents } from "../../hooks";

import services from "../../../../services";
import { ChevronLeftOutlined as MuiChevronLeftOutlinedIcon } from "@mui/icons-material";

const mainNavbarItems = [
  {
    id: 0,
    label: "Overview",
    handleClick: (history, screenBreakpoint, sidebarV1) => {
      history.replace("/admin/dashboard/overview");
    },
  },
  // {
  //   id: 70,
  //   label: "Messages",
  //   handleClick: (history, screenBreakpoint, sidebarV1) => {
  //     history.replace("/admin/dashboard/messages");
  //   },
  // },
  {
    id: 2,
    label: "Administrators",
    handleClick: (history, screenBreakpoint, sidebarV1) => {
      history.replace("/admin/dashboard/administrators");
    },
  },
  {
    id: 9,
    label: "Teachers",
    handleClick: (history, screenBreakpoint, sidebarV1) => {
      history.replace("/admin/dashboard/teachers");
    },
  },
  {
    id: 1,
    label: "Students",
    handleClick: (history, screenBreakpoint, sidebarV1) => {
      history.replace("/admin/dashboard/students");
    },
  },
  {
    id: 5,
    label: "Classes",
    handleClick: (history, screenBreakpoint, sidebarV1) => {
      history.replace("/admin/dashboard/classes");
    },
  },
  {
    id: 6,
    label: "Results",
    handleClick: (history, screenBreakpoint, sidebarV1) => {
      history.replace("/admin/dashboard/results");
    },
  },
];

export default function SidebarV1(props) {
  const history = useHistory();
  const { componentsState, sidebarV1 } = useComponents();
  const screenBreakpoint = useScreenBreakpoint();
  useEffect(() => {
    if (screenBreakpoint !== "sm" && screenBreakpoint !== "xs") {
      sidebarV1.open();
    } else {
      sidebarV1.close();
    }
  }, [screenBreakpoint]);
  return (
    <MuiSlide
      in={componentsState.sidebarV1.isOpen}
      direction={"right"}
      timeout={100}
      unmountOnExit={true}
    >
      <MuiGrid
        xs={12}
        sm={12}
        md={2}
        lg={2}
        xl={2}
        className={"h-[100vh] overflow-y-auto bg-[#000f25]"}
      >
        <MuiGrid className="h-[60px] py-[20px] px-[30px] bg-[black] flex items-center justify-between">
          <MuiTypography
            noWrap={true}
            className={"font-poppins text-[20px] font-light text-[white]"}
          >
            Park British School
          </MuiTypography>
          <MuiHidden mdUp={true}>
            <MuiIconButton
              onClick={() => {
                sidebarV1.close();
              }}
            >
              <MuiChevronLeftOutlinedIcon className={"text-[white]"} />
            </MuiIconButton>
          </MuiHidden>
        </MuiGrid>

        <List className="w-[100%] py-0 ">
          {mainNavbarItems.map((item, index) => {
            return (
              <MuiListItem
                className={`h-[60px] py-0 cursor-pointer pl-[30px] hover:bg-[black]`}
                key={item.id}
                onClick={() => item.handleClick(history)}
                selected={props.selectedIndex === index}
              >
                <ListItemText className={"h-[100%] "}>
                  <MuiTypography
                    className={`h-[100%] w-[100%] text-[15px] flex items-center font-poppins font-light text-[#abb4be] hover:text-[white]`}
                  >
                    {item.label}
                  </MuiTypography>
                </ListItemText>
              </MuiListItem>
            );
          })}
          <MuiDivider />
          <MuiListItem
            className="pl-[30px] cursor-pointer"
            onClick={() => {
              services.api.auth.signOut
                .administrator()
                .then((data) => {
                  alert(data.message);
                  history.replace("/admin/signin");
                })
                .catch((error) => {
                  alert(error.message);
                  history.replace("/admin/signin");
                });
            }}
          >
            <ListItemText>
              <MuiTypography className={"font-poppins font-light text-danger"}>
                {"Sign Out"}
              </MuiTypography>
            </ListItemText>
          </MuiListItem>
        </List>
      </MuiGrid>
    </MuiSlide>
  );
}
