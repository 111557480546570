import {
  Button as MuiButton,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Modal as MuiModal,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import { IconButton as MuiIconButton } from "@mui/material";
import { Edit as MuiEditIcon } from "@mui/icons-material";
import { useState, useEffect } from "react";
import services from "../../../../../../../services";

export default function Session() {
  const [mode, setMode] = useState("default");
  const [session, setSession] = useState(" ");
  useEffect(() => {
    services.api.variables
      .get("session")
      .then((data) => {
        setSession(data.data.variable.value);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return (
    <>
      <MuiGrid item={true} xs={12} sm={6} md={4} lg={3} xl={2}>
        <MuiPaper
          square
          elevation={0}
          className={"p-[10px] sm:p-[20px] flex justify-between sm:block h-100"}
        >
          <MuiTypography
            noWrap={true}
            className={"font-poppins text-[25px] font-light"}
          >
            {"Session"}
          </MuiTypography>
          <div className={"flex items-center"}>
            <MuiTypography
              noWrap={true}
              className={"flex-grow-1 text-[25px] sm:text-[50px]"}
            >
              {session}
            </MuiTypography>
            <MuiIconButton
              onClick={() => {
                setMode("set");
              }}
            >
              <MuiEditIcon className={"text-[18px] sm:text-[24px]"} />
            </MuiIconButton>
          </div>
        </MuiPaper>
      </MuiGrid>
      <CreateSessionModal
        open={mode === "set"}
        handleClose={() => {
          setMode("default");
        }}
      />
    </>
  );
}

function CreateSessionModal(props) {
  const [formState, setFormState] = useState({ session: "" });
  return (
    <MuiModal open={props.open} onClose={props.handleClose}>
      <MuiGrid
        container
        justifyContent={"center"}
        alignItems={"center"}
        className={"h-100"}
      >
        <MuiGrid
          item={true}
          className={"bg-white p-3"}
          xs={10}
          sm={8}
          md={5}
          lg={3}
        >
          <MuiDivider />
          <h3>SET CURRENT SESSION</h3>
          <MuiDivider className={"mb-3"} />
          <MuiGrid className={"d-flex"}>
            <MuiTextField
              size={"small"}
              value={formState.session}
              variant={"outlined"}
              placeholder={"YYYY/YYYY"}
              onChange={(event) => {
                if (formState.session.length < 9) {
                  if (formState.session.length === 3) {
                    event.target.value = event.target.value.toString() + "/";
                    setFormState({
                      ...formState,
                      session: `${formState.session}/`,
                    });
                  }
                  setFormState({ session: event.target.value });
                }
              }}
            />
          </MuiGrid>
          <MuiDivider className={"mt-3 mb-2"} />
          <MuiButton
            size={"small"}
            className={"bg-danger me-3 text-white"}
            onClick={props.handleClose}
          >
            Cancel
          </MuiButton>
          <MuiButton
            size={"small"}
            className={"bg-danger me-3 text-white"}
            onClick={() => {
              setFormState({ session: "" });
            }}
          >
            Clear
          </MuiButton>
          <MuiButton
            size={"small"}
            className={"bg-primary text-white"}
            onClick={() => {
              if (formState.session.length !== 9) {
                alert("Session must be in YYYY/YYYY format");
                return false;
              }
              services.api.variables
                .set("session", { value: formState.session })
                .then((data) => {
                  alert(data.message);
                })
                .catch((error) => {
                  alert(error.message);
                });
            }}
          >
            Proceed
          </MuiButton>
          <MuiDivider className={"mt-2"} />
        </MuiGrid>
      </MuiGrid>
    </MuiModal>
  );
}
