export const list1 = [
  {
    label: "Overview",
    path: "/student/dashboard/overview",
  },
  // {
  //   label: "Notifications",
  //   path: "/student/dashboard/notifications",
  // },
  {
    label: "Announcements",
    path: "/student/dashboard/announcements",
  },
  {
    label: "Results",
    path: "/student/dashboard/results",
  },
  {
    label: "Invoices",
    path: "/student/dashboard/invoices",
  },
  // {
  //   label: "Sign Out",
  //   path: "/signin",
  // },
];
