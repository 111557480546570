import React from "react";
import { Switch, Route } from "react-router-dom";
import { Create, Update } from "./pages";

export default function Result() {
  return (
    <Switch>
      <Route path={"/result/create"} component={Create} />
      <Route path={"/result/update"} component={Update} />
    </Switch>
  );
}
