import { Switch, Route } from "react-router-dom";

import { Dashboard, Create, Profile } from "./pages";

import state from "./state";

export default function Student() {
  return (
    <state.ComponentsState>
      <Switch>
        <Route path={"/student/dashboard"} component={Dashboard} />
        <Route path={"/student/create"} component={Create} />
        <Route path={"/student/profile"} component={Profile} />
      </Switch>
    </state.ComponentsState>
  );
}
