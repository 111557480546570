import { userContext } from "../state/userState";
import { useContext } from "react";

export default function useUserState(params) {
  const { userState, setUserState } = useContext(userContext);
  function getUser() {}
  return {
    userState,
    setUserState,
  };
}
