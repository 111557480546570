import { useHistory, useLocation } from "react-router-dom";
import {
  Grid as MuiGrid,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  Collapse as MuiCollapse,
  Zoom as MuiZoom,
} from "@material-ui/core";

import { list1 } from "./data";

import "./styles.scss";

export default function Drawer(props) {
  const history = useHistory();
  const location = useLocation();

  return (
    <MuiCollapse
      direction="bottom"
      in={props.drawerOpen}
      timeout={{ appear: 5000, enter: 1000, exit: 500 }}
      unmountOnExit
      mountOnEnter
      className={"w-100"}
    >
      <MuiGrid
        container={true}
        item={true}
        justify={"center"}
        className={`Student__Dashboard__Drawer bg-primary-color`}
      >
        <MuiGrid item={true} xs={12}>
          <MuiList className={"w-100"}>
            {list1.map((item, index) => {
              return (
                <MuiZoom
                  in={true}
                  timeout={500 + index * 100}
                  unmountOnExit
                  mountOnEnter
                  key={index}
                >
                  <MuiListItem
                    className={"px-3"}
                    selected={location.pathname === item.path}
                    role={"button"}
                    onClick={() => {
                      if (location.pathname === item.path) {
                        return false;
                      } else {
                        history.push(item.path);
                      }
                    }}
                  >
                    <MuiListItemText
                      primary={item.label}
                      className={"text-white"}
                    />
                  </MuiListItem>
                </MuiZoom>
              );
            })}
          </MuiList>
        </MuiGrid>
      </MuiGrid>
    </MuiCollapse>
  );
}
