import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  TextField as MuiTextField,
  Button as MuiButton,
  IconButton as MuiIconButton,
  Paper as MuiPaper,
  OutlinedInput as MuiOutlinedInput,
  Snackbar as MuiSnackbar,
  InputAdornment as MuiInputAdornment,
  CircularProgress as MuiCircularProgress,
  Hidden as MuiHidden,
  Avatar as MuiAvatar,
  Grow as MuiGrow,
} from "@material-ui/core";
import validator from "validator";

import {
  Visibility as MuiVisibilityIcon,
  VisibilityOff as MuiVisibilityOffIcon,
  CloseOutlined as MuiCloseOutlinedIcon,
} from "@material-ui/icons";

import services from "../../../../services";

import { useUserState } from "../../../../hooks";

import "./styles.css";

export default function SignIn() {
  const history = useHistory();
  const { userState, setUserState } = useUserState();
  const [formState, setFormState] = useState({
    email: "",
    password: "",
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    handleClose: function () {
      setSnackbarState({ ...snackbarState, open: false, message: "" });
    },
    handleOpen: function (message) {
      setSnackbarState({ ...snackbarState, open: true, message: message });
    },
  });
  const [submitProgress, setSubmitProgress] = useState(false);

  const [administrator, setAdministrator] = useState({});
  const [existingLastKnownAdministrator, setExistingLastKnownAdministrator] =
    useState(false);

  useEffect(() => {
    if (localStorage.getItem("administrator")) {
      services.api.administrators
        .findOne(localStorage.getItem("administrator"))
        .then((data) => {
          setExistingLastKnownAdministrator(true);
          setAdministrator(data.data.administrator);
          setFormState({ ...formState, email: data.data.administrator.email });
        })
        .catch((error) => {
          localStorage.clear();
          setExistingLastKnownAdministrator(false);
        });
    }
  }, []);
  return (
    <MuiGrid
      container={true}
      className={"position-absolute w-100 h-100 Signin"}
    >
      <MuiHidden only={"xs"}>
        <MuiGrid
          item={true}
          lg={8}
          md={7}
          sm={5}
          className={"h-100 position-relative"}
        >
          <div
            className={
              "div1 position-absolute top-0 bottom-0 start-0 end-0 bg-danger"
            }
          ></div>
          <div
            className={
              "p-4 position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center"
            }
          >
            <h1 className={"ff-font-family-4 text-white"}>PARK</h1>
            <h1 className={"ff-font-family-4 text-white"}>BRITISH</h1>
            <h1 className={"ff-font-family-4 text-white"}>SCHOOL</h1>
          </div>
        </MuiGrid>
      </MuiHidden>
      <MuiGrid
        container={true}
        item={true}
        lg={4}
        md={5}
        sm={7}
        className={"h-100"}
        justify={"center"}
        alignItems={"center"}
      >
        <MuiGrid item={true} xs={10}>
          <MuiPaper>
            <MuiGrid
              className={"py-4"}
              container={true}
              direction={"column"}
              alignContent={"center"}
            >
              <MuiTypography
                align={"center"}
                gutterBottom={true}
                variant={"h4"}
              >
                {existingLastKnownAdministrator === false
                  ? "Admins Only!"
                  : "Welcome back!"}
              </MuiTypography>
              <MuiGrid>
                {existingLastKnownAdministrator === false ? (
                  <MuiTextField
                    className={"my-2"}
                    fullWidth={true}
                    value={formState.email}
                    placeholder={"Email Address"}
                    type={"text"}
                    variant={"outlined"}
                    onChange={(event) => {
                      setFormState({ ...formState, email: event.target.value });
                    }}
                  />
                ) : (
                  <MuiGrow in={true} timeout={1500}>
                    <div>
                      <MuiPaper className={"p-[20px]"}>
                        <div className={"flex items-center"}>
                          <MuiAvatar
                            src={
                              administrator.image ||
                              `https://eportal.parkbritishschool.com/api/uploads/images/profile/${administrator._id}.jpg`
                            }
                          />
                          <div className={"mx-[20px]"}>
                            <MuiTypography
                              className={
                                "text-capitalize font-poppins font-light"
                              }
                            >
                              {`${administrator.firstName} ${administrator.lastName}`}
                            </MuiTypography>
                            <MuiTypography
                              className={"text-[grey] font-poppins"}
                            >
                              Administrator
                            </MuiTypography>
                          </div>
                          <MuiIconButton
                            onClick={() => {
                              setExistingLastKnownAdministrator(false);
                              sessionStorage.removeItem("access-token");
                              localStorage.removeItem("administrator");
                            }}
                          >
                            <MuiCloseOutlinedIcon
                              className={"text-[red] text-[15px]"}
                            />
                          </MuiIconButton>
                        </div>
                      </MuiPaper>
                    </div>
                  </MuiGrow>
                )}
              </MuiGrid>

              <MuiOutlinedInput
                className={"my-2"}
                placeholder={"Password"}
                value={formState.password}
                type={passwordVisibility ? "text" : "password"}
                onChange={(event) => {
                  setFormState({ ...formState, password: event.target.value });
                }}
                endAdornment={
                  <MuiInputAdornment position="end">
                    <MuiIconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setPasswordVisibility(!passwordVisibility);
                      }}
                    >
                      {passwordVisibility ? (
                        <MuiVisibilityOffIcon />
                      ) : (
                        <MuiVisibilityIcon />
                      )}
                    </MuiIconButton>
                  </MuiInputAdornment>
                }
              />

              <MuiButton
                className={"my-2"}
                color={"primary"}
                variant={"contained"}
                disabled={submitProgress}
                onClick={() => {
                  setSubmitProgress(true);
                  services.api.auth.signIn
                    .administrator({
                      emailAddress: formState.email,
                      password: formState.password,
                    })
                    .then((data) => {
                      snackbarState.handleOpen(
                        `Login Successful. Please wait...`
                      );
                      setUserState({ ...data });
                      localStorage.setItem(
                        "administrator",
                        data.data.administrator._id
                      );
                      sessionStorage.setItem(
                        "access-token",
                        data.data.accessToken
                      );
                      setTimeout(() => {
                        history.replace("/admin/dashboard/overview");
                      }, 1000);
                    })
                    .catch((error) => {
                      snackbarState.handleOpen(`${error.message}`);
                      setTimeout(() => {
                        setSubmitProgress(false);
                      }, 2000);
                    });
                }}
              >
                {submitProgress ? (
                  <MuiCircularProgress size={24} color="secondary" />
                ) : (
                  "sign in"
                )}
              </MuiButton>
            </MuiGrid>
          </MuiPaper>
        </MuiGrid>
      </MuiGrid>
      <MuiSnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarState.open}
        message={snackbarState.message}
        action={
          <>
            <MuiButton
              color="secondary"
              size="small"
              onClick={snackbarState.handleClose}
            >
              {"CLOSE"}
            </MuiButton>
          </>
        }
      />
    </MuiGrid>
  );
}
