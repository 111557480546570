import { classContext } from "../state/classState";
import { useContext } from "react";

export default function useClassState(params) {
  const { classState, setClassState } = useContext(classContext);

  return {
    classState,
    setClassState,
  };
}
