import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
  Divider as MuiDivider,
  List as MuiList,
  ListItem as MuiListItem,
  Button as MuiButton,
  Grow as MuiGrow,
} from "@material-ui/core";
import { Header, Drawer, Breadcrumbs } from "../../components";
import { useUserState, useGlobalState } from "../../../../../../hooks";
import img15 from "../../../../../../../assets/images/img15.png";
import img6 from "../../../../../../../assets/images/img6.png";
import {
  requestAnnouncements,
  requestClasses,
} from "../../../../../../utils/apiRequests";
import { _NWR4434R, _FJ4R34R } from "./script";

export default function Overview(props) {
  const history = useHistory();
  const { globalState, setGlobalState } = useGlobalState();
  const { userState } = useUserState();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [announcements, setAnnouncements] = useState(globalState.announcements);

  useEffect(() => {
    requestAnnouncements.findAllAnnouncements().then((data) => {
      setGlobalState((globalState) => {
        return {
          ...globalState,
          announcements: [...data],
        };
      });
    });
    requestClasses.findAllClasses().then((data) => {
      setGlobalState((globalState) => {
        return {
          ...globalState,
          classes: [...data],
        };
      });
    });
  }, []);

  return (
    <MuiGrid container={true} className={"w-100"}>
      <Header
        drawerOpen={openDrawer}
        setDrawerOpen={setOpenDrawer}
        title={"Overview"}
      />
      <div className={"w-100"}>
        <Drawer drawerOpen={openDrawer} setDrawerOpen={setOpenDrawer} />
        <MuiGrid container={true} justify={"flex-end"} className={"bg-light"}>
          <MuiGrid className={"w-100 p-3"}>
            <Breadcrumbs paths={[{ label: "Overview" }]} />
          </MuiGrid>
          <MuiGrid container={true} item={true}>
            <MuiGrow in={true} timeout={800} mountOnEnter unmountOnExit>
              <MuiGrid
                item={true}
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className={"p-3"}
              >
                <MuiPaper>
                  <MuiGrid className={"px-3"}>
                    <MuiTypography variant={"h6"} color={"primary"}>
                      {"Profile"}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiDivider />
                  <MuiGrid
                    container={true}
                    item={true}
                    xs={12}
                    className={"px-3 py-3"}
                  >
                    <MuiGrid
                      item={true}
                      xs={4}
                      className={"px-4 d-flex justify-content-center"}
                    >
                      <img
                        src={`${
                          process.env.REACT_APP_API_BASE_URL
                        }/uploads/images/profile/${userState._id.replaceAll(
                          "/",
                          "-"
                        )}.jpg`}
                        alt={"avatar"}
                        height={250}
                        width={250}
                        className={"rounded-circle"}
                        onError={(e) => {
                          if (e.target.src !== img15) {
                            e.target.onerror = null;
                            e.target.src = img15;
                          }
                        }}
                      />
                    </MuiGrid>
                    <MuiGrid item={true} xs={4}>
                      <MuiTypography variant={"body1"}>ID: </MuiTypography>
                      <MuiTypography variant={"body1"}>
                        First Name:
                      </MuiTypography>
                      <MuiTypography variant={"body1"}>
                        Last Name:
                      </MuiTypography>
                      <MuiTypography variant={"body1"}>Gender:</MuiTypography>
                      <MuiTypography variant={"body1"}>Classes:</MuiTypography>
                    </MuiGrid>
                    <MuiGrid item={true} xs={4}>
                      <MuiTypography noWrap={true} variant={"body1"}>
                        {userState._id}
                      </MuiTypography>
                      <MuiTypography variant={"body1"}>
                        {userState?.firstName
                          ? userState.firstName.toUpperCase()
                          : "N/A"}
                      </MuiTypography>
                      <MuiTypography variant={"body1"}>
                        {userState?.lastName
                          ? userState.lastName.toUpperCase()
                          : "N/A"}
                      </MuiTypography>
                      <MuiTypography variant={"body1"}>
                        {userState.gender}
                      </MuiTypography>

                      <MuiTypography variant={"body1"}>
                        {(function () {
                          return userState.classes
                            .map((_class) => _class.name)
                            .join(", ");
                        })()}
                      </MuiTypography>
                    </MuiGrid>
                  </MuiGrid>
                  <MuiButton
                    className={"w-100"}
                    onClick={() => {
                      history.push("/profile/view");
                    }}
                  >
                    {"VIEW PROFILE"}
                  </MuiButton>
                </MuiPaper>
              </MuiGrid>
            </MuiGrow>
            <MuiGrow in={true} timeout={1000} mountOnEnter unmountOnExit>
              <MuiGrid
                item={true}
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className={"p-3"}
              >
                <MuiPaper className={"h-100"}>
                  <MuiGrid className={"d-flex flex-column h-100"}>
                    <MuiGrid className={"pt-4 px-3"}>
                      <MuiTypography variant={"h5"} color={"primary"}>
                        {"Announcements"}
                      </MuiTypography>
                      <MuiDivider />
                    </MuiGrid>

                    <MuiGrid
                      item={true}
                      xs={12}
                      className={"py-4 px-3 flex-grow-1"}
                    >
                      <MuiList className={"p-0 m-0"}>
                        {globalState.announcements.length === 0 ? (
                          <MuiTypography
                            variant={"h5"}
                            className={"text-muted"}
                          >
                            {"No announcements to display"}
                          </MuiTypography>
                        ) : (
                          globalState.announcements?.map((item, index) => {
                            if (index < 3) {
                              return (
                                <MuiGrow in={true} timeout={500 + index * 500}>
                                  <MuiListItem
                                    key={index}
                                    className="d-block w-100 p-0"
                                  >
                                    <MuiTypography className={"w-100 fs-18-px"}>
                                      {item.title}
                                    </MuiTypography>
                                    <MuiTypography
                                      className={"w-100 fs-14-px text-muted"}
                                    >
                                      {item.message}
                                    </MuiTypography>
                                  </MuiListItem>
                                </MuiGrow>
                              );
                            }
                          })
                        )}
                      </MuiList>
                    </MuiGrid>
                    <MuiGrid className={"w-100"}>
                      <MuiButton
                        className={"w-100"}
                        onClick={() => {
                          history.push("/teacher/dashboard/Announcements");
                        }}
                      >
                        {"View All announcements"}
                      </MuiButton>
                    </MuiGrid>
                  </MuiGrid>
                </MuiPaper>
              </MuiGrid>
            </MuiGrow>
          </MuiGrid>
          <MuiGrid container={true} item={true}>
            {_NWR4434R.map((item, index) => {
              return (
                <MuiGrow key={index} in={true} timeout={700 + index * 200}>
                  <MuiGrid
                    item={true}
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    className={"p-3"}
                  >
                    <MuiPaper>
                      <MuiGrid className={"p-3"}>
                        <MuiTypography variant={"h4"} className={"w-100"}>
                          {item.label}
                        </MuiTypography>
                        <MuiTypography variant={"h3"}>
                          {item.count()}
                        </MuiTypography>
                      </MuiGrid>
                    </MuiPaper>
                  </MuiGrid>
                </MuiGrow>
              );
            })}
          </MuiGrid>
          <MuiGrid container={true} item={true}>
            {_FJ4R34R.map((item, index) => {
              return (
                <MuiGrow key={index} in={true} timeout={700 + index * 200}>
                  <MuiGrid
                    container={true}
                    item={true}
                    lg={2}
                    md={3}
                    sm={4}
                    xs={6}
                    className={"p-3"}
                  >
                    <MuiButton
                      className={"p-3 w-100"}
                      variant={"contained"}
                      onClick={() =>
                        item.handleClick(globalState, userState, history)
                      }
                    >
                      <div>
                        <img
                          src={item.image}
                          height={100}
                          width={100}
                          alt={"classrooom button icon"}
                        />
                        <MuiTypography className={"py-3"}>
                          {item.label}
                        </MuiTypography>
                      </div>
                    </MuiButton>
                  </MuiGrid>
                </MuiGrow>
              );
            })}

            {userState.classes.map((_class, index) => {
              return (
                <MuiGrow key={index} in={true} timeout={700 + index * 200}>
                  <MuiGrid
                    container={true}
                    item={true}
                    lg={2}
                    md={3}
                    sm={4}
                    xs={6}
                    className={"p-3"}
                  >
                    <MuiButton
                      className={"p-3 w-100"}
                      variant={"contained"}
                      onClick={() => {
                        history.push(
                          `/class/dashboard?ID=${_class._id}&accessMode=teacher`
                        );
                      }}
                    >
                      <div>
                        <img
                          src={img6}
                          height={100}
                          width={100}
                          alt={"classrooom button icon"}
                        />
                        <MuiTypography className={"py-3"}>
                          {`CLASS (${_class.name})`}
                        </MuiTypography>
                      </div>
                    </MuiButton>
                  </MuiGrid>
                </MuiGrow>
              );
            })}
          </MuiGrid>
        </MuiGrid>
      </div>
    </MuiGrid>
  );
}
