import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  Button as MuiButton,
  Divider as MuiDivider,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText as MuiFormHelperText,
  Snackbar as MuiSnackbar,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";

import { Header } from "../../components";

import { Save as MuiSaveIcon } from "@material-ui/icons";

import { useGlobalState } from "../../../../../../hooks";

import { sampleFormState } from "./sampleData";

export default function Update() {
  const params = useParams();
  const [formState, setFormState] = useState(sampleFormState);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const { globalState } = useGlobalState();

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
    handleClose: function () {
      setSnackbarState({
        ...snackbarState,
        open: false,
        message: "",
        severity: "",
      });
    },
    handleOpen: function (message, severity) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: message,
        severity: severity,
      });
    },
  });

  const [submitProgress, setSubmitProgress] = useState(false);

  useEffect(() => {}, []);
  return (
    <MuiGrid
      container={true}
      justify={"center"}
      className={"position-absolute w-100 h-100"}
    >
      <Header title={"Profile"} />
      <MuiGrid item={true} xs={10} sm={9} md={8} lg={7} xl={6}>
        <div className={"p-3"}>
          <form onSubmit={(e) => e.preventDefault()}>
            <MuiTypography variant={"h5"}>Please fill correctly</MuiTypography>
            <MuiDivider className={"mb-5"} />
            <MuiTypography className={"mt-3"}>First Name</MuiTypography>
            <MuiTextField
              name={"firstName"}
              fullWidth={true}
              size={"small"}
              type={"text"}
              variant={"outlined"}
              value={formState.firstName}
              onChange={(e) => {
                formState["firstName"] = e.target.value;
                setFormState({ ...formState });
              }}
            />
            <MuiTypography className={"mt-3"}>Last Name</MuiTypography>
            <MuiTextField
              name={"lastName"}
              fullWidth={true}
              size={"small"}
              type={"text"}
              variant={"outlined"}
              value={formState.lastName}
              onChange={(e) => {
                formState["lastName"] = e.target.value;
                setFormState({ ...formState });
              }}
            />
            <MuiTypography className={"mt-3"}>Email Address</MuiTypography>
            <MuiTextField
              name={"emailAddress"}
              fullWidth={true}
              size={"small"}
              type={"text"}
              variant={"outlined"}
              value={formState.emailAddress}
              onChange={(e) => {
                formState["emailAddress"] = e.target.value;
                setFormState({ ...formState });
              }}
            />
            <MuiTypography className={"mt-3"}>Phone Number</MuiTypography>
            <MuiTextField
              name={"phoneNumber"}
              fullWidth={true}
              size={"small"}
              type={"tel"}
              variant={"outlined"}
              value={formState.phoneNumber}
              onChange={(e) => {
                formState["phoneNumber"] = e.target.value;
                setFormState({ ...formState });
              }}
            />
            <MuiTypography className={"mt-3"}>Gender</MuiTypography>
            <MuiSelect
              size={"small"}
              fullWidth={true}
              variant={"outlined"}
              value={formState.gender}
              onChange={(e) => {
                formState["gender"] = e.target.value;
                setFormState({ ...formState });
              }}
            >
              <MuiMenuItem value={"male"}>Male</MuiMenuItem>
              <MuiMenuItem value={"female"}>Female</MuiMenuItem>
            </MuiSelect>
            <MuiTypography className={"mt-3"}>Home Address</MuiTypography>
            <MuiTextareaAutosize
              className={"w-100 form-control"}
              value={formState.address}
              rowsMin={4}
              onChange={(e) => {
                formState["address"] = e.target.value;
                setFormState({ ...formState });
              }}
            />
            <MuiTypography className={"mt-3"}>
              Upload new profile image
            </MuiTypography>
            <MuiTextField
              variant={"outlined"}
              type={"file"}
              multiple={false}
              onChange={(e) => {
                const reader = new FileReader();
                reader.readAsBinaryString(e.target.files[0]);
                reader.onload = function () {
                  formState["image"] = btoa(reader.result);
                  setFormState({ ...formState });
                };
              }}
            />
            <MuiFormHelperText>
              *image must be in portrait mode
            </MuiFormHelperText>

            <MuiDivider className={"my-4"} />

            <MuiButton
              className={"mb-3"}
              type={"submit"}
              color={"primary"}
              variant={"contained"}
              endIcon={<MuiSaveIcon />}
              onClick={async () => {
                setSubmitProgress(true);
              }}
            >
              {submitProgress ? (
                <MuiCircularProgress size={24} color="secondary" />
              ) : (
                "Update"
              )}
            </MuiButton>
          </form>
        </div>
      </MuiGrid>

      <MuiSnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarState.open}
        message={snackbarState.message}
        action={
          <>
            <MuiButton
              color="secondary"
              size="small"
              onClick={snackbarState.handleClose}
            >
              {"CLOSE"}
            </MuiButton>
          </>
        }
      />
    </MuiGrid>
  );
}
