import { useState, Fragment } from "react";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Typography as MuiTypography,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Grow as MuiGrow,
  Zoom as MuiZoom,
  TextField as MuiTextField,
  Collapse as MuiCollapse,
} from "@material-ui/core";

import { useUserState, useClassState } from "../../../../../../hooks";
import { requestClasses } from "../../../../../../utils/apiRequests";

export default function Subjects() {
  const { userState } = useUserState();
  const { classState, setClassState } = useClassState();
  const [addSubjectMode, setAddSubjectMode] = useState(false);
  const [formState, setFormState] = useState({ subject: "" });

  return (
    <MuiGrid
      container={true}
      justify={"center"}
      className={"Class_Teachers pt-4 "}
    >
      <MuiGrid item={true} xs={11}>
        <MuiGrid container={true}>
          <MuiGrid container={true}>
            <MuiGrid container={true} className={"MuiGrid1 w-100 p-2"}>
              <MuiGrid className={"flex-grow-1"}>
                <MuiTypography
                  variant={"h6"}
                  className={"MuiTypography1 h-100"}
                >
                  {"Subjects"}
                </MuiTypography>
              </MuiGrid>
              <MuiGrid item={true}>
                <MuiButton className={"bg-white mx-1"} onClick={() => {}}>
                  <MuiTypography
                    variant={"button"}
                    className={"mx-1"}
                    onClick={() => {
                      requestClasses
                        .findClassByID(classState._id)
                        .then((data) => {
                          setClassState((classState) => {
                            return {
                              ...classState,
                              ...data,
                            };
                          });
                        })
                        .catch((error) => {
                          alert(error);
                        });
                    }}
                  >
                    {"Refresh"}
                  </MuiTypography>
                </MuiButton>
                <MuiButton className={"bg-white mx-1"} onClick={() => {}}>
                  <MuiTypography
                    variant={"button"}
                    className={"mx-1"}
                    onClick={() => {
                      setAddSubjectMode(true);
                    }}
                  >
                    {"Add"}
                  </MuiTypography>
                </MuiButton>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container={true} className={"px-3 py-4"}>
            <MuiCollapse in={addSubjectMode} className={"w-100"}>
              <MuiTypography>{"Subject"}</MuiTypography>
              <MuiTextField
                size={"small"}
                variant={"outlined"}
                className={"w-100"}
                onChange={(event) => {
                  setFormState((formState) => {
                    return { ...formState, subject: event.target.value };
                  });
                }}
              />
              <MuiGrid className={"py-3"}>
                <MuiButton
                  size={"small"}
                  color={"primary"}
                  variant={"contained"}
                  onClick={() => {
                    requestClasses.subjects
                      .add(classState._id, formState.subject)
                      .then(() => {
                        requestClasses
                          .findClassByID(classState._id)
                          .then((data) => {
                            setClassState((classState) => {
                              return {
                                ...classState,
                                ...data,
                              };
                            });
                          })
                          .catch((error) => {
                            alert(error);
                          });
                        alert("Added Successfully!");
                      })
                      .catch((error) => {
                        alert(error);
                      });
                  }}
                >
                  {"Submit"}
                </MuiButton>
                <MuiButton
                  size={"small"}
                  color={"secondary"}
                  variant={"contained"}
                  className={"mx-2"}
                  onClick={() => {
                    setAddSubjectMode(false);
                  }}
                >
                  {"close"}
                </MuiButton>
              </MuiGrid>
            </MuiCollapse>
          </MuiGrid>
          <MuiTable className={" border"}>
            <MuiTableHead>
              <MuiTableRow>
                <MuiTableCell className={"bg-info border-0 fw-bold"}>
                  <MuiTypography>{"Name"}</MuiTypography>
                </MuiTableCell>
                {(classState.accessMode === "administrator" ||
                  classState.accessMode === "teacher") && (
                  <MuiTableCell className={"bg-info border-0 fw-bold"}>
                    Actions
                  </MuiTableCell>
                )}
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody>
              {classState.subjects.length === 0 ? (
                <MuiGrow in={true} timeout={200}>
                  <MuiTableRow>
                    <MuiTableCell colSpan={4}>
                      <MuiTypography
                        variant={"h1"}
                        className={"text-muted text-center"}
                      >
                        {"No subjects to show"}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                </MuiGrow>
              ) : (
                <Fragment>
                  {classState.subjects.map((subject, index) => {
                    return (
                      <MuiZoom
                        key={index}
                        in={true}
                        timeout={500 + index * 100}
                      >
                        <MuiTableRow>
                          <MuiTableCell>{subject}</MuiTableCell>
                          {(classState.accessMode === "administrator" ||
                            classState.accessMode === "teacher") && (
                            <MuiTableCell>
                              <MuiButton
                                size={"small"}
                                onClick={() => {
                                  requestClasses.subjects
                                    .remove(classState._id, subject)
                                    .then(() => {
                                      requestClasses
                                        .findClassByID(classState._id)
                                        .then((data) => {
                                          setClassState((classState) => {
                                            return {
                                              ...classState,
                                              ...data,
                                            };
                                          });
                                        })
                                        .catch((error) => {
                                          alert(error);
                                        });
                                      alert("Removed successfully!");
                                    })
                                    .catch((error) => {
                                      alert(error);
                                    });
                                }}
                              >
                                {"Remove"}
                              </MuiButton>
                            </MuiTableCell>
                          )}
                        </MuiTableRow>
                      </MuiZoom>
                    );
                  })}
                </Fragment>
              )}
            </MuiTableBody>
          </MuiTable>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}
