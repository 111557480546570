import alertContext from "./alertContext.js";
import administratorContext from "./administrator";
import componentsContext from "./components";
import directoriesContext from "./directories.js";

const contexts = {
  alertContext,
  administratorContext,
  componentsContext,
  directoriesContext,
};

export default contexts;
