import { Grid as MuiGrid } from "@material-ui/core";

import { Spreadsheet as RsSpreadsheet } from "react-spreadsheet";

export default function Spreadsheet({
  scoresheetData,
  setScoresheetData,
  type,
}) {
  console.log(scoresheetData);
  return (
    <MuiGrid className={"my-3"}>
      <MuiGrid container={true} wrap={"nowrap"} alignContent={"flex-start"}>
        <RsSpreadsheet
          data={scoresheetData[type].rows}
          onChange={(data) => {
            setScoresheetData([scoresheetData[type].columns, ...data]);
          }}
          columnLabels={scoresheetData[type].columns}
        />
      </MuiGrid>
    </MuiGrid>
  );
}
