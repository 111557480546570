import { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Typography as MuiTypography,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Grow as MuiGrow,
  Zoom as MuiZoom,
  Collapse as MuiCollapse,
  TextField as MuiTextField,
} from "@material-ui/core";

import { useUserState, useClassState } from "../../../../../../hooks";
import { requestClasses } from "../../../../../../utils/apiRequests";

import { F1 } from "./script";

import "./style.scss";

export default function Students() {
  const { userState } = useUserState();
  const history = useHistory();
  const { classState, setClassState } = useClassState();
  const [formState, setFormState] = useState({ studentID: "" });
  const [assignStudentMode, setAssignStudentMode] = useState(false);

  return (
    <MuiGrid container={true} className={"Class_Students pt-4 "}>
      <MuiGrid container={true} className={"px-3"}>
        <MuiGrid container={true}>
          <MuiGrid container={true} className={"MuiGrid1 w-100 p-2"}>
            <MuiGrid className={"flex-grow-1"}>
              <MuiTypography variant={"h6"} className={"MuiTypography1 h-100"}>
                {"Students"}
              </MuiTypography>
            </MuiGrid>
            <MuiGrid item={true}>
              <MuiButton
                className={"bg-white mx-1"}
                onClick={() => {
                  requestClasses
                    .findClassByID(classState._id)
                    .then((data) => {
                      setClassState((classState) => {
                        return {
                          ...classState,
                          ...data,
                        };
                      });
                    })
                    .catch((error) => {
                      alert(error);
                    });
                }}
              >
                <MuiTypography className={"mx-1 text-capitalize"}>
                  {"Refresh"}
                </MuiTypography>
              </MuiButton>
              <MuiButton className={"bg-white mx-1"}>
                <MuiTypography
                  className={"mx-1 text-capitalize"}
                  onClick={() => {
                    setAssignStudentMode(true);
                  }}
                >
                  {"Assign"}
                </MuiTypography>
              </MuiButton>
              <MuiButton
                className={"bg-white mx-1"}
                onClick={() => F1(requestClasses, classState, setClassState)}
              >
                <MuiTypography className={"mx-1 text-capitalize"}>
                  {"Remove all"}
                </MuiTypography>
              </MuiButton>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
        <MuiGrid container={true} className={"px-3 py-4"}>
          <MuiCollapse in={assignStudentMode} className={"w-100"}>
            <MuiTypography>{"Student ID"}</MuiTypography>
            <MuiTextField
              size={"small"}
              variant={"outlined"}
              className={"w-100"}
              onChange={(event) => {
                setFormState((formState) => {
                  return { ...formState, studentID: event.target.value };
                });
              }}
            />
            <MuiGrid className={"py-3"}>
              <MuiButton
                size={"small"}
                color={"primary"}
                variant={"contained"}
                onClick={() => {
                  requestClasses.students
                    .assign(classState._id, formState.studentID)
                    .then(() => {
                      requestClasses
                        .findClassByID(classState._id)
                        .then((data) => {
                          setClassState((classState) => {
                            return {
                              ...classState,
                              ...data,
                            };
                          });
                        })
                        .catch((error) => {
                          alert(error);
                        });
                      alert("Assigned Successfully!");
                    })
                    .catch((error) => {
                      alert(error);
                    });
                }}
              >
                {"Submit"}
              </MuiButton>
              <MuiButton
                size={"small"}
                color={"secondary"}
                variant={"contained"}
                className={"mx-2"}
                onClick={() => {
                  setAssignStudentMode(false);
                }}
              >
                {"close"}
              </MuiButton>
            </MuiGrid>
          </MuiCollapse>
        </MuiGrid>
        <MuiTable className={"border"}>
          <MuiTableHead>
            <MuiTableRow>
              <MuiTableCell className={"bg-info border-0 fw-bold"}>
                Student ID
              </MuiTableCell>
              <MuiTableCell className={"bg-info border-0 fw-bold"}>
                First Name
              </MuiTableCell>
              <MuiTableCell className={"bg-info border-0 fw-bold"}>
                Last Name
              </MuiTableCell>
              {(classState.accessMode === "administrator" ||
                classState.accessMode === "teacher") && (
                <MuiTableCell className={"bg-info border-0 fw-bold"}>
                  Actions
                </MuiTableCell>
              )}
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody>
            {classState.students.length === 0 ? (
              <MuiGrow in={true} timeout={1500}>
                <MuiTableRow>
                  <MuiTableCell colSpan={4}>
                    <MuiTypography
                      variant={"h1"}
                      className={"text-muted text-center"}
                    >
                      {"No students found"}
                    </MuiTypography>
                  </MuiTableCell>
                </MuiTableRow>
              </MuiGrow>
            ) : (
              <Fragment>
                {classState.students.map((student, index) => {
                  return (
                    <MuiZoom key={index} in={true} timeout={100 + index * 100}>
                      <MuiTableRow key={index}>
                        <MuiTableCell>{student._id}</MuiTableCell>
                        <MuiTableCell>{student.firstName}</MuiTableCell>
                        <MuiTableCell>{student.lastName}</MuiTableCell>
                        {(classState.accessMode === "administrator" ||
                          classState.accessMode === "teacher") && (
                          <MuiTableCell>
                            <MuiButton
                              size={"small"}
                              onClick={() => {
                                requestClasses.students
                                  .deassign(classState._id, student._id)
                                  .then(() => {
                                    requestClasses
                                      .findClassByID(classState._id)
                                      .then((data) => {
                                        setClassState((classState) => {
                                          return {
                                            ...classState,
                                            ...data,
                                          };
                                        });
                                      })
                                      .catch((error) => {
                                        alert(error);
                                      });
                                    alert("Operation successful");
                                  })
                                  .catch((error) => {
                                    alert(error);
                                  });
                              }}
                            >
                              {"DeAssign"}
                            </MuiButton>
                          </MuiTableCell>
                        )}
                      </MuiTableRow>
                    </MuiZoom>
                  );
                })}
              </Fragment>
            )}
          </MuiTableBody>
        </MuiTable>
      </MuiGrid>
    </MuiGrid>
  );
}
