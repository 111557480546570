import { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Button as MuiButton,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Zoom as MuiZoom,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

import { Header, Drawer } from "../../components";

import { useUserState } from "../../../../../../hooks";

export default function Invoices() {
  const history = useHistory();
  const { userState } = useUserState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState({});

  return (
    <MuiGrid container={true} className={"w-100"}>
      <Header
        drawerOpen={openDrawer}
        setDrawerOpen={setOpenDrawer}
        title={"Invoices"}
      />
      <div className={"w-100"}>
        <Drawer drawerOpen={openDrawer} setDrawerOpen={setOpenDrawer} />
        <MuiGrid container={true} justify={"flex-end"} className={"bg-light"}>
          <MuiGrid container={true} item={true}>
            <div className={"w-100 p-3"}>
              <Table>
                <TableHead>
                  <MuiTableRow>
                    <TableCell className={"bg-primary-color"}>
                      <MuiTypography variant={"h6"}>{"ID"}</MuiTypography>
                    </TableCell>
                    <TableCell className={"bg-primary-color"}>
                      <MuiTypography variant={"h6"}>{"Status"}</MuiTypography>
                    </TableCell>
                    <TableCell className={"bg-primary-color"}>
                      <MuiTypography variant={"h6"}>
                        {"Date of issue"}
                      </MuiTypography>
                    </TableCell>
                    <TableCell className={"bg-primary-color"}>
                      <MuiTypography variant={"h6"}>{""}</MuiTypography>
                    </TableCell>
                  </MuiTableRow>
                </TableHead>
                <TableBody>
                  {userState.invoices.length === 0 ? (
                    <MuiZoom in={true} timeout={800} unmountOnExit mountOnEnter>
                      <MuiTableRow>
                        <MuiTableCell colSpan={3}>
                          <MuiTypography
                            variant={"h4"}
                            align={"center"}
                            className={"text-muted"}
                          >
                            {"No invoices found"}
                          </MuiTypography>
                        </MuiTableCell>
                      </MuiTableRow>
                    </MuiZoom>
                  ) : (
                    <Fragment>
                      {userState.invoices.map((item, index) => {
                        return (
                          <MuiZoom
                            in={true}
                            timeout={800}
                            unmountOnExit
                            mountOnEnter
                          >
                            <MuiTableRow key={index}>
                              <TableCell>{item._id}</TableCell>
                              <TableCell> {item.status}</TableCell>
                              <TableCell>
                                {new Date(item.issuedAt).toLocaleDateString()}
                              </TableCell>
                              <TableCell>
                                <MuiButton
                                  color={"primary"}
                                  variant={"contained"}
                                  size={"small"}
                                  onClick={() => {
                                    history.push(`/invoices/${item._id}`);
                                  }}
                                >
                                  {"View Invoice"}
                                </MuiButton>
                              </TableCell>
                            </MuiTableRow>
                          </MuiZoom>
                        );
                      })}
                    </Fragment>
                  )}
                </TableBody>
              </Table>
            </div>
          </MuiGrid>
        </MuiGrid>
      </div>
    </MuiGrid>
  );
}
