import { Switch, Route } from "react-router-dom";

import { Root, Update } from "./pages";

export default function Administrator() {
  return (
    <Switch>
      <Route
        path={"/admin/dashboard/administrator/update"}
        component={Update}
      />
      <Route path={"/admin/dashboard/administrator"} component={Root} />
    </Switch>
  );
}
