import { useState } from "react";
import { useHistory } from "react-router-dom";
import ReactOtpInput from "react-otp-input";
import {
  Grid as MuiGrid,
  Container as MuiContainer,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import { useUserState } from "../../hooks";
import { enqueueSnackbar } from "notistack";

import services from "../../services";

export default function Authorize() {
  const { userState, setUserState } = useUserState();
  const [isLoading, setIsLoading] = useState([
    false, // when you hit the continue button to submit the pin
  ]);
  const [formData, setFormData] = useState({});
  const history = useHistory();
  const [submitProgress, setSubmitProgress] = useState(false);

  return (
    <MuiGrid
      container={true}
      direction={"column"}
      alignContent={"center"}
      justify={"center"}
      className={"!fixed !top-0 !bottom-0 !right-0 !left-0 !bg-gray-300"}
    >
      <MuiContainer
        className={"!min-w-[250px] !max-w-[350px]"}
        disableGutters={true}
      >
        <MuiPaper className={"!px-6 !py-3"}>
          <MuiGrid
            className={""}
            container={true}
            direction={"column"}
            alignContent={"center"}
          >
            <p className={"text-center text-lg text-gray-500"}>{"Authorize"}</p>
            <p className={"text-center text-gray-500 font-light"}>
              {"Please enter your PIN to authorize your account."}
            </p>
            <div className={"w-full flex justify-center mt-6"}>
              <ReactOtpInput
                value={formData.pin || ""}
                onChange={(value) => {
                  setFormData({ ...formData, pin: value });
                }}
                numInputs={4}
                renderSeparator={<span> </span>}
                renderInput={(props) => {
                  return (
                    <input
                      {...props}
                      type={"password"}
                      className={
                        "!w-9 !h-9 !m-1 border-blue-400 border rounded-md focus:border-blue-500 !outline-0"
                      }
                    />
                  );
                }}
              />
            </div>

            <button
              className={"!mt-6 !mb-3 !w-full bg-amber-900 py-2 rounded-md"}
              disabled={!formData.pin || formData.pin.length < 4}
              onClick={async () => {
                setIsLoading((isLoading) => {
                  isLoading[0] = true;
                  return [...isLoading];
                });
                const response =
                  await services.api.v2.students.single.authorize(
                    {
                      id: localStorage.getItem("student").replaceAll("/", "-"),
                    },
                    { pin: formData.pin }
                  );
                if (response.status === 200) {
                  history.push("/student/dashboard/overview");
                } else {
                  setIsLoading((isLoading) => {
                    isLoading[0] = false;
                    return [...isLoading];
                  });
                  enqueueSnackbar("Invalid pin");
                }
              }}
            >
              {isLoading[0] === true ? (
                <MuiCircularProgress size={22} className={"text-white"} />
              ) : (
                <p className={"text-white"}>{"Continue"}</p>
              )}
            </button>
          </MuiGrid>
        </MuiPaper>
      </MuiContainer>
    </MuiGrid>
  );
}
