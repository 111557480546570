import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Grid as MuiGrid,
  CircularProgress as MuiCircularProgress,
  Typography as MuiTypography,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Divider as MuiDivider,
} from "@material-ui/core";

import { requestInvoices } from "../../utils/apiRequests";

import img18 from "../../../assets/images/img18.jpg";

import "./styles.css";

export default function Invoice() {
  const params = useParams();
  const [invoice, setInvoice] = useState(null);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const type = new URLSearchParams(window.location.search).get("type");
    if (type === "default") {
      requestInvoices
        .findInvoiceByID(params.invoiceID)
        .then((data) => {
          setInvoice(data);
        })
        .catch((error) => {
          alert(error);
        });
    }
    if (type === "template") {
      requestInvoices.templates
        .findInvoiceByID(params.invoiceID)
        .then((data) => {
          setInvoice(data);
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, []);

  useEffect(() => {
    if (invoice) {
      let sum = 0;
      invoice.fees.forEach((item) => {
        sum += item.amount;
      });
      setTotal(sum);
    }
  }, [invoice]);
  return (
    <MuiGrid
      container={true}
      className={"Invoice position-absolute w-100 pt-4 mt-5"}
      justifyContent={"center"}
    >
      {invoice ? (
        <MuiGrid
          item={true}
          xs={12}
          sm={10}
          md={8}
          lg={6}
          xl={6}
          className={"py-3"}
        >
          <img className={"w-100"} src={img18} alt={"blitz"} />
          <MuiTypography className={"text-center"} variant={"h3"} noWrap>
            {"PARK BRITISH SCHOOL"}
          </MuiTypography>
          <MuiTypography className={"text-center my-3 fw-bold"}>
            {"86, Marine Road Apapa. Tel: 0803303855, 080333052965"}
          </MuiTypography>
          <MuiTypography className={"w-100 text-muted"} variant={"h4"}>
            {`Invoice ID: ${invoice._id}`}
          </MuiTypography>
          <MuiDivider className={"my-4"} />
          <MuiTable>
            <MuiTableBody>
              <MuiTableRow>
                <MuiTableCell className={"px-0"}>{"ITEM"}</MuiTableCell>
                <MuiTableCell align={"right"}>{"PRICE"}</MuiTableCell>
              </MuiTableRow>
              {invoice.fees.map((item, index) => {
                return (
                  <MuiTableRow key={index}>
                    <MuiTableCell className={"px-0"}>
                      <MuiTypography className={"w-100 fw-bold"}>
                        {item.title}
                      </MuiTypography>

                      <MuiTypography className={"text-muted"}>
                        {item.subTitle}
                      </MuiTypography>
                    </MuiTableCell>
                    <MuiTableCell align={"right"}>
                      <MuiTypography className={"fw-bold"}>
                        {item.amount.toLocaleString()}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                );
              })}
              <MuiTableRow>
                <MuiTableCell colSpan={2} align={"right"}>
                  <MuiTypography variant={"h4"}>
                    {total.toLocaleString()}
                  </MuiTypography>
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableBody>
          </MuiTable>
          {invoice.type === "TEMPLATE" ? (
            ""
          ) : (
            <MuiGrid className={"my-5"}>
              <MuiTypography>
                {`Issued To: ${invoice.issuedTo?.firstName || "n/a"} ${
                  invoice.issuedTo?.lastName || "n/a"
                }`}
              </MuiTypography>
            </MuiGrid>
          )}
          <MuiGrid className={"my-5"}>
            <MuiTypography className={"ff-primary-font-1 text-muted fw-bold"}>
              {"*Payments are to be made to one of the following banks:"}
            </MuiTypography>
            <ul>
              <li className={"ff-primary-font-1 text-muted"}>
                Guaranty Trust Bank PLC - Account Number 0010179199
              </li>
              <li className={"ff-primary-font-1 text-muted"}>
                Union Bank PLC - Account Number 0000112861
              </li>
              <li className={"ff-primary-font-1 text-muted"}>
                Zenith Bank PLC - Account Number 1010388858
              </li>
            </ul>
          </MuiGrid>
          <MuiGrid className={"my-5"}>
            <MuiTypography className={"ff-primary-font-1 text-muted fw-bold"}>
              {
                "*Kindly note that fees are non-transferrable and non-refundable"
              }
            </MuiTypography>
          </MuiGrid>
          <MuiGrid className={"my-5"}>
            <MuiTypography className={"ff-primary-font-1 text-muted fw-bold"}>
              {"*Full fees are to be paid before resumption"}
            </MuiTypography>
          </MuiGrid>
        </MuiGrid>
      ) : (
        <MuiCircularProgress />
      )}
    </MuiGrid>
  );
}
