import { Switch, Route } from "react-router-dom";

import { Create, Root } from "./pages";

export default function Administrators() {
  return (
    <Switch>
      <Route
        path={"/admin/dashboard/administrators/create"}
        component={Create}
      />
      <Route path={"/admin/dashboard/administrators"} component={Root} />
    </Switch>
  );
}
