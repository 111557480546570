import { useEffect, useState, Fragment } from "react";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Typography as MuiTypography,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Grow as MuiGrow,
  Zoom as MuiZoom,
} from "@material-ui/core";

import { requestAnnouncements } from "../../../../../../utils/apiRequests";
import { useGlobalState } from "../../../../../../hooks";

export default function Announcements() {
  const { globalState, setGlobalState } = useGlobalState();
  const [announcements, setAnnouncements] = useState(globalState.announcements);

  useEffect(() => {
    if (globalState.announcements.length === 0) {
      requestAnnouncements
        .findAllAnnouncements()
        .then((data) => {
          setGlobalState((globalState) => {
            return {
              ...globalState,
              announcements: [...data],
            };
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, []);

  useEffect(() => {
    setAnnouncements((announcements) => {
      return [...globalState.announcements];
    });
  }, [globalState]);

  return (
    <MuiGrid
      container={true}
      justifyContent={"center"}
      className={"Class_Announcements pt-4"}
    >
      <MuiGrid item={true} xs={11}>
        <MuiGrid container={true}>
          <MuiGrid container={true}>
            <MuiGrid container={true} className={"MuiGrid1 w-100 p-2"}>
              <MuiGrid className={"flex-grow-1"}>
                <MuiTypography
                  variant={"h6"}
                  className={"MuiTypography1 h-100"}
                >
                  {"Announcements"}
                </MuiTypography>
              </MuiGrid>
              <MuiGrid item={true}>
                <MuiButton className={"bg-white mx-1"} onClick={() => {}}>
                  <MuiTypography
                    variant={"button"}
                    className={""}
                    onClick={() => {
                      requestAnnouncements
                        .findAllAnnouncements()
                        .then((data) => {
                          setGlobalState((globalState) => {
                            return {
                              ...globalState,
                              announcements: [...data],
                            };
                          });
                        })
                        .catch((error) => {
                          alert(error);
                        });
                    }}
                  >
                    {"Refresh"}
                  </MuiTypography>
                </MuiButton>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
          <MuiTable className={" border"}>
            <MuiTableHead>
              <MuiTableRow>
                <MuiTableCell className={"bg-info border-0 fw-bold"}>
                  <MuiTypography>{"Announcements"}</MuiTypography>
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody>
              {announcements.length === 0 ? (
                <MuiGrow in={true} timeout={1500}>
                  <MuiTableRow>
                    <MuiTableCell colSpan={4}>
                      <MuiTypography
                        variant={"h1"}
                        className={"text-muted text-center"}
                      >
                        {"No announcements to show"}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                </MuiGrow>
              ) : (
                <Fragment>
                  {announcements.map((announcement, index) => {
                    return (
                      <MuiZoom
                        key={index}
                        in={true}
                        timeout={200 + index * 100}
                      >
                        <MuiTableRow key={index}>
                          <MuiTableCell>
                            <MuiGrid>
                              <MuiTypography
                                variant={"h6"}
                                className={"fw-bold"}
                              >
                                {announcement.title}
                              </MuiTypography>
                              <MuiTypography>
                                {announcement.message}
                              </MuiTypography>
                            </MuiGrid>
                          </MuiTableCell>
                        </MuiTableRow>
                      </MuiZoom>
                    );
                  })}
                </Fragment>
              )}
            </MuiTableBody>
          </MuiTable>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}
