import React from "react";
import { Switch, Route } from "react-router-dom";
import { SignIn, Dashboard, Create, PasswordReset } from "./pages";

import state from "./state";

export default function AdminPage() {
  return (
    <state.GlobalState>
      <Switch>
        <Route path={"/admin/dashboard"} component={Dashboard} />
        <Route path={"/admin/create"} component={Create} />
        <Route path={"/admin/signIn"} component={SignIn} />
        <Route path={"/admin/password/reset"} component={PasswordReset} />
      </Switch>
    </state.GlobalState>
  );
}
