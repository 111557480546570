import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  CircularProgress as MuiCircularProgress,
  Typography as MuiTypography,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Button as MuiButton,
} from "@material-ui/core";

import { Paper as MuiPaper, Avatar as MuiAvatar } from "@mui/material";

import { LayoutV1 } from "../../../../../../components";

import { Edit as MuiEditIcon } from "@material-ui/icons";

import img15 from "../../../../../../../../../assets/images/img15.png";
import { requestStudents } from "../../../../../../../../utils/apiRequests";

export default function Root() {
  const history = useHistory();
  const [profileData, setProfileData] = useState({});
  useEffect(() => {
    const ID = new URLSearchParams(window.location.search).get("ID");
    requestStudents
      .findStudentByID({ ID: ID })
      .then((data) => {
        setProfileData({ ...data });
      })
      .catch((error) => {
        alert(`Error: ${error}`);
      });
  }, []);

  return (
    <LayoutV1>
      <MuiGrid
        container={true}
        justifyContent={"center"}
        className={"mt-[30px]"}
      >
        <MuiGrid item={true} xs={9}>
          <MuiPaper className={"p-[30px]"}>
            <MuiGrid container={true} className={""}>
              <MuiGrid
                container={true}
                item={true}
                xl={12}
                lg={12}
                className={"px-3 my-4"}
              >
                <MuiGrid
                  container={true}
                  item={true}
                  xl={7}
                  lg={7}
                  md={5}
                  sm={8}
                  xs={11}
                >
                  <MuiAvatar
                    className={""}
                    src={`https://ilechuks73.com.ng/api/uploads/images/profile/${new URLSearchParams(
                      window.location.search
                    ).get("ID")}.jpg`}
                    style={{
                      minWidth: "200px",
                      maxWidth: "200px",
                      height: "200px",
                    }}
                  />
                </MuiGrid>

                <MuiGrid
                  container={true}
                  item={true}
                  xl={8}
                  lg={8}
                  md={5}
                  sm={8}
                  xs={11}
                  direction={"column"}
                >
                  <MuiTypography variant={"h3"} noWrap={true}>
                    {`${
                      profileData.firstName?.charAt(0).toUpperCase() +
                      profileData.firstName?.slice(1)
                    } ${
                      profileData.lastName?.charAt(0).toUpperCase() +
                      profileData.lastName?.slice(1)
                    }`}
                  </MuiTypography>
                  <MuiTypography variant={"h4"} className={"text-primary"}>
                    {`${profileData.role}`}
                  </MuiTypography>
                </MuiGrid>
              </MuiGrid>
              <MuiGrid
                container={true}
                item={true}
                xl={12}
                lg={12}
                className={"my-4"}
              >
                <MuiTable>
                  <MuiTableBody>
                    <MuiTableRow>
                      <MuiTableCell>Student ID</MuiTableCell>
                      <MuiTableCell>{profileData.id}</MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>First Name</MuiTableCell>
                      <MuiTableCell>{profileData.firstName}</MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>Last Name</MuiTableCell>
                      <MuiTableCell>{profileData.lastName}</MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>Gender</MuiTableCell>
                      <MuiTableCell>{profileData.gender}</MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>Class</MuiTableCell>
                      <MuiTableCell>
                        {profileData.class
                          ? profileData.populatedFields.class.name
                          : "Not Assigned"}
                      </MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>Email Address</MuiTableCell>
                      <MuiTableCell>{profileData.emailAddress}</MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>Phone Number</MuiTableCell>
                      <MuiTableCell>{profileData.phoneNumber}</MuiTableCell>
                    </MuiTableRow>
                    <MuiTableRow>
                      <MuiTableCell>Home Address</MuiTableCell>
                      <MuiTableCell>{profileData.homeAddress}</MuiTableCell>
                    </MuiTableRow>
                  </MuiTableBody>
                </MuiTable>
                <MuiGrid className={"my-5 w-100"}>
                  <MuiButton
                    variant={"contained"}
                    startIcon={<MuiEditIcon />}
                    onClick={() =>
                      history.push(
                        `/admin/dashboard/student/update?id=${new URLSearchParams(
                          window.location.search
                        ).get("ID")}`
                      )
                    }
                  >
                    {"Update Profile"}
                  </MuiButton>
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </MuiPaper>
        </MuiGrid>
      </MuiGrid>
    </LayoutV1>
  );
}
