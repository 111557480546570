import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Paper as MuiPaper,
  Typography as MuiTypography,
  MenuItem as MuiMenuItem,
  Divider as MuiDivider,
  TextField as MuiTextField,
  Select as MuiSelect,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText as MuiFormHelperText,
  CircularProgress as MuiCircularProgress,
  OutlinedInput as MuiOutlinedInput,
  InputAdornment as MuiInputAdornment,
  IconButton as MuiIconButton,
} from "@material-ui/core";
import { useGlobalState } from "../../../../../../hooks";

import {
  AddBox as MuiAddBoxIcon,
  Block as MuiBlockIcon,
  Cached as MuiCachedIcon,
  Preview as MuiPreviewIcon,
  Delete as MuiDeleteIcon,
} from "@mui/icons-material";

import { LayoutV1 } from "../../../../../../components";
import services from "../../../../../../../../services";
import {
  Avatar as MuiAvatar,
  Skeleton as MuiSkeleton,
  TablePagination as MuiTablePagination,
} from "@mui/material";
import {
  Save as MuiSaveIcon,
  Visibility as MuiVisibilityIcon,
  VisibilityOff as MuiVisibilityOffIcon,
} from "@material-ui/icons";

export default function Create() {
  const { globalState, setGlobalState } = useGlobalState();
  const history = useHistory();
  const [loadingStates, setLoadingStates] = useState([true]);
  const [formState, setFormState] = useState({
    gender: "male",
    maritalStatus: "single",
  });
  const [submitProgress, setSubmitProgress] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  return (
    <LayoutV1>
      <MuiGrid
        container={true}
        justifyContent={"center"}
        className={"mt-[30px]"}
      >
        <MuiGrid item={true} xs={10} sm={9} md={8} lg={7} xl={6}>
          <MuiPaper className={"p-[30px]"}>
            <div className={"p-3"}>
              <form onSubmit={(e) => e.preventDefault()}>
                <MuiTypography variant={"h5"}>
                  Please fill correctly
                </MuiTypography>
                <MuiDivider className={"mb-5"} />
                <MuiTypography className={"mt-3"}>First Name</MuiTypography>
                <MuiTextField
                  name={"firstName"}
                  placeholder={"First Name"}
                  fullWidth={true}
                  size={"small"}
                  type={"text"}
                  variant={"outlined"}
                  onChange={(e) => {
                    formState["firstName"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                />
                <MuiTypography className={"mt-3"}>Last Name</MuiTypography>
                <MuiTextField
                  name={"lastName"}
                  fullWidth={true}
                  size={"small"}
                  type={"text"}
                  variant={"outlined"}
                  placeholder={"Last Name ..."}
                  onChange={(e) => {
                    formState["lastName"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                />
                <MuiTypography className={"mt-3"}>Email Address</MuiTypography>
                <MuiTextField
                  name={"emailAddress"}
                  fullWidth={true}
                  placeholder={"Email address"}
                  size={"small"}
                  type={"text"}
                  variant={"outlined"}
                  onChange={(e) => {
                    formState["emailAddress"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                />
                <MuiTypography className={"mt-3"}>Phone Number</MuiTypography>
                <MuiTextField
                  name={"phoneNumber"}
                  fullWidth={true}
                  size={"small"}
                  type={"tel"}
                  variant={"outlined"}
                  placeholder={"Phone number ..."}
                  onChange={(e) => {
                    formState["phoneNumber"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                />
                <MuiTypography className={"mt-3"}>Gender</MuiTypography>
                <MuiSelect
                  size={"small"}
                  fullWidth={true}
                  variant={"outlined"}
                  value={"male"}
                  onChange={(e) => {
                    formState["gender"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                >
                  <MuiMenuItem value={"male"}>Male</MuiMenuItem>
                  <MuiMenuItem value={"female"}>Female</MuiMenuItem>
                </MuiSelect>
                <MuiTypography className={"mt-3"}>Marital Status</MuiTypography>
                <MuiSelect
                  size={"small"}
                  fullWidth={true}
                  variant={"outlined"}
                  value={"single"}
                  onChange={(e) => {
                    formState["maritalStatus"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                >
                  <MuiMenuItem value={"single"}>Single</MuiMenuItem>
                  <MuiMenuItem value={"married"}>Married</MuiMenuItem>
                  <MuiMenuItem value={"divorced"}>Divorced</MuiMenuItem>
                </MuiSelect>
                <MuiTypography className={"mt-3"}>Home Address</MuiTypography>
                <MuiTextareaAutosize
                  className={"w-100 form-control"}
                  minRows={4}
                  placeholder={"Home address ..."}
                  onChange={(e) => {
                    formState["address"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                />
                <MuiTypography className={"mt-3"}>
                  Upload new profile image
                </MuiTypography>
                <MuiTextField
                  variant={"outlined"}
                  type={"file"}
                  multiple={false}
                  onChange={(event) => {
                    const selectedFile = event.target.files[0];
                    if (selectedFile) {
                      const reader = new FileReader();

                      reader.onload = function (event) {
                        const base64Data = event.target.result.split(",")[1];
                        setFormState({
                          ...formState,
                          image: base64Data,
                        });
                      };
                      reader.readAsDataURL(selectedFile);
                    }
                  }}
                />
                <MuiFormHelperText>
                  *image must be in portrait mode
                </MuiFormHelperText>

                <MuiTypography className={"mt-3"}>Password</MuiTypography>
                <MuiOutlinedInput
                  className={"my-2"}
                  placeholder={"Password"}
                  fullWidth={true}
                  type={passwordVisibility ? "text" : "password"}
                  onChange={(e) => {
                    formState["password"] = e.target.value;
                    setFormState({ ...formState });
                  }}
                  endAdornment={
                    <MuiInputAdornment position="end">
                      <MuiIconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setPasswordVisibility(!passwordVisibility);
                        }}
                      >
                        {passwordVisibility ? (
                          <MuiVisibilityOffIcon />
                        ) : (
                          <MuiVisibilityIcon />
                        )}
                      </MuiIconButton>
                    </MuiInputAdornment>
                  }
                />

                <MuiDivider className={"my-4"} />

                <MuiButton
                  className={"mb-3"}
                  type={"submit"}
                  color={"primary"}
                  variant={"contained"}
                  disabled={submitProgress}
                  endIcon={<MuiSaveIcon />}
                  onClick={async () => {
                    setSubmitProgress(true);
                    services.api.administrators
                      .create(formState)
                      .then((data) => {
                        alert("success!");
                        setSubmitProgress(false);
                      })
                      .catch((error) => {
                        alert(error.message);
                        console.log(error);
                        setSubmitProgress(false);
                      });
                  }}
                >
                  {submitProgress ? (
                    <MuiCircularProgress size={24} color="secondary" />
                  ) : (
                    "Save"
                  )}
                </MuiButton>
              </form>
            </div>
          </MuiPaper>
        </MuiGrid>
      </MuiGrid>
    </LayoutV1>
  );
}
