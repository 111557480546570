import { useState, useEffect } from "react";

import contexts from "../contexts";

function AdministratorState(props) {
  const initialAdministratorState = {
    isSignedIn: false,
    accessToken: null,
    refreshToken: null,
    administrator: null,
  };

  const cache = JSON.parse(sessionStorage.getItem("wddddededed"));
  if (cache === null) {
    sessionStorage.setItem(
      "wddddededed",
      JSON.stringify(initialAdministratorState)
    );
  }

  const [administratorState, setAdministratorState] = useState(
    cache || initialAdministratorState
  );

  useEffect(() => {
    sessionStorage.setItem("wddddededed", JSON.stringify(administratorState));
  }, [administratorState]);

  useEffect(() => {
    console.log("Administrator STATE ===>", administratorState);
  }, [administratorState]);
  return (
    <contexts.administratorContext.Provider
      value={[administratorState, setAdministratorState]}
    >
      {props.children}
    </contexts.administratorContext.Provider>
  );
}

export default AdministratorState;
