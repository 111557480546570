import {useState, Fragment, useEffect} from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Button as MuiButton,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Grow as MuiGrow,
  TableContainer as MuiTableContainer,
  Paper as MuiPaper,
  Table as MuiTable,
  TableHead as MuiTableHead,
  Checkbox as MuiCheckbox,
  TableBody as MuiTableBody,
  TableFooter as MuiTableFooter,
} from "@material-ui/core";

import { Header, Drawer, Breadcrumbs } from "../../components";

import { useUserState } from "../../../../../../hooks";
import { LayoutV1 } from "../../../../components";
import {
  ResultFilter,
  ResultSearch,
} from "../../../../../Admin/pages/Dashboard/pages/Results/components";
import {
  Cached as MuiCachedIcon,
  DeleteForever as MuiDeleteForeverIcon,
  Preview as MuiPreviewIcon,
} from "@mui/icons-material";
import services from "../../../../../../services";
import {
  Skeleton as MuiSkeleton,
  TablePagination as MuiTablePagination,
} from "@mui/material";

function trffe(selectedResults, result) {
  const val = selectedResults.some(
    (selectedResult) => selectedResult["_id"] === result._id
  );

  console.log(val);
  return val;
}
export default function Results() {
  const history = useHistory();
  const { userState } = useUserState();
  const [selectedResults, setSelectedResults] = useState([]);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [mode, setMode] = useState("default");
  const [loadingStates, setLoadingStates] = useState([true]);
  const [filters, setFilters] = useState({});
  const [totalNumberOfResults, setTotalNumberOfResults] = useState(0);
  
  useEffect(() => {
    
      services.api.students.single.results
        .find(localStorage.getItem("student"), page, count)
        .then((data) => {
          setResults(data.data.results);
          setTotalNumberOfResults(data.data.totalCount);
          setLoadingStates((loadingStates) => {
            let newLoadingStates = [...loadingStates];
            newLoadingStates[0] = false;
            return [...newLoadingStates];
          });
        })
        .catch((error) => {
          alert(error.message);
        });
  
  }, [page, count]);
  
  return (
    <LayoutV1>
      {/*<MuiGrid container={true} justify={"flex-end"} className={"bg-[#ebeef2]"}>*/}
      {/*  <MuiGrid container={true} item={true}>*/}
      {/*    <div className={"w-100 p-3"}>*/}
      {/*      <Table>*/}
      {/*        <TableHead>*/}
      {/*          <MuiTableRow>*/}
      {/*            <TableCell className={"bg-primary-color"}>*/}
      {/*              <MuiTypography variant={"h6"}>ID</MuiTypography>*/}
      {/*            </TableCell>*/}
      {/*            <TableCell className={"bg-primary-color"}>*/}
      {/*              <MuiTypography variant={"h6"}>Session</MuiTypography>*/}
      {/*            </TableCell>*/}
      {/*            <TableCell className={"bg-primary-color"}>*/}
      {/*              <MuiTypography variant={"h6"}>Term</MuiTypography>*/}
      {/*            </TableCell>*/}
      {/*            <TableCell className={"bg-primary-color"}>*/}
      {/*              <MuiTypography>{"Actions"}</MuiTypography>*/}
      {/*            </TableCell>*/}
      {/*          </MuiTableRow>*/}
      {/*        </TableHead>*/}
      {/*        <TableBody>*/}
      {/*          {userState.results.length === 0 ? (*/}
      {/*            <MuiGrow in={true} timeout={1000} unmountOnExit mountOnEnter>*/}
      {/*              <MuiTableRow>*/}
      {/*                <MuiTableCell colSpan={3}>*/}
      {/*                  <MuiTypography*/}
      {/*                    variant={"h2"}*/}
      {/*                    align={"center"}*/}
      {/*                    className={"text-muted"}*/}
      {/*                  >*/}
      {/*                    {"No results found"}*/}
      {/*                  </MuiTypography>*/}
      {/*                </MuiTableCell>*/}
      {/*              </MuiTableRow>*/}
      {/*            </MuiGrow>*/}
      {/*          ) : (*/}
      {/*            <Fragment>*/}
      {/*              {userState.results.map((item, index) => {*/}
      {/*                return (*/}
      {/*                  <MuiTableRow key={index}>*/}
      {/*                    <TableCell>{item._id}</TableCell>*/}
      {/*                    <TableCell> {item.session}</TableCell>*/}
      {/*                    <TableCell>{item.term}</TableCell>*/}
      {/*                    {item.isApproved ? (*/}
      {/*                      <TableCell>*/}
      {/*                        <MuiButton*/}
      {/*                          color={"primary"}*/}
      {/*                          variant={"contained"}*/}
      {/*                          size={"small"}*/}
      {/*                          onClick={() => {*/}
      {/*                            history.push(`/results/${item._id}`);*/}
      {/*                          }}*/}
      {/*                        >*/}
      {/*                          Check Result*/}
      {/*                        </MuiButton>*/}
      {/*                      </TableCell>*/}
      {/*                    ) : (*/}
      {/*                      <TableCell>{"Awaiting Approval"}</TableCell>*/}
      {/*                    )}*/}
      {/*                  </MuiTableRow>*/}
      {/*                );*/}
      {/*              })}*/}
      {/*            </Fragment>*/}
      {/*          )}*/}
      {/*        </TableBody>*/}
      {/*      </Table>*/}
      {/*    </div>*/}
      {/*  </MuiGrid>*/}
      {/*</MuiGrid>*/}
      <MuiGrid container={true} item={true}>
        <MuiGrid className={"px-[10px] sm:px-[30px] mt-[30px] w-[100%]"}>
          <MuiPaper>
            <div
              className={
                "px-[10px] sm:px-[30px] py-[10px] sm:flex sm:items-center sm:justify-between"
              }
            >
              <div className={"xs:mb-[20px] md:mb-0"}>
                {selectedResults.length < 1 ? (
                  <>
                    {/*<ResultSearch setMode={setMode} setResults={setResults} />*/}
                  </>
                ) : (
                  <MuiTypography
                    className={"text-[18px] sm:text-[25px] font-poppins font-light"}
                  >
                    {`${selectedResults.length} selected`}
                  </MuiTypography>
                )}
              </div>
              
              
              <div className={"flex justify-end"}>
                {selectedResults.length === 1 && (
                  <MuiButton
                    size={"small"}
                    variant={"contained"}
                    className={
                      "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                    }
                    disabled={selectedResults[0].isApproved === true? false : true}
                    startIcon={<MuiPreviewIcon />}
                    onClick={() => {
                      history.push(`/results/${selectedResults[0]._id}`);
                    }}
                  >
                    {"View"}
                  </MuiButton>
                )}
                
                <MuiButton
                  size={"small"}
                  variant={"contained"}
                  className={
                    "rounded-sm font-poppins text-[14px] font-light text-capitalize"
                  }
                  startIcon={<MuiCachedIcon />}
                  onClick={() => {
                    setLoadingStates((loadingStates) => {
                      let newLoadingStates = [...loadingStates];
                      newLoadingStates[0] = true;
                      return [...newLoadingStates];
                    });
                    
                    services.api.results
                      .findAll(page, count)
                      .then((data) => {
                        // setResults(data.data.results);
                        setTotalNumberOfResults(data.data.totalCount);
                        setLoadingStates((loadingStates) => {
                          let newLoadingStates = [...loadingStates];
                          newLoadingStates[0] = false;
                          return [...newLoadingStates];
                        });
                      })
                      .catch((error) => {
                        alert(error.message);
                      });
                    setPage(1);
                  }}
                >
                  {"Reload"}
                </MuiButton>
              </div>
            </div>
            <MuiTableContainer>
              
              {/*<ResultFilter*/}
              {/*  setMode={setMode}*/}
              {/*  setFilters={setFilters}*/}
              {/*  setPage={setPage}*/}
              {/*/>*/}
              <MuiTable>
                <MuiTableHead>
                  <MuiTableRow>
                    <MuiTableCell>
                      <MuiCheckbox disabled={true} />
                    </MuiTableCell>
                    <MuiTableCell>Result ID</MuiTableCell>
                    <MuiTableCell>Class</MuiTableCell>
                    <MuiTableCell>Type</MuiTableCell>
                    <MuiTableCell>Session</MuiTableCell>
                    <MuiTableCell>Term</MuiTableCell>
                    <MuiTableCell>Status</MuiTableCell>
                  </MuiTableRow>
                </MuiTableHead>
                <MuiTableBody>
                  {results.map((item, index) => {
                    if (loadingStates[0] === true) {
                      return (
                        <MuiTableRow key={index}>
                          <MuiTableCell>
                            <MuiCheckbox checked={false} disabled={true} />
                          </MuiTableCell>
                          <MuiTableCell>
                            <MuiSkeleton />
                          </MuiTableCell>
                          <MuiTableCell>
                            <MuiSkeleton />
                          </MuiTableCell>
                          <MuiTableCell>
                            <MuiSkeleton />
                          </MuiTableCell>
                          <MuiTableCell>
                            <MuiSkeleton />
                          </MuiTableCell>
                          <MuiTableCell>
                            <MuiSkeleton />
                          </MuiTableCell>
                          <MuiTableCell>
                            <MuiSkeleton />
                          </MuiTableCell>
                          <MuiTableCell>
                            <MuiSkeleton />
                          </MuiTableCell>
                        </MuiTableRow>
                      );
                    } else {
                      return (
                        <MuiTableRow
                          onClick={()=>{
                            setSelectedResults((selectedResults)=>{
                              selectedResults[0] = item
                              return [...selectedResults]
                            })
                          }}
                          key={index} className={"cursor-pointer"}>
                          <MuiTableCell>
                            {trffe(selectedResults, item) === true ? (
                              <MuiCheckbox
                                checked={true}
                                onClick={(event) => {
                                  setSelectedResults((selectedResults) => {
                                    let state = [...selectedResults];
                                    state = state.filter((item2) => {
                                      return item._id !== item2._id;
                                    });
                                    return state;
                                  });
                                }}
                              />
                            ) : (
                              <MuiCheckbox
                                checked={false}
                                onClick={(event) => {
                                  setSelectedResults([...selectedResults, item]);
                                }}
                              />
                            )}
                          </MuiTableCell>
                          <MuiTableCell>{item?._id?.toUpperCase()}</MuiTableCell>
                          <MuiTableCell>
                            <MuiTypography
                              noWrap={true}
                              className={"text-[14px]"}
                            >
                              {item?.class?.name}
                            </MuiTypography>
                          </MuiTableCell>
                          {item.type === "midTerm" ? (
                            <MuiTableCell>
                              <MuiTypography
                                noWrap={true}
                                className={"text-[14px]"}
                              >
                                Mid Term
                              </MuiTypography>
                            </MuiTableCell>
                          ) : (
                            <MuiTableCell className={""}>
                              <MuiTypography
                                noWrap={true}
                                className={"text-[14px]"}
                              >
                                End of Term
                              </MuiTypography>
                            </MuiTableCell>
                          )}
                          <MuiTableCell>{item.session}</MuiTableCell>
                          <MuiTableCell>{item.term}</MuiTableCell>
                          {item.isApproved ? (
                            <MuiTableCell className={"text-[green]"}>
                              Approved
                            </MuiTableCell>
                          ) : (
                            <MuiTableCell className={"text-[red]"}>
                              Not Approved
                            </MuiTableCell>
                          )}
                        </MuiTableRow>
                      );
                    }
                  })}
                </MuiTableBody>
                <MuiTableFooter>
                  <MuiTableRow>
                    {mode === "default" || mode === "filter" ? (
                      <MuiTablePagination
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        colSpan={8}
                        count={totalNumberOfResults}
                        rowsPerPage={count}
                        page={page - 1}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                          native: true,
                        }}
                        onPageChange={(event, page) => {
                          if (loadingStates[0] === true) {
                            return false;
                          }
                          setPage(page + 1);
                        }}
                        onRowsPerPageChange={(event) => {
                          setPage(1);
                          setCount(parseInt(event.target.value));
                        }}
                        showFirstButton={true}
                        showLastButton={true}
                      />
                    ) : (
                      ""
                    )}
                  </MuiTableRow>
                </MuiTableFooter>
              </MuiTable>
            </MuiTableContainer>
          </MuiPaper>
         
        </MuiGrid>
      </MuiGrid>
    </LayoutV1>
  );
}
