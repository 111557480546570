import { Switch, Route } from "react-router-dom";
import { Dashboard, Create } from "./pages";
import SignIn from "./pages/sign-in";

export default function Teacher() {
  return (
    <Switch>
      <Route path={"/teacher/sign-in"} component={SignIn} />
      <Route path={"/teacher/dashboard"} component={Dashboard} />
      <Route path={"/teacher/create"} component={Create} />
    </Switch>
  );
}
