import { useEffect, useState } from "react";
import {
  Avatar as MuiAvatar,
  Typography as MuiTypography,
  Divider as MuiDivider,
} from "@mui/material";

import services from "../../../../../../../services";

export default function ProfileOverview() {
  const [student, setStudent] = useState({});
  useEffect(() => {
    services.api.students
      .findOne(localStorage.getItem("student"))
      .then((data) => {
        setStudent(data.data.student);
      })
      .catch((error) => {
        alert(`Error: ${error.message}`);
      });
  }, []);
  return (
    <div className={"w-[100%] bg-[white]"}>
      <div className={"py-[40px] flex flex-column items-center"}>
        <MuiAvatar
          src={
            student.image ||
            `https://eportal.parkbritishschool.com/api/uploads/images/profile/${student._id?.replaceAll(
              "/",
              "-"
            )}.jpg`
          }
          className={"w-[150px] h-[150px] my-[5px]"}
        />
        <MuiTypography
          className={
            "my-[5px] font-poppins font-light text-[30px] text-capitalize"
          }
        >
          {`${student.firstName} ${student.lastName}`}
        </MuiTypography>
        <MuiTypography
          className={"my-[5px] font-poppins font-light text-[20px]"}
        >
          {"Student"}
        </MuiTypography>
      </div>
      <MuiDivider className={"bg-[black]"} />
      <div className={"w-[100%] py-[40px] px-[30px]"}>
        <div className={"mb-[5px]"}>
          <MuiTypography className={"font-poppins font-light text-[13px]"}>
            {"ID"}
          </MuiTypography>
          <MuiTypography className={"font-poppins font-light text-[16px]"}>
            {student._id || "N/A"}
          </MuiTypography>
        </div>
        <div className={"mb-[5px]"}>
          <MuiTypography className={"font-poppins font-light text-[13px]"}>
            {"Email address"}
          </MuiTypography>
          <MuiTypography className={"font-poppins font-light text-[16px]"}>
            {student.emailAddress || "N/A"}
          </MuiTypography>
        </div>
        <div className={"my-[5px]"}>
          <MuiTypography className={"font-poppins font-light text-[13px]"}>
            {"Phone number"}
          </MuiTypography>
          <MuiTypography className={"font-poppins font-light text-[16px]"}>
            {student.phoneNumber || "N/A"}
          </MuiTypography>
        </div>
        <div className={"mt-[5px]"}>
          <MuiTypography className={"font-poppins font-light text-[13px]"}>
            {"Home address"}
          </MuiTypography>
          <MuiTypography className={"font-poppins font-light text-[16px]"}>
            {student.homeAddress || "N/A"}
          </MuiTypography>
        </div>
      </div>
    </div>
  );
}
