import { Switch, Route } from "react-router-dom";

import { Root, Update } from "./pages";

export default function Profile() {
  return (
    <Switch>
      <Route path={"/student/profile/"} component={Root} />
      <Route path={"/student/profile/update"} component={Update} />
    </Switch>
  );
}
