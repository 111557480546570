import { useState, createContext, useEffect } from "react";

export const userContext = createContext();

export const UserState = (props) => {
  const initialUserState = {
    notifications: [],
    results: [],
    invoices: [],
  };
  const cache = JSON.parse(sessionStorage.getItem("userState"));
  if (cache === null) {
    sessionStorage.setItem("userState", JSON.stringify(initialUserState));
  }

  const [userState, setUserState] = useState(cache || initialUserState);
  useEffect(() => {
    sessionStorage.setItem("userState", JSON.stringify(userState));
  }, [userState]);

  useEffect(() => {
    console.log("USER STATE ===>", userState);
  }, [userState]);

  return (
    <userContext.Provider value={{ userState, setUserState }}>
      {props.children}
    </userContext.Provider>
  );
};
