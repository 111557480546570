import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import styles from "./styles.module.css";
import {
  Button as MuiButton,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";

import {
  Edit as MuiEditIcon,
  Done as MuiDoneIcon,
  CloudDownload as MuiCloudDownloadIcon,
} from "@material-ui/icons";

import { Header } from "./components";
import { useUserState } from "../../hooks";
import {
  requestResult,
  requestResult__downloadResult,
  requestResults,
} from "../../utils/apiRequests";
import logo1 from "../../../images/logo1.png";

import services from "../../services";

export default function ResultPage() {
  const history = useHistory();
  const { userState } = useUserState();
  const [pageReadyState, setPageReadyState] = useState([false]);
  const params = useParams();
  console.log(params);
  const [result, setResult] = useState({});
  useEffect(() => {
    requestResult(params.resultID).then((data) => {
      if (data.error) {
        alert(data.error.message);
      } else {
        setResult({ ...result, ...data });
        pageReadyState[0] = true;
        setPageReadyState([...pageReadyState]);
      }
    });
  }, []);
  return (
    <MuiGrid
      container={true}
      className={"position-absolute w-100 pt-4 mt-5"}
      justify={"center"}
    >
      <Header />
      {pageReadyState[0] === false ? (
        <MuiCircularProgress />
      ) : (
        <MuiGrid
          item={true}
          xs={12}
          sm={10}
          md={8}
          lg={6}
          xl={6}
          className={"py-3"}
          style={{
            borderRadius: "20px",
            border: "2px solid black",
          }}
        >
          <MuiGrid
            container={true}
            justify={"space-around"}
            alignItems={"center"}
            wrap={"nowrap"}
          >
            <MuiGrid>
              <img
                src={logo1}
                alt={"school logo"}
                className={`${styles.img1}`}
              />
            </MuiGrid>
            <MuiGrid>
              <MuiTypography
                className={"fw-bold"}
                align={"center"}
                variant={"h5"}
              >
                {result.school.toUpperCase()}
              </MuiTypography>
              <MuiTypography align={"center"}>
                {result.title.toUpperCase()}
              </MuiTypography>
            </MuiGrid>
            <MuiGrid>
              <img
                src={logo1}
                alt={"school logo"}
                className={`${styles.img1}`}
              />
            </MuiGrid>
          </MuiGrid>
          <MuiTable>
            <MuiTableBody>
              <MuiTableRow>
                <MuiTableCell className={"py-0"}>
                  <MuiTypography className={"fw-bold"}>
                    {`${result.class.name}`.toUpperCase()}
                  </MuiTypography>
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableBody>
          </MuiTable>
          <MuiTable size={"small"}>
            <MuiTableBody>
              <MuiTableRow
                className={
                  "border-3 border-start-0 border-end-0 border-bottom-0"
                }
              >
                <MuiTableCell className={"border-0"}>
                  Candidate Name
                </MuiTableCell>
                <MuiTableCell className={"border-0 text-center"}>
                  Session
                </MuiTableCell>
                <MuiTableCell className={"border-0 text-center"}>
                  Overall Percentage
                </MuiTableCell>
                <MuiTableCell className={"border-0 text-center"}>
                  Grade
                </MuiTableCell>
              </MuiTableRow>
              <MuiTableRow
                className={"border-3 border-start-0 border-end-0 border-top-0"}
              >
                <MuiTableCell
                  className={"fw-bold"}
                >{`${result.student.firstName.toUpperCase()} ${result.student.lastName.toUpperCase()}`}</MuiTableCell>
                <MuiTableCell className={"fw-bold text-center"}>
                  {result.session}
                </MuiTableCell>
                <MuiTableCell className={"fw-bold text-center"}>
                  {result.overallPercentage}
                </MuiTableCell>
                <MuiTableCell className={"fw-bold text-center"}>
                  {result.overallGrade}
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableBody>
          </MuiTable>
          <MuiTable size={"small"}>
            <MuiTableBody>
              {JSON.parse(result.scoreSheet).map((item, index) => {
                if (index === 0) {
                  return (
                    <MuiTableRow key={index}>
                      {result.type === "midTerm"
                        ? ["syllabus title", "Score", "Grade"].map(
                            (item, index) => {
                              return (
                                <MuiTableCell key={index} className={"fw-bold"}>
                                  {item.toUpperCase()}
                                </MuiTableCell>
                              );
                            }
                          )
                        : [
                            "syllabus title",
                            "Test",
                            "Exam",
                            "Average Percentage",
                            "Grade",
                          ].map((item, index) => {
                            return (
                              <MuiTableCell key={index} className={"fw-bold"}>
                                {item.toUpperCase()}
                              </MuiTableCell>
                            );
                          })}
                    </MuiTableRow>
                  );
                } else {
                  return (
                    <MuiTableRow key={index}>
                      {item.map((item, index) => {
                        return (
                          <MuiTableCell className={"border-0 py-1"} key={index}>
                            {item.value}
                          </MuiTableCell>
                        );
                      })}
                    </MuiTableRow>
                  );
                }
              })}
            </MuiTableBody>
          </MuiTable>
          <MuiGrid
            className={
              "px-3 py-3 border-3 border-start-0 border-end-0 border-dark"
            }
          >
            <MuiTypography variant={"h6 fw-bold"}>{"Electives"}</MuiTypography>
            <MuiTable>
              <MuiTableBody>
                {result?.electives?.length === 0 ? (
                  <MuiTableRow>
                    <MuiTableCell className={"px-0"}>
                      <MuiTypography className={"text-muted"}>
                        {"No electives"}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                ) : (
                  result.electives.map((elective, index) => {
                    return (
                      <MuiTableRow>
                        <MuiTableCell>{elective.title}</MuiTableCell>
                        <MuiTableCell>{elective.grade}</MuiTableCell>
                      </MuiTableRow>
                    );
                  })
                )}
              </MuiTableBody>
            </MuiTable>
          </MuiGrid>

          <MuiTable>
            <MuiTableBody>
              <MuiTableRow>
                <MuiTableCell className={"border-1 py-1"}>
                  Class Teacher's Remark
                </MuiTableCell>
                <MuiTableCell className={"border-1 py-1"}>
                  {result.teachersRemark.split("\n").map((p, index) => {
                    return <MuiTypography>{p}</MuiTypography>;
                  })}
                </MuiTableCell>
              </MuiTableRow>
              <MuiTableRow>
                <MuiTableCell className={"border-1 py-1"}>
                  Extra curricular activities
                </MuiTableCell>
                <MuiTableCell className={"border-1 py-1"}>
                  {result.comments.split("\n").map((p, index) => {
                    return <MuiTypography>{p}</MuiTypography>;
                  })}
                </MuiTableCell>
              </MuiTableRow>
              <MuiTableRow>
                <MuiTableCell className={"border-1 py-1"}>
                  School resumes
                </MuiTableCell>
                <MuiTableCell className={"border-1 py-1"}>
                  {result.resumptionDate}
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableBody>
          </MuiTable>
          <MuiGrid className={`my-3 w-75 m-auto rounded-3 ${styles.MuiGrid1}`}>
            <MuiTable className={" border-dark "}>
              <MuiTableBody>
                <MuiTableRow>
                  <MuiTableCell className={"border-0 py-1"}>
                    Principal's Remark:
                  </MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell className={"border-0 py-1"}>
                    {result.principalsRemark.split("\n").map((p, index) => {
                      return <MuiTypography>{p}</MuiTypography>;
                    })}
                  </MuiTableCell>
                </MuiTableRow>
                {result.isApproved ? (
                  <MuiTableRow>
                    <MuiTableCell align={"right"} className={"border-0"}>
                      <MuiTypography className={"fw-bold"}>
                        {"AMA"}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                ) : (
                  ""
                )}
              </MuiTableBody>
            </MuiTable>
          </MuiGrid>
        </MuiGrid>
      )}
      <MuiGrid
        container={true}
        className={"mb-5 mt-3 d-flex justify-content-center"}
      >
        <MuiButton
          className={"mx-2"}
          variant={"contained"}
          size={"small"}
          startIcon={<MuiCloudDownloadIcon />}
          onClick={() => {
            requestResult__downloadResult(result._id.replaceAll("/", "-"));
          }}
        >
          {"Download"}
        </MuiButton>
        {localStorage.getItem("administrator") ||
        localStorage.getItem("teacher") ? (
          <MuiButton
            variant={"contained"}
            size={"small"}
            startIcon={<MuiEditIcon />}
            className={"mx-2"}
            onClick={() => history.push(`/result/update?id=${params.resultID}`)}
          >
            {"Update"}
          </MuiButton>
        ) : (
          ""
        )}
        {localStorage.getItem("administrator") && !result.isApproved ? (
          <MuiButton
            variant={"contained"}
            size={"small"}
            startIcon={<MuiDoneIcon />}
            className={"mx-2 bg-success text-white"}
            disabled={result.isApproved ? true : false}
            onClick={() => {
              if (
                window.confirm(
                  "This result will be approved.\n\nThis action is not reversible!"
                )
              ) {
                requestResults.result
                  .approve(result._id)
                  .then(() => {
                    alert("Result successfully approved, Please wait...");
                    requestResult(params.resultID).then((data) => {
                      if (data.error) {
                        alert(data.error.message);
                      } else {
                        setResult({ ...result, ...data });
                        pageReadyState[0] = true;
                        setPageReadyState([...pageReadyState]);
                      }
                    });
                  })
                  .catch((error) => {
                    alert(`Error: ${error}`);
                  });
              }
            }}
          >
            {"approve"}
          </MuiButton>
        ) : (
          ""
        )}
      </MuiGrid>
    </MuiGrid>
  );
}
