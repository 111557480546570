import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Paper as MuiPaper,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  Button as MuiButton,
  Divider as MuiDivider,
  TextareaAutosize as MuiTextareaAutosize,
  FormHelperText as MuiFormHelperText,
  Snackbar as MuiSnackbar,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import { Header } from "./components";

import { Save as MuiSaveIcon } from "@material-ui/icons";

import { useGlobalState } from "../../hooks";

import { requestTeacher, requestEditTeacher } from "../../utils/apiRequests";

export default function EditTeacher() {
  const params = useParams();
  const [formState, setFormState] = useState(null);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const { globalState, getClasses } = useGlobalState();

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
    handleClose: function () {
      setSnackbarState({
        ...snackbarState,
        open: false,
        message: "",
        severity: "",
      });
    },
    handleOpen: function (message, severity) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: message,
        severity: severity,
      });
    },
  });

  const [submitProgress, setSubmitProgress] = useState(false);

  useEffect(() => {
    getClasses();
    requestTeacher(params.teacherID).then((data) => {
      setFormState({
        firstName: data.firstName,
        lastName: data.lastName,
        gender: data.gender,
        address: data.address,
        email: data.email,
        maritalStatus: data.maritalStatus,
      });
    });
  }, []);
  return (
    <MuiGrid
      container={true}
      justify={"center"}
      className={"position-absolute w-100 h-100 py-5 my-5"}
    >
      <Header title={"Edit Teacher Profile"} />
      <MuiGrid item={true} xs={10} sm={9} md={8} lg={7} xl={6}>
        {formState === null ? (
          <MuiCircularProgress />
        ) : (
          <MuiPaper className={"p-3"}>
            <form onSubmit={(e) => e.preventDefault()}>
              <MuiTypography variant={"h5"}>
                Please fill correctly
              </MuiTypography>
              <MuiDivider className={"mb-5"} />
              <MuiTypography className={"mt-3"}>First Name</MuiTypography>
              <MuiTextField
                name={"firstName"}
                fullWidth={true}
                size={"small"}
                type={"text"}
                variant={"outlined"}
                value={formState.firstName}
                onChange={(e) => {
                  formState["firstName"] = e.target.value;
                  setFormState({ ...formState });
                }}
              />
              <MuiTypography className={"mt-3"}>Last Name</MuiTypography>
              <MuiTextField
                name={"lastName"}
                fullWidth={true}
                size={"small"}
                type={"text"}
                variant={"outlined"}
                value={formState.lastName}
                onChange={(e) => {
                  formState["lastName"] = e.target.value;
                  setFormState({ ...formState });
                }}
              />
              <MuiTypography className={"mt-3"}>Email Address</MuiTypography>
              <MuiTextField
                fullWidth={true}
                size={"small"}
                type={"text"}
                value={formState.email}
                variant={"outlined"}
                onChange={(e) => {
                  setFormState({ ...formState, email: e.target.value });
                }}
              />
              <MuiTypography className={"mt-3"}>Gender</MuiTypography>
              <MuiSelect
                size={"small"}
                fullWidth={true}
                variant={"outlined"}
                value={formState.gender}
                onChange={(e) => {
                  formState["gender"] = e.target.value;
                  setFormState({ ...formState });
                }}
              >
                <MuiMenuItem value={"male"}>Male</MuiMenuItem>
                <MuiMenuItem value={"female"}>Female</MuiMenuItem>
              </MuiSelect>
              <MuiTypography className={"mt-3"}>Marital Status</MuiTypography>
              <MuiSelect
                fullWidth={true}
                variant={"outlined"}
                value={formState.maritalStatus}
                onChange={(e) => {
                  setFormState({ ...formState, maritalStatus: e.target.value });
                }}
              >
                <MuiMenuItem value={"single"}>Single</MuiMenuItem>
                <MuiMenuItem value={"married"}>Married</MuiMenuItem>
              </MuiSelect>
              <MuiTypography className={"mt-3"}>Address</MuiTypography>
              <MuiTextareaAutosize
                className={"w-100 form-control"}
                value={formState.address}
                rows={4}
                onChange={(e) => {
                  formState["address"] = e.target.value;
                  setFormState({ ...formState });
                }}
              />
              <MuiTypography className={"mt-3"}>
                Upload new profile image
              </MuiTypography>
              <MuiTextField
                variant={"outlined"}
                type={"file"}
                multiple={false}
                onChange={(e) => {
                  const reader = new FileReader();
                  reader.readAsBinaryString(e.target.files[0]);
                  reader.onload = function () {
                    formState["image"] = btoa(reader.result);
                    setFormState({ ...formState });
                  };
                }}
              />
              <MuiFormHelperText>
                *image must be in portrait mode
              </MuiFormHelperText>

              <MuiTypography className={"mt-3"}>
                Enter new password
              </MuiTypography>
              <MuiTextField
                fullWidth={true}
                size={"small"}
                type={"password"}
                variant={"outlined"}
                onChange={(e) => {
                  setFormState({ ...formState, password: e.target.value });
                }}
              />

              <MuiTypography className={"mt-3"}>
                Confirm new password
              </MuiTypography>
              <MuiTextField
                fullWidth={true}
                size={"small"}
                type={"password"}
                variant={"outlined"}
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    confirmPassword: e.target.value,
                  });
                }}
              />

              <MuiDivider className={"my-4"} />

              <MuiButton
                className={"mb-3"}
                type={"submit"}
                color={"primary"}
                variant={"contained"}
                endIcon={<MuiSaveIcon />}
                onClick={async () => {
                  if (formState.password) {
                    if (formState.password !== formState.confirmPassword) {
                      snackbarState.handleOpen(
                        `Password doesn't match, Try again`,
                        "error"
                      );
                      return false;
                    }
                  }
                  setSubmitProgress(true);
                  requestEditTeacher(params.teacherID, formState).then(
                    (data) => {
                      if (data.error) {
                        snackbarState.handleOpen(
                          `Something went wrong, Try again`,
                          "error"
                        );
                        setTimeout(() => {
                          setSubmitProgress(false);
                        }, 2000);
                      } else {
                        snackbarState.handleOpen(
                          `Profile Successfully Updated.`,
                          "success"
                        );
                        setTimeout(() => {
                          setSubmitProgress(false);
                          window.location.reload();
                        }, 2000);
                      }
                    }
                  );
                }}
              >
                {submitProgress ? (
                  <MuiCircularProgress size={24} color="secondary" />
                ) : (
                  "Save Changes"
                )}
              </MuiButton>
            </form>
          </MuiPaper>
        )}
      </MuiGrid>

      <MuiSnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarState.open}
        message={snackbarState.message}
        action={
          <>
            <MuiButton
              color="secondary"
              size="small"
              onClick={snackbarState.handleClose}
            >
              {"CLOSE"}
            </MuiButton>
          </>
        }
      />
    </MuiGrid>
  );
}
