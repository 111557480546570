import { Switch, Route } from "react-router-dom";

import { Root, Update } from "./pages";

export default function Student() {
  return (
    <Switch>
      <Route path={"/admin/dashboard/student/update"} component={Update} />
      <Route path={"/admin/dashboard/student"} component={Root} />
    </Switch>
  );
}
