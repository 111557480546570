import { useState, Fragment } from "react";
import { useHistory } from "react-router";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  Button as MuiButton,
  TableHead as MuiTableHead,
  Zoom as MuiZoom,
} from "@material-ui/core";

import { useUserState } from "../../../../../../hooks";

import { Header, Drawer, Breadcrumbs } from "../../components";

export default function Notifications(props) {
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { userState, setUserState } = useUserState();
  return (
    <MuiGrid container={true} className={"w-100"}>
      <Header
        drawerOpen={openDrawer}
        setDrawerOpen={setOpenDrawer}
        title={"Notifications"}
      />
      <div className={"w-100"}>
        <Drawer drawerOpen={openDrawer} setDrawerOpen={setOpenDrawer} />
        <MuiGrid container={true} justify={"flex-end"} className={"bg-light"}>
          <MuiGrid container={true} item={true}>
            <MuiGrid className={"w-100 p-3 pb-0"}>
              <Breadcrumbs paths={[{ label: "Notifications" }]} />
            </MuiGrid>
            <div className={"w-100 p-3 pb-0"}>
              <MuiTable>
                <MuiTableHead>
                  <MuiTableRow>
                    <MuiTableCell className={"bg-primary-color"}>
                      <MuiTypography variant={"h5"} className={"text-white"}>
                        {"Notifications"}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                </MuiTableHead>
                <MuiTableBody>
                  {userState.notifications.length === 0 ? (
                    <MuiZoom in={true} timeout={600} mountOnEnter unmountOnExit>
                      <MuiTableRow>
                        <MuiTableCell>
                          <MuiTypography
                            align={"center"}
                            variant={"h4"}
                            className={"text-muted"}
                          >
                            {"Nothing to see here."}
                          </MuiTypography>
                        </MuiTableCell>
                      </MuiTableRow>
                    </MuiZoom>
                  ) : (
                    <Fragment>
                      {userState.notifications.map((item, index) => {
                        return (
                          <MuiTableRow key={index}>
                            {item.type === "NEW_INVOICE" && (
                              <MuiTableCell>
                                <MuiGrid container={true}>
                                  <MuiTypography className={"w-100"}>
                                    {
                                      "A new invoice was recently generated for your profile, Please do ensure to pay."
                                    }
                                  </MuiTypography>
                                  <MuiGrid className={"w-100"}>
                                    <MuiButton
                                      variant={"outlined"}
                                      className={"me-2"}
                                      onClick={() =>
                                        history.push(
                                          `/invoices/${item.invoiceID}`
                                        )
                                      }
                                    >
                                      {"View invoice"}
                                    </MuiButton>
                                    <MuiButton
                                      variant={"outlined"}
                                      disabled={item.isRead}
                                      onClick={() => {}}
                                    >
                                      {"Mark as read"}
                                    </MuiButton>
                                  </MuiGrid>
                                </MuiGrid>
                              </MuiTableCell>
                            )}
                          </MuiTableRow>
                        );
                      })}
                    </Fragment>
                  )}
                </MuiTableBody>
              </MuiTable>
            </div>
          </MuiGrid>
        </MuiGrid>
      </div>
    </MuiGrid>
  );
}
