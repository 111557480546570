import React from "react";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
} from "@material-ui/core";

import HeaderV1 from "../HeaderV1";
import SidebarV1 from "../SidebarV1";

export default function LayoutV2(props) {
  return (
    <MuiGrid
      container={true}
      className={"h-[100vh] overflow-hidden bg-[#ebeef2]"}
    >
      <SidebarV1 />

      <MuiGrid
        item={true}
        wrap={"nowrap"}
        xs={12}
        sm={12}
        md={10}
        lg={10}
        xl={10}
        className="relative"
      >
        <HeaderV1 />
        <MuiGrid
          container={true}
          justifyContent={"space-between"}
          alignItems={"center"}
          className={
            "px-[30px] bg-[#000f257e] h-[60px] absolute top-[60px] z-50"
          }
        >
          <MuiGrid item={true}>
            <MuiTypography
              className={"text-[25px] text-[white] font-poppins font-light"}
            >
              {props.title}
            </MuiTypography>
          </MuiGrid>
          <MuiGrid item={true}>{props.actions}</MuiGrid>
        </MuiGrid>
        <MuiGrid
          item={true}
          className={"h-screen overflow-auto pt-[120px] pb-[100px]"}
        >
          {props.children}
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}
