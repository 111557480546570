import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  TableContainer as MuiTableContainer,
  Paper as MuiPaper,
  Typography as MuiTypography,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Checkbox as MuiCheckbox,
  TableBody as MuiTableBody,
  TableFooter as MuiTableFooter,
  MenuItem as MuiMenuItem,
} from "@material-ui/core";
import { useGlobalState } from "../../../../../../hooks";

import {
  AddBox as MuiAddBoxIcon,
  Block as MuiBlockIcon,
  Cached as MuiCachedIcon,
  Preview as MuiPreviewIcon,
  Delete as MuiDeleteIcon,
} from "@mui/icons-material";

import { LayoutV2 } from "../../../../../../components";
import services from "../../../../../../../../services";
import {
  Avatar as MuiAvatar,
  Skeleton as MuiSkeleton,
  TablePagination as MuiTablePagination,
} from "@mui/material";

function trffe(selectedAdministrators, administrator) {
  const val = selectedAdministrators.some(
    (selectedAdministrator) =>
      selectedAdministrator["_id"] === administrator._id
  );

  console.log(val);
  return val;
}

export default function Root() {
  const { globalState, setGlobalState } = useGlobalState();
  const history = useHistory();
  const [loadingStates, setLoadingStates] = useState([true]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [mode, setMode] = useState("default");
  const [administrators, setAdministrators] = useState([...Array(count)]);
  const [selectedAdministrators, setSelectedAdministrators] = useState([]);
  const [totalNumberOfAdministrators, setTotalNumberOfAdministrators] =
    useState(0);

  useEffect(() => {
    if (mode === "default") {
      setLoadingStates((loadingStates) => {
        let newLoadingStates = [...loadingStates];
        newLoadingStates[0] = true;
        return [...newLoadingStates];
      });
      services.api.administrators
        .findAll(page, count)
        .then((data) => {
          setAdministrators(data.data.administrators);
          setTotalNumberOfAdministrators(data.data.totalCount);
          setLoadingStates((loadingStates) => {
            let newLoadingStates = [...loadingStates];
            newLoadingStates[0] = false;
            return [...newLoadingStates];
          });
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }, [page, count, mode]);

  return (
    <LayoutV2
      title={"Administrators"}
      actions={
        <>
          <MuiButton
            size={"small"}
            variant={"contained"}
            className={
              "rounded-sm font-poppins text-[14px] font-light text-capitalize"
            }
            startIcon={<MuiAddBoxIcon />}
            onClick={() => {
              history.push("/admin/dashboard/administrators/create");
            }}
          >
            {"create"}
          </MuiButton>
        </>
      }
    >
      <MuiGrid container={true}>
        <MuiGrid className={"px-[30px] mt-[30px] w-[100%]"}>
          <MuiTableContainer component={MuiPaper}>
            <div
              className={
                "pl-[25px] pr-[25px] py-[10px] flex items-center justify-between"
              }
            >
              {selectedAdministrators.length < 1 ? (
                <p>{"  "}</p>
              ) : (
                <MuiTypography
                  className={"text-[25px] font-poppins font-light"}
                >
                  {`${selectedAdministrators.length} selected`}
                </MuiTypography>
              )}

              <div>
                {selectedAdministrators.length === 1 && (
                  <MuiButton
                    size={"small"}
                    variant={"contained"}
                    className={
                      "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                    }
                    startIcon={<MuiBlockIcon />}
                    onClick={() => {
                      if (selectedAdministrators[0].status === "active") {
                        services.api.administrators
                          .updateOne(selectedAdministrators[0]._id, "ban", {})
                          .then((data) => {
                            alert(data.message);
                          })
                          .catch((error) => {
                            alert(error.message);
                          });
                      } else {
                        services.api.administrators
                          .updateOne(selectedAdministrators[0]._id, "unban", {})
                          .then((data) => {
                            alert(data.message);
                          })
                          .catch((error) => {
                            alert(error.message);
                          });
                      }
                      setSelectedAdministrators([]);
                      services.api.administrators
                        .findAll(page, count)
                        .then((data) => {
                          setAdministrators(data.data.administrators);
                          setTotalNumberOfAdministrators(data.data.totalCount);
                          setLoadingStates((loadingStates) => {
                            let newLoadingStates = [...loadingStates];
                            newLoadingStates[0] = false;
                            return [...newLoadingStates];
                          });
                        })
                        .catch((error) => {
                          alert(error.message);
                        });
                    }}
                  >
                    {selectedAdministrators[0].status === "active"
                      ? "Ban"
                      : "Unban"}
                  </MuiButton>
                )}
                {selectedAdministrators.length === 1 && (
                  <MuiButton
                    size={"small"}
                    variant={"contained"}
                    className={
                      "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                    }
                    startIcon={<MuiPreviewIcon />}
                    onClick={() => {
                      history.push(
                        `/admin/dashboard/administrator?id=${selectedAdministrators[0]._id}`
                      );
                    }}
                  >
                    {"View"}
                  </MuiButton>
                )}

                {selectedAdministrators.length === 1 && (
                  <MuiButton
                    size={"small"}
                    variant={"contained"}
                    className={
                      "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                    }
                    startIcon={<MuiDeleteIcon />}
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to delete this administrator?"
                        )
                      ) {
                        services.api.administrators
                          .deleteOne(selectedAdministrators[0]._id)
                          .then((data) => {
                            alert(data.message);
                          })
                          .catch((error) => {
                            alert(error.message);
                          });
                        setSelectedAdministrators([]);
                        services.api.administrators
                          .findAll(page, count)
                          .then((data) => {
                            setAdministrators(data.data.administrators);
                            setTotalNumberOfAdministrators(
                              data.data.totalCount
                            );
                            setLoadingStates((loadingStates) => {
                              let newLoadingStates = [...loadingStates];
                              newLoadingStates[0] = false;
                              return [...newLoadingStates];
                            });
                          })
                          .catch((error) => {
                            alert(error.message);
                          });
                      }
                    }}
                  >
                    {"Delete"}
                  </MuiButton>
                )}
                <MuiButton
                  size={"small"}
                  variant={"contained"}
                  className={
                    "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                  }
                  startIcon={<MuiCachedIcon />}
                  onClick={() => {
                    setLoadingStates((loadingStates) => {
                      let newLoadingStates = [...loadingStates];
                      newLoadingStates[0] = true;
                      return [...newLoadingStates];
                    });

                    services.api.administrators
                      .findAll(page, count)
                      .then((data) => {
                        setAdministrators(data.data.administrators);
                        setTotalNumberOfAdministrators(data.data.totalCount);
                        setLoadingStates((loadingStates) => {
                          let newLoadingStates = [...loadingStates];
                          newLoadingStates[0] = false;
                          return [...newLoadingStates];
                        });
                      })
                      .catch((error) => {
                        alert(error.message);
                      });
                    setPage(1);
                  }}
                >
                  {"Reload"}
                </MuiButton>
              </div>
            </div>
            <MuiTable>
              <MuiTableHead>
                <MuiTableRow>
                  <MuiTableCell>
                    <MuiCheckbox disabled={true} />
                  </MuiTableCell>
                  <MuiTableCell>
                    <MuiAvatar />
                  </MuiTableCell>
                  <MuiTableCell>Administrator ID</MuiTableCell>
                  <MuiTableCell>First Name</MuiTableCell>
                  <MuiTableCell>Last Name</MuiTableCell>
                  <MuiTableCell>Gender</MuiTableCell>
                  <MuiTableCell>Status</MuiTableCell>
                </MuiTableRow>
              </MuiTableHead>
              <MuiTableBody>
                {administrators.map((item, index) => {
                  if (loadingStates[0] === true) {
                    return (
                      <MuiTableRow key={index}>
                        <MuiTableCell>
                          <MuiCheckbox checked={false} disabled={true} />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton
                            variant="circular"
                            width={40}
                            height={40}
                          />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                      </MuiTableRow>
                    );
                  } else {
                    return (
                      <MuiTableRow key={index} className={"cursor-pointer"}>
                        <MuiTableCell>
                          {trffe(selectedAdministrators, item) === true ? (
                            <MuiCheckbox
                              checked={true}
                              onClick={(event) => {
                                setSelectedAdministrators(
                                  (selectedAdministrators) => {
                                    let state = [...selectedAdministrators];
                                    state = state.filter((item2) => {
                                      return item._id !== item2._id;
                                    });
                                    return state;
                                  }
                                );
                              }}
                            />
                          ) : (
                            <MuiCheckbox
                              checked={false}
                              onClick={(event) => {
                                setSelectedAdministrators([
                                  ...selectedAdministrators,
                                  item,
                                ]);
                              }}
                            />
                          )}
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiAvatar
                            src={
                              item.image
                                ? item.image
                                : `https://ilechuks73.com.ng/uploads/images/profile/${item._id.replaceAll(
                                    "/",
                                    "-"
                                  )}.jpg`
                            }
                          />
                        </MuiTableCell>
                        <MuiTableCell>{item?._id?.toUpperCase()}</MuiTableCell>
                        <MuiTableCell className={"text-capitalize"}>
                          {item?.firstName}
                        </MuiTableCell>
                        <MuiTableCell className={"text-capitalize"}>
                          {item?.lastName}
                        </MuiTableCell>
                        <MuiTableCell>{item?.gender}</MuiTableCell>
                        <MuiTableCell>
                          {item?.status?.toUpperCase()}
                        </MuiTableCell>
                      </MuiTableRow>
                    );
                  }
                })}
              </MuiTableBody>
              <MuiTableFooter>
                <MuiTableRow>
                  <MuiTablePagination
                    rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    colSpan={8}
                    count={totalNumberOfAdministrators}
                    rowsPerPage={count}
                    page={page - 1}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={(event, page) => {
                      if (loadingStates[0] === true) {
                        return false;
                      }
                      setPage(page + 1);
                    }}
                    onRowsPerPageChange={(event) => {
                      setPage(1);
                      setCount(parseInt(event.target.value));
                    }}
                    showFirstButton={true}
                    showLastButton={true}
                    // ActionsComponent={TablePaginationActions}
                  />
                </MuiTableRow>
              </MuiTableFooter>
            </MuiTable>
          </MuiTableContainer>
        </MuiGrid>
      </MuiGrid>
    </LayoutV2>
  );
}
