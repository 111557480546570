import { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { Grid as MuiGrid } from "@material-ui/core";
import {
  Overview,
  Students,
  Subjects,
  Teachers,
  Results,
  Announcements,
} from "./pages";
import { Header, Drawer } from "./components";

import { requestClasses } from "../../../../utils/apiRequests";
import { useClassState } from "../../../../hooks";

export default function Dashboard() {
  const history = useHistory();
  const { classState, setClassState } = useClassState();
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const ID = new URLSearchParams(window.location.search).get("ID");
    const accessMode = new URLSearchParams(window.location.search).get(
      "accessMode"
    );
    if (ID && accessMode) {
      setClassState((classState) => {
        return {
          ...classState,
          _id: ID,
          accessMode: accessMode,
        };
      });
      requestClasses
        .findClassByID(ID)
        .then((data) => {
          setClassState((classState) => {
            return {
              ...classState,
              ...data,
            };
          });
          setTimeout(() => {
            history.push("/class/dashboard/overview");
          }, 2000);
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, []);
  return (
    <MuiGrid container={true}>
      <Header
        title={"test"}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
      <Drawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <MuiGrid className={`min-vh-100 flex-grow-1`}>
        <Switch>
          <Route path={"/class/dashboard/overview"} component={Overview} />
          <Route path={"/class/dashboard/students"} component={Students} />
          <Route path={"/class/dashboard/subjects"} component={Subjects} />

          <Route path={"/class/dashboard/teachers"} component={Teachers} />
          <Route path={"/class/dashboard/results"} component={Results} />
          <Route
            path={"/class/dashboard/announcements"}
            component={Announcements}
          />
        </Switch>
      </MuiGrid>
    </MuiGrid>
  );
}
