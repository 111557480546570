import { useState, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Typography as MuiTypography,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Grow as MuiGrow,
  Zoom as MuiZoom,
  Collapse as MuiCollapse,
  TextField as MuiTextField,
} from "@material-ui/core";

import { useUserState, useClassState } from "../../../../../../hooks";
import { requestClasses } from "../../../../../../utils/apiRequests";

export default function Teachers() {
  const { userState } = useUserState();
  const history = useHistory();
  const { classState, setClassState } = useClassState();
  const params = useParams();
  const [assignTeacherMode, setAssignTeacherMode] = useState(false);
  const [formState, setFormState] = useState({ emailAddress: "" });

  return (
    <MuiGrid
      container={true}
      justify={"center"}
      className={"Class_Teachers pt-4 "}
    >
      <MuiGrid item={true} xs={11}>
        <MuiGrid container={true}>
          <MuiGrid container={true}>
            <MuiGrid container={true} className={"MuiGrid1 w-100 p-2"}>
              <MuiGrid className={"flex-grow-1"}>
                <MuiTypography
                  variant={"h6"}
                  className={"MuiTypography1 h-100"}
                >
                  {"Teachers"}
                </MuiTypography>
              </MuiGrid>
              <MuiGrid item={true}>
                <MuiButton
                  className={"bg-white mx-1"}
                  onClick={() => {
                    requestClasses
                      .findClassByID(classState._id)
                      .then((data) => {
                        setClassState((classState) => {
                          return {
                            ...classState,
                            ...data,
                          };
                        });
                      })
                      .catch((error) => {
                        alert(error);
                      });
                  }}
                >
                  <MuiTypography className={"mx-1"}>{"Refresh"}</MuiTypography>
                </MuiButton>
                <MuiButton className={"bg-white mx-1"}>
                  <MuiTypography
                    variant={"button"}
                    className={"mx-1"}
                    onClick={() => {
                      setAssignTeacherMode(true);
                    }}
                  >
                    {"Assign"}
                  </MuiTypography>
                </MuiButton>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid container={true} className={"px-3 py-4"}>
            <MuiCollapse in={assignTeacherMode} className={"w-100"}>
              <MuiTypography>{"Email Address"}</MuiTypography>
              <MuiTextField
                size={"small"}
                variant={"outlined"}
                className={"w-100"}
                onChange={(event) => {
                  setFormState((formState) => {
                    return { ...formState, emailAddress: event.target.value };
                  });
                }}
              />
              <MuiGrid className={"py-3"}>
                <MuiButton
                  size={"small"}
                  color={"primary"}
                  variant={"contained"}
                  onClick={() => {
                    requestClasses.teachers
                      .assign(classState._id, formState.emailAddress)
                      .then(() => {
                        requestClasses
                          .findClassByID(classState._id)
                          .then((data) => {
                            setClassState((classState) => {
                              return {
                                ...classState,
                                ...data,
                              };
                            });
                          })
                          .catch((error) => {
                            alert(error);
                          });
                        alert("Assigned Successfully!");
                      })
                      .catch((error) => {
                        alert(error);
                      });
                  }}
                >
                  {"Submit"}
                </MuiButton>
                <MuiButton
                  size={"small"}
                  color={"secondary"}
                  variant={"contained"}
                  className={"mx-2"}
                  onClick={() => {
                    setAssignTeacherMode(false);
                  }}
                >
                  {"close"}
                </MuiButton>
              </MuiGrid>
            </MuiCollapse>
          </MuiGrid>
          <MuiTable className={" border"}>
            <MuiTableHead>
              <MuiTableRow>
                <MuiTableCell className={"bg-info border-0 fw-bold"}>
                  <MuiTypography>{"Teacher ID"}</MuiTypography>
                </MuiTableCell>
                <MuiTableCell className={"bg-info border-0 fw-bold"}>
                  <MuiTypography>{"First Name"}</MuiTypography>
                </MuiTableCell>
                <MuiTableCell className={"bg-info border-0 fw-bold"}>
                  <MuiTypography>{"Last Name"}</MuiTypography>
                </MuiTableCell>
                {classState.accessMode === "administrator" && (
                  <MuiTableCell className={"bg-info border-0 fw-bold"}>
                    <MuiTypography>{"Actions"}</MuiTypography>
                  </MuiTableCell>
                )}
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody>
              {classState.teachers.length === 0 ? (
                <MuiGrow in={true} timeout={1500}>
                  <MuiTableRow>
                    <MuiTableCell colSpan={4}>
                      <MuiTypography
                        variant={"h1"}
                        className={"text-muted text-center"}
                      >
                        {"No teachers to show"}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                </MuiGrow>
              ) : (
                <Fragment>
                  {classState.teachers.map((teacher, index) => {
                    return (
                      <MuiZoom in={true} timeout={1500 + index * 100}>
                        <MuiTableRow key={index}>
                          <MuiTableCell>{teacher._id}</MuiTableCell>
                          <MuiTableCell>{teacher.firstName}</MuiTableCell>
                          <MuiTableCell>{teacher.lastName}</MuiTableCell>
                          {classState.accessMode === "administrator" && (
                            <MuiTableCell>
                              <MuiButton
                                size={"small"}
                                onClick={() => {
                                  requestClasses.teachers
                                    .deassign(classState._id, teacher._id)
                                    .then(() => {
                                      requestClasses
                                        .findClassByID(classState._id)
                                        .then((data) => {
                                          setClassState((classState) => {
                                            return {
                                              ...classState,
                                              ...data,
                                            };
                                          });
                                        })
                                        .catch((error) => {
                                          alert(error);
                                        });
                                      alert("Deassigned successfully!");
                                    })
                                    .catch((error) => {
                                      alert(error);
                                    });
                                }}
                              >
                                {"DEASSIGN"}
                              </MuiButton>
                            </MuiTableCell>
                          )}
                        </MuiTableRow>
                      </MuiZoom>
                    );
                  })}
                </Fragment>
              )}
            </MuiTableBody>
          </MuiTable>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}
