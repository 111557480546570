import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalState } from "../../../../../../hooks";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Typography as MuiTypography,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableFooter as MuiTableFooter,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  Paper as MuiPaper,
  Checkbox as MuiCheckbox,
} from "@material-ui/core";

import {
  Skeleton as MuiSkeleton,
  Avatar as MuiAvatar,
  TablePagination as MuiTablePagination,
} from "@mui/material";

import {
  AddBox as MuiAddBoxIcon,
  Cached as MuiCachedIcon,
  Preview as MuiPreviewIcon,
  Block as MuiBlockIcon,
} from "@mui/icons-material";

import { StudentSearch } from "./components";

import { LayoutV1, LayoutV2 } from "../../../../components";
import services from "../../../../../../services";
import { enqueueSnackbar } from "notistack";
import * as xlsx from "xlsx";

function trffe(selectedStudents, student) {
  const val = selectedStudents.some(
    (selectedStudent) => selectedStudent["_id"] === student._id
  );

  console.log(val);
  return val;
}

export default function Students() {
  const [loadingStates, setLoadingStates] = useState([true]);
  const [mode, setMode] = useState("default");
  const { globalState, setGlobalState } = useGlobalState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [totalNumberOfStudents, setTotalNumberOfStudents] = useState(0);
  const history = useHistory();
  const [students, setStudents] = useState([...Array(count)]);
  const [isLoading, setIsLoading] = useState([
    false, // for download button
  ]);
  useEffect(() => {
    if (mode === "default") {
      setLoadingStates((loadingStates) => {
        let newLoadingStates = [...loadingStates];
        newLoadingStates[0] = true;
        return [...newLoadingStates];
      });
      services.api.students
        .findAll(page, count)
        .then((data) => {
          setStudents(data.data.students);
          setTotalNumberOfStudents(data.data.totalCount);
          setLoadingStates((loadingStates) => {
            let newLoadingStates = [...loadingStates];
            newLoadingStates[0] = false;
            return [...newLoadingStates];
          });
        })
        .catch((error) => {
          enqueueSnackbar(error.message);
        });
    }
  }, [page, count, mode]);

  useEffect(() => {
    setStudents((students) => {
      return [...globalState.students];
    });
  }, [globalState]);

  const [menuState, setMenuState] = useState({
    isOpen: false,
    userState: null,
    anchorElement: null,
    handleOpen: function (userState, anchorElement) {
      setMenuState({
        ...menuState,
        isOpen: true,
        userState: userState,
        anchorElement: anchorElement,
      });
    },
    handleClose: function () {
      setMenuState({
        ...menuState,
        isOpen: false,
        anchorElement: null,
      });
    },
  });

  return (
    <LayoutV2
      title={"Students"}
      actions={
        <div className={"flex gap-x-2"}>
          <MuiButton
            disabled={isLoading[0] === true}
            size={"small"}
            variant={"contained"}
            className={
              "rounded-sm font-poppins text-[14px] font-light text-capitalize"
            }
            onClick={async () => {
              try {
                setIsLoading((isLoading) => {
                  isLoading[0] = true;
                  return [...isLoading];
                });
                const data = await services.api.students.findAll(page, 1000);
                let rows = [["ID", "Name", "Pin"]];
                data.data.students.forEach((student) => {
                  rows.push([
                    student._id,
                    `${student.firstName} ${student.lastName}`,
                    student.pin,
                  ]);
                });

                // Function to generate and download the xlsx file
                const exportToExcel = (data, fileName) => {
                  // Create a new workbook
                  const workbook = xlsx.utils.book_new();

                  // Convert the array into a worksheet
                  const worksheet = xlsx.utils.aoa_to_sheet(data);

                  // Append the worksheet to the workbook
                  xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");

                  // Generate a binary Excel file
                  const excelBuffer = xlsx.write(workbook, {
                    bookType: "xlsx",
                    type: "array",
                  });

                  // Create a Blob from the buffer
                  const blob = new Blob([excelBuffer], {
                    type: "application/octet-stream",
                  });

                  // Trigger download using a temporary link element
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = `${fileName}.xlsx`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                };

                // Example usage: Export and download the file
                exportToExcel(rows, "students");
                setIsLoading((isLoading) => {
                  isLoading[0] = false;
                  return [...isLoading];
                });
              } catch (error) {
                console.log(error.message);
                enqueueSnackbar(error.message);
              }
            }}
          >
            {"Download"}
          </MuiButton>
          <MuiButton
            size={"small"}
            variant={"contained"}
            className={
              "rounded-sm font-poppins text-[14px] font-light text-capitalize"
            }
            onClick={async () => {
              const response = await services.api.v2.students.pins.scramble();
              if (response.status === 200) {
                enqueueSnackbar("Success");
              } else {
                enqueueSnackbar("Something went wrong!!!");
              }
            }}
          >
            {"Scramble pins"}
          </MuiButton>
          <MuiButton
            size={"small"}
            variant={"contained"}
            className={
              "rounded-sm font-poppins text-[14px] font-light text-capitalize"
            }
            startIcon={<MuiAddBoxIcon />}
            onClick={() => {
              history.push("/student/create");
            }}
          >
            {"create"}
          </MuiButton>
        </div>
      }
    >
      <MuiGrid container={true}>
        <MuiGrid className={"px-[30px] mt-[30px] w-[100%]"}>
          <MuiTableContainer component={MuiPaper}>
            <div
              className={
                "pl-[25px] pr-[25px] py-[10px] flex items-center justify-between"
              }
            >
              {selectedStudents.length < 1 ? (
                <>
                  <StudentSearch setMode={setMode} setStudents={setStudents} />
                </>
              ) : (
                <MuiTypography
                  className={"text-[25px] font-poppins font-light"}
                >
                  {`${selectedStudents.length} selected`}
                </MuiTypography>
              )}

              <div>
                {selectedStudents.length > 0 && (
                  <MuiButton
                    size={"small"}
                    variant={"contained"}
                    className={
                      "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                    }
                    startIcon={<MuiBlockIcon />}
                    onClick={() => {
                      history.push(
                        `/student/profile?ID=${selectedStudents[0]._id.replace(
                          /\//g,
                          "-"
                        )}`
                      );
                    }}
                  >
                    {"Deactivate"}
                  </MuiButton>
                )}
                {selectedStudents.length === 1 && (
                  <>
                    <MuiButton
                      size={"small"}
                      variant={"contained"}
                      className={
                        "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                      }
                      startIcon={<MuiPreviewIcon />}
                      onClick={() => {
                        history.push(
                          `/admin/dashboard/student?ID=${selectedStudents[0]._id.replace(
                            /\//g,
                            "-"
                          )}`
                        );
                      }}
                    >
                      {"View"}
                    </MuiButton>
                    <MuiButton
                      size={"small"}
                      variant={"contained"}
                      className={
                        "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                      }
                      onClick={() => {
                        const data = prompt("Enter new password", "password");
                        if (!data) {
                          return false;
                        }
                        services.api.students
                          .updateOne(
                            selectedStudents[0]._id,
                            "update_password",
                            { password: data }
                          )
                          .then((data) => {
                            alert(data.message);
                          })
                          .catch((error) => {
                            alert(error.message);
                          });
                      }}
                    >
                      {"Update Password"}
                    </MuiButton>
                  </>
                )}
                <MuiButton
                  size={"small"}
                  variant={"contained"}
                  className={
                    "me-2 rounded-sm font-poppins text-[14px] font-light text-capitalize"
                  }
                  startIcon={<MuiCachedIcon />}
                  onClick={() => {
                    setLoadingStates((loadingStates) => {
                      let newLoadingStates = [...loadingStates];
                      newLoadingStates[0] = true;
                      return [...newLoadingStates];
                    });

                    services.api.students
                      .findAll(1, count)
                      .then((data) => {
                        setStudents(data.data.students);
                        setTotalNumberOfStudents(data.data.totalCount);
                        setLoadingStates((loadingStates) => {
                          let newLoadingStates = [...loadingStates];
                          newLoadingStates[0] = false;
                          return [...newLoadingStates];
                        });
                      })
                      .catch((error) => {
                        alert(error.message);
                      });
                    setPage(1);
                  }}
                >
                  {"Reload"}
                </MuiButton>
              </div>
            </div>
            <MuiTable>
              <MuiTableHead>
                <MuiTableRow>
                  <MuiTableCell>
                    <MuiCheckbox disabled={true} />
                  </MuiTableCell>
                  <MuiTableCell>
                    <MuiAvatar />
                  </MuiTableCell>
                  <MuiTableCell>Student ID</MuiTableCell>
                  <MuiTableCell>First Name</MuiTableCell>
                  <MuiTableCell>Last Name</MuiTableCell>
                  <MuiTableCell>Gender</MuiTableCell>
                  <MuiTableCell>Class</MuiTableCell>
                  <MuiTableCell>Status</MuiTableCell>
                </MuiTableRow>
              </MuiTableHead>
              <MuiTableBody>
                {students.map((item, index) => {
                  if (loadingStates[0] === true) {
                    return (
                      <MuiTableRow key={index}>
                        <MuiTableCell>
                          <MuiCheckbox checked={false} disabled={true} />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton
                            variant="circular"
                            width={40}
                            height={40}
                          />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiSkeleton />
                        </MuiTableCell>
                      </MuiTableRow>
                    );
                  } else {
                    return (
                      <MuiTableRow key={index} className={"cursor-pointer"}>
                        <MuiTableCell>
                          {trffe(selectedStudents, item) === true ? (
                            <MuiCheckbox
                              checked={true}
                              onClick={(event) => {
                                setSelectedStudents((selectedStudents) => {
                                  let state = [...selectedStudents];
                                  state = state.filter((item2) => {
                                    return item._id !== item2._id;
                                  });
                                  return state;
                                });
                              }}
                            />
                          ) : (
                            <MuiCheckbox
                              checked={false}
                              onClick={(event) => {
                                setSelectedStudents([
                                  ...selectedStudents,
                                  item,
                                ]);
                              }}
                            />
                          )}
                        </MuiTableCell>
                        <MuiTableCell>
                          <MuiAvatar
                            src={`https://ilechuks73.com.ng/uploads/images/profile/${item._id.replaceAll(
                              "/",
                              "-"
                            )}.jpg`}
                          />
                        </MuiTableCell>
                        <MuiTableCell>{item?._id?.toUpperCase()}</MuiTableCell>
                        <MuiTableCell className={"text-capitalize"}>
                          {item?.firstName}
                        </MuiTableCell>
                        <MuiTableCell className={"text-capitalize"}>
                          {item?.lastName}
                        </MuiTableCell>
                        <MuiTableCell>{item?.gender}</MuiTableCell>
                        <MuiTableCell>
                          {item?.class?.name ? item.class.name : "Not Assigned"}
                        </MuiTableCell>
                        <MuiTableCell>
                          {item?.status?.toUpperCase()}
                        </MuiTableCell>
                      </MuiTableRow>
                    );
                  }
                })}
              </MuiTableBody>
              <MuiTableFooter>
                <MuiTableRow>
                  {mode === "default" && (
                    <MuiTablePagination
                      rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                      colSpan={8}
                      count={totalNumberOfStudents}
                      rowsPerPage={count}
                      page={page - 1}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={(event, page) => {
                        if (loadingStates[0] === true) {
                          return false;
                        }
                        setPage(page + 1);
                      }}
                      onRowsPerPageChange={(event) => {
                        setPage(1);
                        setCount(parseInt(event.target.value));
                      }}
                      showFirstButton={true}
                      showLastButton={true}
                      // ActionsComponent={TablePaginationActions}
                    />
                  )}
                </MuiTableRow>
              </MuiTableFooter>
            </MuiTable>
          </MuiTableContainer>
        </MuiGrid>
      </MuiGrid>
    </LayoutV2>
  );
}
