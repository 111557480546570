import { useState, useEffect } from "react";
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
} from "@material-ui/core";

import services from "../../../../../../../services";

export default function AdministratorMetrics() {
  const [administratorMetrics, setAdministratorMetrics] = useState({
    totalNumberOfAdministrators: 0,
  });
  const [selectedMetric, setSelectedMetric] = useState(
    "totalNumberOfAdministrators"
  );

  useEffect(() => {
    services.api.administrators
      .metrics()
      .then((data) => {
        setAdministratorMetrics(data.data);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return (
    <MuiGrid item={true} xs={12} sm={6}>
      <MuiPaper
        elevation={0}
        square
        className={"p-[10px] sm:p-[20px] flex justify-between sm:block h-100]"}
      >
        <MuiTypography
          noWrap={true}
          className={"font-poppins text-[25px] font-light"}
        >
          {"Administrators"}
        </MuiTypography>
        <MuiTypography noWrap={true} className={"text-[25px] sm:text-[50px]"}>
          {administratorMetrics[selectedMetric]}
        </MuiTypography>
      </MuiPaper>
    </MuiGrid>
  );
}
