import { useEffect, useState, Fragment } from "react";
import {
  Button as MuiButton,
  Avatar as MuiAvatar,
  Typography as MuiTypography,
  Divider as MuiDivider,
} from "@mui/material";
import services from "../../../../../../../../services";

//THIS COMPONENTS IS THE SIDE WIDGET THAT APPEAR WHEN YOU WANT TO CREATE A NEW CONVERSATION
export function C1(props) {
  const [administrators, setAdministrators] = useState([]);
  useEffect(() => {
    services.api.administrators
      .findAll(1, 20)
      .then((data) => {
        setAdministrators(data.data.administrators);
        console.log(data.data.administrator);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return (
    <div>
      {administrators.map((administrator, index) => {
        return (
          <Fragment key={index}>
            <MuiButton
              variant={"outlined"}
              className={"d-block w-[100%] border-none py-[10px]"}
              onClick={async () => {
                try {
                  const data = await services.api.conversations.create({
                    members: [
                      {
                        role: "administrator",
                        id: localStorage.getItem("administrator"),
                      },
                      { role: "administrator", id: administrator._id },
                    ],
                  });
                  props.setConversation(data.data.conversation);
                  props.setMode("chat");
                  console.log(administrator._id);
                } catch (error) {
                  alert(error.message);
                }
              }}
            >
              <div className={"flex items-center"}>
                <MuiAvatar src={administrator.image} className={"mr-[5px]"} />
                <MuiTypography
                  className={
                    "text-capitalize text-[14px] ml-[5px] text-[black]"
                  }
                  noWrap={true}
                >{`${administrator.firstName} ${administrator.lastName}`}</MuiTypography>
              </div>
            </MuiButton>
            {index < administrators.length && <MuiDivider />}
          </Fragment>
        );
      })}
    </div>
  );
}

export function C2(props) {}
