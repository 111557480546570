import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  List,
  ListItemText,
  Divider as MuiDivider,
  Box as MuiBox,
  Grid as MuiGrid,
  Typography as MuiTypography,
  ListItem as MuiListItem,
} from "@material-ui/core";

import { Slide as MuiSlide } from "@mui/material";

import { useScreenBreakpoint } from "../../../../hooks";
import { useComponents } from "../../hooks";

const mainNavbarItems = [
  {
    id: 0,
    label: "Overview",
    handleClick: (history, sidebar, screenBreakpoint) => {
      history.replace("/student/dashboard/overview");
      if (screenBreakpoint === "sm" || screenBreakpoint === "xs") {
        sidebar.close();
      }
    },
  },
  {
    id: 2,
    label: "Results",
    handleClick: (history, sidebar, screenBreakpoint) => {
      history.replace("/student/dashboard/Results");
      if (screenBreakpoint === "sm" || screenBreakpoint === "xs") {
        sidebar.close();
      }
    },
  },
  // {
  //   id: 9,
  //   label: "Announcements",
  //   handleClick: (history) => {
  //     history.replace("/student/dashboard/announcements");
  //   },
  // },
  {
    id: 1,
    label: "Profile",
    handleClick: (history, sidebar, screenBreakpoint) => {
      history.replace("/student/dashboard/profile");
      if (screenBreakpoint === "sm" || screenBreakpoint === "xs") {
        sidebar.close();
      }
    },
  },
];

export default function SidebarV1(props) {
  const history = useHistory();
  const screenBreakpoint = useScreenBreakpoint();
  const { componentsState, sidebarV1 } = useComponents();
  useEffect(() => {
    if (screenBreakpoint !== "sm" && screenBreakpoint !== "xs") {
      sidebarV1.open();
    } else {
      sidebarV1.close();
    }
  }, [screenBreakpoint]);
  return (
    <MuiSlide
      in={componentsState.sidebarV1.isOpen}
      direction={"right"}
      timeout={100}
      unmountOnExit={true}
    >
      <MuiGrid
        xs={12}
        sm={12}
        md={2}
        lg={2}
        xl={2}
        className={"h-[100vh] overflow-y-auto bg-[#000f25]"}
      >
        <MuiGrid className="h-[60px] py-[20px] px-[30px] bg-[black] flex items-center">
          <MuiTypography
            noWrap={true}
            className={"font-poppins text-[20px] font-light text-[white]"}
          >
            Park British School
          </MuiTypography>
        </MuiGrid>

        <List className="w-[100%] py-0 ">
          {mainNavbarItems.map((item, index) => {
            return (
              <MuiListItem
                className={`h-[60px] py-0 cursor-pointer pl-[30px] hover:bg-[black]`}
                key={item.id}
                onClick={() =>
                  item.handleClick(history, sidebarV1, screenBreakpoint)
                }
                selected={props.selectedIndex === index}
              >
                <ListItemText className={"h-[100%] "}>
                  <MuiTypography
                    className={`h-[100%] w-[100%] text-[15px] flex items-center font-poppins font-light text-[#abb4be] hover:text-[white]`}
                  >
                    {item.label}
                  </MuiTypography>
                </ListItemText>
              </MuiListItem>
            );
          })}
          <MuiDivider />
          <MuiListItem
            className="pl-[30px] cursor-pointer"
            onClick={() => {
              history.replace("/signin");
              sessionStorage.clear();
              localStorage.clear();
            }}
          >
            <ListItemText>
              <MuiTypography className={"font-poppins font-light text-danger"}>
                {"Sign Out"}
              </MuiTypography>
            </ListItemText>
          </MuiListItem>
        </List>
      </MuiGrid>
    </MuiSlide>
  );
}
