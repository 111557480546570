import { useEffect, useState } from "react";
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
} from "@material-ui/core";
import services from "../../../../../../../services";
export default function TicketMetrics() {
  const [ticketMetrics, setTicketMetrics] = useState({
    totalNumberOfTickets: "---",
  });
  const [selectedMetric, setSelectedMetric] = useState("totalNumberOfTickets");
  useEffect(() => {}, []);
  return (
    <MuiGrid item={true} xs={12} sm={6}>
      <MuiPaper
        elevation={0}
        square
        className={"p-[10px] sm:p-[20px] flex justify-between sm:block h-100"}
      >
        <MuiTypography
          noWrap={true}
          className={"font-poppins text-[25px] font-light"}
        >
          {"Tickets"}
        </MuiTypography>
        <MuiTypography noWrap={true} className={"text-[25px] sm:text-[50px]"}>
          {ticketMetrics[selectedMetric]}
        </MuiTypography>
      </MuiPaper>
    </MuiGrid>
  );
}
