import { Switch, Route } from "react-router-dom";

import { Root, Update } from "./pages";

export default function Teacher() {
  return (
    <Switch>
      <Route path={"/admin/dashboard/teacher/update"} component={Update} />
      <Route path={"/admin/dashboard/teacher"} component={Root} />
    </Switch>
  );
}
