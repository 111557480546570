export const _A1 = {
  isOpen: false,
  close: function (state, setState) {
    setState({
      ...state,
      isOpen: false,
    });
  },
  open: function (state, setState) {
    setState({
      ...state,
      isOpen: true,
    });
  },
};

export const _A2 = {
  school: "",
  title: "",
  student: "",
  scoreSheet: "",
  term: "",
  session: "",
  class: "",
  teachersRemark: "",
  gradingScale: "",
  principalsRemark: "",
  resumptionDate: "",
  type: "endOfTerm",
  comments: "N/A",
  electives: [],
};

export const _B1 = (
  userState,
  history,
  requestTerm,
  requestSession,
  setTerm,
  setFormState,
  setSession
) => {
  if (userState === null) {
    history.push("/signin");
  }
  requestTerm().then((data) => {
    setTerm(data);
    setFormState((formState) => {
      return {
        ...formState,
        term: data.term,
      };
    });
  });
  requestSession().then((data) => {
    console.log(data);
    setSession(data);
    setFormState((formState) => {
      return {
        ...formState,
        session: data.session,
      };
    });
  });
};
