import { useState, useEffect } from "react";
import {
  Grid as MuiGrid,
  Box as MuiBox,
  Paper as MuiPaper,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Avatar as MuiAvatar,
} from "@mui/material";

import services from "../../../../../../../services";

function getTimeElapsed(date) {
  const now = new Date();
  const elapsed = now - date;

  // Calculate elapsed time in seconds, minutes, hours, days, and months
  const seconds = Math.floor(elapsed / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
  }
}

export default function TeacherActivityLogs() {
  const [activityLogs, setActivityLogs] = useState([]);
  useEffect(() => {
    try {
      services.api.teachers.activityLogs
        .findAll(1, 6)
        .then((data) => {
          setActivityLogs(data.data.teacherActivityLogs);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }

    return () => {};
  }, []);
  return (
    <MuiGrid item={true} xs={12}>
      <MuiPaper elevation={0} className={"h-[100%]] rounded-0"}>
        <MuiBox className={"px-[10px] sm:px-[30px] py-[10px]"}>
          <MuiTypography className={"font-poppins font-light text-[16px]"}>
            Teacher Activity Logs
          </MuiTypography>
        </MuiBox>
        <MuiDivider className={"bg-[black]"} />
        <MuiBox className={`px-[10px] sm:px-[30px]`}>
          <MuiTable>
            <MuiTableBody>
              {activityLogs.length === 0 ? (
                <MuiTableRow>
                  <MuiTableCell colSpan={2}>
                    <MuiTypography align={"center"}>{"..."}</MuiTypography>
                  </MuiTableCell>
                </MuiTableRow>
              ) : (
                activityLogs.map((item, index) => {
                  return (
                    <MuiTableRow key={index}>
                      <MuiTableCell>
                        <div className={"flex justify-between"}>
                          <div className={"flex-grow-1"}>
                            <p className={"font-poppins"}>
                              {item.type.replaceAll("_", " ")}
                            </p>
                          </div>
                          <div>
                            <p className={"font-poppins text-[grey]"}>
                              {getTimeElapsed(new Date(item.createdAt))}
                            </p>
                          </div>
                        </div>
                        <div className={"mt-[4px]"}>
                          <p
                            className={
                              "text-[13px] cursor-pointer hover:text-[blue] hover:underline"
                            }
                          >{`${item.teacher.firstName} ${item.teacher.lastName}`}</p>
                        </div>
                      </MuiTableCell>
                    </MuiTableRow>
                  );
                })
              )}
            </MuiTableBody>
          </MuiTable>
        </MuiBox>
      </MuiPaper>
    </MuiGrid>
  );
}
