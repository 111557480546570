import React from "react";
import { Grid as MuiGrid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { ExpandLess as MuiExpandLessIcon } from "@material-ui/icons";

import { useWidth } from "../../../../../../hooks";
import { useHistory } from "react-router-dom";

export default function Header(props) {
  const history = useHistory();
  const width = useWidth();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position={"relative"} elevation={0}>
      <Toolbar styles={{ zIndex: 9999 }}>
        <MuiGrid container={true} alignItems={"center"}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              props.setDrawerOpen(!props.drawerOpen);
            }}
          >
            {props.drawerOpen ? <MuiExpandLessIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" className={"mt-1"}>{`Class`}</Typography>
        </MuiGrid>
        <MuiGrid>
          <IconButton onClick={handleMenu} color="inherit">
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/");
                sessionStorage.clear();
                window.location.reload();
              }}
            >
              Sign Out
            </MenuItem>
          </Menu>
        </MuiGrid>
      </Toolbar>
    </AppBar>
  );
}
