import { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Button as MuiButton,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Grow as MuiGrow,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

import { Header, Drawer, Breadcrumbs } from "../../components";

import { useUserState } from "../../../../../../hooks";

export default function Results() {
  const history = useHistory();
  const { userState } = useUserState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    if (userState.results.length > 0) {
      setData({
        ...userState.results[userState.results.length - 2],
        scoreSheet: JSON.parse(
          userState.results[userState.results.length - 1].scoreSheet
        ),
      });
    }
  }, []);

  return (
    <MuiGrid container={true} className={"w-100"}>
      <Header
        drawerOpen={openDrawer}
        setDrawerOpen={setOpenDrawer}
        title={"Results"}
      />
      <div className={"w-100"}>
        <Drawer drawerOpen={openDrawer} setDrawerOpen={setOpenDrawer} />
        <MuiGrid container={true} justify={"flex-end"} className={"bg-light"}>
          <MuiGrid className={"w-100 p-3"}>
            <Breadcrumbs paths={[{ label: "Results" }]} />
          </MuiGrid>
          <MuiGrid container={true} item={true}>
            <div className={"w-100 p-3"}>
              <Table>
                <TableHead>
                  <MuiTableRow>
                    <TableCell className={"bg-primary-color"}>
                      <MuiTypography variant={"h6"}>ID</MuiTypography>
                    </TableCell>
                    <TableCell className={"bg-primary-color"}>
                      <MuiTypography variant={"h6"}>Session</MuiTypography>
                    </TableCell>
                    <TableCell className={"bg-primary-color"}>
                      <MuiTypography variant={"h6"}>Term</MuiTypography>
                    </TableCell>
                    <TableCell className={"bg-primary-color"}>
                      <MuiTypography>{"Actions"}</MuiTypography>
                    </TableCell>
                  </MuiTableRow>
                </TableHead>
                <TableBody>
                  {userState.results.length === 0 ? (
                    <MuiGrow
                      in={true}
                      timeout={1000}
                      unmountOnExit
                      mountOnEnter
                    >
                      <MuiTableRow>
                        <MuiTableCell colSpan={3}>
                          <MuiTypography
                            variant={"h2"}
                            align={"center"}
                            className={"text-muted"}
                          >
                            {"No results to show"}
                          </MuiTypography>
                        </MuiTableCell>
                      </MuiTableRow>
                    </MuiGrow>
                  ) : (
                    <Fragment>
                      {userState.results.map((item, index) => {
                        if (item.isApproved) {
                          return (
                            <MuiTableRow key={index}>
                              <TableCell>{item._id}</TableCell>
                              <TableCell> {item.session}</TableCell>
                              <TableCell>{item.term}</TableCell>

                              <TableCell>
                                <MuiButton
                                  color={"primary"}
                                  variant={"contained"}
                                  size={"small"}
                                  onClick={() => {
                                    history.push(`/results/${item._id}`);
                                  }}
                                >
                                  Check Result
                                </MuiButton>
                              </TableCell>
                            </MuiTableRow>
                          );
                        }
                      })}
                    </Fragment>
                  )}
                </TableBody>
              </Table>
            </div>
          </MuiGrid>
        </MuiGrid>
      </div>
    </MuiGrid>
  );
}
