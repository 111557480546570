import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
  Divider as MuiDivider,
  List as MuiList,
  ListItem as MuiListItem,
  Button as MuiButton,
  Grow as MuiGrow,
} from "@material-ui/core";

import { useUserState, useGlobalState } from "../../../../../../hooks";
import img15 from "../../../../../../../assets/images/img15.png";
import {
  requestAnnouncements,
  requestClasses,
} from "../../../../../../utils/apiRequests";
import { _NWR4434R, _FJ4R34R } from "./script";
import { Term, Session, ResultMetrics, Class, Profile } from "./components";
import { LayoutV1 } from "../../../../components";
import { Breadcrumbs } from "../../components";
export default function Overview(props) {
  const history = useHistory();
  const { globalState, setGlobalState } = useGlobalState();
  const { userState } = useUserState();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [announcements, setAnnouncements] = useState(globalState.announcements);

  useEffect(() => {
    requestAnnouncements.findAllAnnouncements().then((data) => {
      setGlobalState((globalState) => {
        return {
          ...globalState,
          announcements: [...data],
        };
      });
    });
    requestClasses.findAllClasses().then((data) => {
      setGlobalState((globalState) => {
        return {
          ...globalState,
          classes: [...data],
        };
      });
    });
  }, []);

  return (
    <LayoutV1>
      <div className={"bg-[#ebeef2] w-[100%] p-[30px]"}>
        <MuiGrid container={true} spacing={2}>
          <ResultMetrics />
          <Class />
          <Term />
          <Session />

          <Profile />
          <MuiGrid item={true} xs={12} sm={12} md={12} lg={4} xl={4}>
            <MuiPaper className={"h-100"}>
              <MuiGrid className={"d-flex flex-column h-100"}>
                <MuiGrid className={"pt-4 px-3"}>
                  <MuiTypography variant={"h5"} color={"primary"}>
                    {"Announcements"}
                  </MuiTypography>
                  <MuiDivider />
                </MuiGrid>

                <MuiGrid
                  item={true}
                  xs={12}
                  className={"py-4 px-3 flex-grow-1"}
                >
                  <MuiList className={"p-0 m-0"}>
                    {globalState.announcements.length === 0 ? (
                      <MuiTypography variant={"h5"} className={"text-muted"}>
                        {"No announcements to display"}
                      </MuiTypography>
                    ) : (
                      globalState.announcements?.map((item, index) => {
                        if (index < 3) {
                          return (
                            <MuiGrow in={true} timeout={500 + index * 500}>
                              <MuiListItem
                                key={index}
                                className="d-block w-100 p-0"
                              >
                                <MuiTypography className={"w-100 fs-18-px"}>
                                  {item.title}
                                </MuiTypography>
                                <MuiTypography
                                  className={"w-100 fs-14-px text-muted"}
                                >
                                  {item.message}
                                </MuiTypography>
                              </MuiListItem>
                            </MuiGrow>
                          );
                        }
                      })
                    )}
                  </MuiList>
                </MuiGrid>
                <MuiGrid className={"w-100"}>
                  <MuiButton
                    className={"w-100"}
                    onClick={() => {
                      history.push("/student/dashboard/announcements");
                    }}
                  >
                    {"View All announcements"}
                  </MuiButton>
                </MuiGrid>
              </MuiGrid>
            </MuiPaper>
          </MuiGrid>
        </MuiGrid>

        <MuiGrid container={true} item={true}>
          {_FJ4R34R.map((item, index) => {
            return (
              <MuiGrow key={index} in={true} timeout={700 + index * 200}>
                <MuiGrid
                  container={true}
                  item={true}
                  lg={2}
                  md={3}
                  sm={4}
                  xs={6}
                  className={"p-3"}
                >
                  <MuiButton
                    className={"p-3 w-100"}
                    variant={"contained"}
                    onClick={() =>
                      item.handleClick(userState, globalState, history)
                    }
                  >
                    <div>
                      <img
                        src={item.image}
                        height={100}
                        width={100}
                        alt={"classrooom button icon"}
                      />
                      <MuiTypography className={"py-3"}>
                        {item.label}
                      </MuiTypography>
                    </div>
                  </MuiButton>
                </MuiGrid>
              </MuiGrow>
            );
          })}
        </MuiGrid>
      </div>
    </LayoutV1>
  );
}
