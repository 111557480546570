import { useState } from "react";
import { Switch, Route } from "react-router-dom";

import {
  Overview,
  Classes,
  Students,
  Teachers,
  Results,
  FeesAndInvoices,
  Administrators,
  Announcements,
  Student,
  Administrator,
  Teacher,
  Profile,
  Messages,
} from "./pages";

export default function Dashboard() {
  return (
    <Switch>
      <Route path={"/admin/dashboard/overview"} component={Overview} />
      <Route path={"/admin/dashboard/classes"} component={Classes} />
      <Route path={"/admin/dashboard/students"} component={Students} />
      <Route path={"/admin/dashboard/student"} component={Student} />
      <Route path={"/admin/dashboard/teachers"} component={Teachers} />
      <Route path={"/admin/dashboard/teacher"} component={Teacher} />
      <Route path={"/admin/dashboard/results"} component={Results} />
      <Route
        path={"/admin/dashboard/administrators"}
        component={Administrators}
      />
      <Route
        path={"/admin/dashboard/administrator"}
        component={Administrator}
      />
      <Route
        path={"/admin/dashboard/fees-and-invoices"}
        component={FeesAndInvoices}
      />
      <Route
        path={"/admin/dashboard/announcements"}
        component={Announcements}
      />
      <Route path={"/admin/dashboard/profile"} component={Profile} />

      <Route path={"/admin/dashboard/messages"} component={Messages} />
    </Switch>
  );
}
