import GlobalState from "./globalState";
import AdministratorState from "./administratorState";
import ComponentsState from "./components";

const state = {
  GlobalState,
  AdministratorState,
  ComponentsState,
};

export default state;
