import {
  AdministratorActivityLogs,
  ManageStudents,
  StudentMetrics,
  AdministratorMetrics,
  TeacherMetrics,
  ClassMetrics,
  ResultMetrics,
  Term,
  Session,
  Greeter,
  ManageResults,
  TeacherActivityLogs,
  StudentActivityLogs,
  TicketMetrics,
  ManageTeachers,
} from "./components";
import { Grid as MuiGrid } from "@material-ui/core";
import { LayoutV1 } from "../../../../components";

export default function Overview() {
  return (
    <LayoutV1>
      <div className={"w-[100%] p-[10px] md:p-[30px]"}>
        <MuiGrid container={true} spacing={2}>
          <Greeter />
          <MuiGrid item={true} xs={12} md={6}>
            <MuiGrid container={true} spacing={2}>
              <StudentMetrics />
              <AdministratorMetrics />
              <TeacherMetrics />
              <ClassMetrics />
              <ResultMetrics />
              <TicketMetrics />
            </MuiGrid>
          </MuiGrid>
          <Term />
          <Session />
        </MuiGrid>
      </div>
      <div className={"w-[100%] p-[10px] md:p-[30px]"}>
        <MuiGrid container={true} spacing={2}>
          <MuiGrid item={true} xs={12} md={12} lg={4}>
            <MuiGrid container={true} spacing={2}>
              <AdministratorActivityLogs />
              <TeacherActivityLogs />
              <StudentActivityLogs />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item={true} xs={12} md={12} lg={8}>
            <MuiGrid container={true} spacing={2}>
              <ManageStudents />
              <ManageResults />
              <ManageTeachers />
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </div>
    </LayoutV1>
  );
}
