const baseURL = `${process.env.REACT_APP_API_BASE_URL}`;

export function getAllClasses() {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/classes`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function getAllStudents() {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/students`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestAllResults() {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/results`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function getAllTeachers() {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/teachers`)
      .then((res) => resolve(res.json()))
      .catch((err) => reject(err));
  });
}

export function requestStudentLogin(body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/students/login`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => resolve(res.json()))
      .catch((err) => reject(err));
  });
}

export function requestTeacherLogin(body) {
  console.log(body);
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/teachers/signin`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => resolve(res.json()))
      .catch((err) => reject(err));
  });
}

export function requestAdminLogin(body) {
  console.log(body);
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/admins/signin`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => resolve(res.json()))
      .catch((err) => reject(err));
  });
}

export function requestStudent(studentID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/students/${studentID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestTeacher(teacherID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/teachers/${teacherID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestAddTeacher(body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/teachers/addTeacher`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => resolve(res.json()))
      .catch((err) => reject(err));
  });
}

export function requestAddStudent(body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/students/addStudent`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => resolve(res.json()))
      .catch((err) => reject(err));
  });
}

export function requestAddClass(body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/classes/addClass`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => resolve(res.json()))
      .catch((err) => reject(err));
  });
}

export function requestImageUpload(body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/images/upload/`, {
      method: "POST",
      body: body,
    })
      .then((res) => resolve(res.json()))
      .catch((err) => reject(err));
  });
}

export function requestAddResult(body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/results`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => resolve(res.json()))
      .catch((err) => reject(err));
  });
}

export function requestResult(resultID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/results/${resultID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestClass(classID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/classes/${classID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestActivateStudent(studentID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/students/activate/${studentID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestDeactivateStudent(studentID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/students/deactivate/${studentID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestActivateTeacher(teacherID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/teachers/activate/${teacherID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestDeactivateTeacher(teacherID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/teachers/deactivate/${teacherID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestDeleteStudent(studentID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/students/delete/${studentID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestDeleteTeacher(teacherID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/teachers/delete/${teacherID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestDeleteClass(classID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/classes/delete/${classID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestDeleteResult(resultID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/results/delete/${resultID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestApproveResult(resultID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/results/${resultID}/approve`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestAssignStudentToClass(body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/classes/assignstudent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestAssignTeacherToClass(body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/classes/assignteacher`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestEditStudent(studentID, body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/students/${studentID}/profile/edit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestEditTeacher(teacherID, body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/teachers/${teacherID}/profile/edit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestEditResult(resultID, body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/results/${resultID}/edit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestClass__addSubject(classID, body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/classes/${classID}/subjects/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestClass__deleteSubject(classID, body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/classes/${classID}/subjects/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestResult__byClass(classID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/results/class/${classID}`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestResult__downloadResult(resultID) {
  fetch(`${baseURL}/results/${resultID}/download`)
    .then((res) => res.blob())
    .then((data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `result.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
}

export function requestClass__removeStudent(classID, body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/classes/${classID}/students/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestClass__removeTeacher(classID, body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/classes/${classID}/teachers/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestFee__getAllFees() {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/fees`, {
      method: "GET",
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestFee__createFee(body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/fees`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestFee__deleteFee(feeID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/fees/${feeID}`, {
      method: "DELETE",
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function request__getAllInvoices() {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/invoices`, {
      method: "GET",
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function request__getInvoiceByID(invoiceID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/invoices/${invoiceID}`, {
      method: "GET",
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function request__createInvoice(body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/invoices`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function request__deleteInvoice(invoiceID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/invoices/${invoiceID}`, {
      method: "DELETE",
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestStudent__getAllNotifications(studentID) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/students/${studentID}/notifications`, {
      method: "GET",
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function request__editNotification(notificationID, body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/notifications/${notificationID}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

// REFACTORING STARTS HERE

export const requestResults = {
  countAllResults() {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/results/count-all`, { method: "GET" })
        .then((response) => {
          if (response.status === 200) {
            response.text().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((error) => reject(error));
    });
  },
  findAllResults(options) {
    return new Promise((resolve, reject) => {
      fetch(
        `${baseURL}/results/find-all?paginate=${
          options.paginate || false
        }&count=${options?.count || 10}&page=${options?.page || "1"}`,
        { method: "GET" }
      )
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((error) => reject(error));
    });
  },
  uploadResult(data) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/results/count-all`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 200) {
          } else if (response.status === 400) {
          }
        })
        .catch((error) => reject(error));
    });
  },
  result: {
    approve(resultID) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/results/${resultID}/approve`, {
          method: "GET",
        })
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else if (response.status === 400) {
              response.text().then((error) => reject(error));
            }
          })
          .catch((error) => reject(error));
      });
    },
    delete(resultID) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/results/${resultID}/delete`, {
          method: "GET",
        })
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else if (response.status === 400) {
              response.text().then((error) => reject(error));
            }
          })
          .catch((error) => reject(error));
      });
    },
  },
};

export const requestClasses = {
  countAllClasses() {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/classes/count-all`, { method: "GET" })
        .then((response) => {
          if (response.status === 200) {
            response.text().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .then((response) => console.log(response))
        .catch((err) => console.error(err));
    });
  },
  findAllClasses(options) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/classes/find-all-deprecated`, { method: "GET" })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((error) => reject(error));
    });
  },
  findClassByID(ID) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/classes/find-one?by=ID&ID=${ID}`, { method: "GET" })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((error) => reject(error));
    });
  },
  students: {
    assign(classID, studentID) {
      return new Promise((resolve, reject) => {
        fetch(
          `${baseURL}/classes/${classID}/students/assign?studentID=${studentID}`,
          { method: "GET" }
        )
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else if (response.status === 400) {
              response.text().then((data) => reject(data));
            }
          })
          .catch((error) => reject(error));
      });
    },
    deassign(classID, studentID) {
      return new Promise((resolve, reject) => {
        fetch(
          `${baseURL}/classes/${classID}/students/deassign?studentID=${studentID}`,
          { method: "GET" }
        )
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else if (response.status === 400) {
              response.text().then((data) => reject(data));
            }
          })
          .catch((error) => reject(error));
      });
    },
  },
  teachers: {
    assign(classID, emailAddress) {
      return new Promise((resolve, reject) => {
        fetch(
          `${baseURL}/classes/${classID}/teachers/assign?emailAddress=${emailAddress}`,
          { method: "GET" }
        )
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else if (response.status === 400) {
              response.text().then((data) => reject(data));
            }
          })
          .catch((error) => reject(error));
      });
    },
    deassign(classID, teacherID) {
      return new Promise((resolve, reject) => {
        fetch(
          `${baseURL}/classes/${classID}/teachers/deassign?teacherID=${teacherID}`,
          { method: "GET" }
        )
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else if (response.status === 400) {
              response.text().then((data) => reject(data));
            }
          })
          .catch((error) => reject(error));
      });
    },
  },
  subjects: {
    add(classID, subject) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/classes/${classID}/subjects/add?subject=${subject}`, {
          method: "GET",
        })
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else if (response.status === 400) {
              response.text().then((data) => reject(data));
            }
          })
          .catch((error) => reject(error));
      });
    },
    remove(classID, subject) {
      return new Promise((resolve, reject) => {
        fetch(
          `${baseURL}/classes/${classID}/subjects/remove?subject=${subject}`,
          { method: "GET" }
        )
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else if (response.status === 400) {
              response.text().then((data) => reject(data));
            }
          })
          .catch((error) => reject(error));
      });
    },
  },
};

export const requestStudents = {
  countAllStudents() {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/students/count-all`, { method: "GET" })
        .then((response) => {
          if (response.status === 200) {
            response.text().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((err) => console.error(err));
    });
  },
  findAllStudents(options) {
    return new Promise((resolve, reject) => {
      fetch(
        `${baseURL}/students/find-all?paginate=${
          options.paginate.toString() || false
        }&count=${options?.count?.toString() || 10}&page=${
          options?.page?.toString() || "1"
        }`,
        { method: "GET" }
      )
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((err) => console.error(err));
    });
  },
  search(data) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/students/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((err) => console.error(err));
    });
  },
  findOneStudent(options) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/students/find-one?`, { method: "GET" })
        .then((response) => response.json())
        .then((response) => console.log(response))
        .catch((err) => console.error(err));
    });
  },
  findStudentByID(options) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/students/find-one-deprecated?by=ID&ID=${options.ID}`, {
        method: "GET",
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((error) => reject(error));
          }
        })
        .catch((error) => reject(error));
    });
  },
  createStudent(body) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/students/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((error) => reject(error));
    });
  },
  results: {
    findAllResults(studentID) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/students/${studentID}/results-deprecated`, {
          method: "GET",
        })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((data) => resolve(data));
            } else if (response.status === 400) {
              response.text().then((data) => reject(data));
            }
          })
          .catch((error) => reject(error));
      });
    },
  },
};

export const requestTeachers = {
  countAllTeachers() {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/teachers/count-all`, { method: "GET" })
        .then((response) => {
          if (response.status === 200) {
            response.text().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .then((response) => console.log(response))
        .catch((err) => console.error(err));
    });
  },
  findAllTeachers() {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/teachers/find-all-deprecated`, { method: "GET" })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((err) => console.error(err));
    });
  },
  findTeacher: {
    byID(teacherID) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/teachers/find-one-deprecated?by=ID&ID=${teacherID}`, {
          method: "GET",
        })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((data) => resolve(data));
            }
            if (response.status === 400) {
              response.text().then((error) => reject(error));
            }
          })
          .catch((err) => console.error(err));
      });
    },
    byEmailAddress(emailAddress) {
      return new Promise((resolve, reject) => {
        fetch(
          `${baseURL}/teachers/find-oneby=emailAddress&emailAddress=${emailAddress}`,
          {
            method: "GET",
          }
        )
          .then((response) => {
            if (response.status === 200) {
              response.json().then((data) => resolve(data));
            }
            if (response.status === 400) {
              response.text().then((error) => reject(error));
            }
          })
          .catch((err) => console.error(err));
      });
    },
  },
  createTeacher(body) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/teachers/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((error) => reject(error));
    });
  },
  deleteTeacher: {
    byID(teacherID) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/teachers/delete-one?by=ID&ID=${teacherID}`, {
          method: "GET",
        })
          .then((response) => {
            if (response.status === 200) {
              resolve();
            }
            if (response.status === 400) {
              response.text().then((error) => reject(error));
            }
          })
          .catch((err) => console.error(err));
      });
    },
    byEmailAddress(emailAddress) {
      return new Promise((resolve, reject) => {
        fetch(
          `${baseURL}/teachers/delete-oneby=emailAddress&emailAddress=${emailAddress}`,
          {
            method: "GET",
          }
        )
          .then((response) => {
            if (response.status === 200) {
              resolve();
            }
            if (response.status === 400) {
              response.text().then((error) => reject(error));
            }
          })
          .catch((err) => console.error(err));
      });
    },
  },
};

export const requestAuth = {
  signIn: {
    student(studentID, password) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/auth/sign-in/student-deprecated`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            studentID: studentID,
            password: password,
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((data) => resolve(data));
            } else if (response.status === 400) {
              response.text().then((data) => reject(data));
            }
          })
          .catch((err) => reject(err));
      });
    },
    teacher(emailAddress, password) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/auth/sign-in/teacher-deprecated`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: emailAddress,
            password: password,
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((data) => resolve(data));
            } else if (response.status === 400) {
              response.text().then((data) => reject(data));
            }
          })
          .catch((error) => reject(error));
      });
    },
    admin(emailAddress, password) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/auth/sign-in/admin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            emailAddress: emailAddress,
            password: password,
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((data) => resolve(data));
            } else if (response.status === 400) {
              response.text().then((data) => reject(data));
            }
          })
          .catch((err) => reject(err));
      });
    },
  },
};

export const requestAnnouncements = {
  findAllAnnouncements() {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/announcements/find-all`, { method: "GET" })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((error) => reject(error));
    });
  },
  findAnnouncements(by, visibility) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/announcements/find?by=${by}&visibility=${visibility}`, {
        method: "GET",
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((error) => reject(error));
    });
  },
  createAnnouncement(body) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/announcements/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((error) => reject(error));
    });
  },
  deleteAnnouncementByID(announcementID) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/announcements/delete-one?ID=${announcementID}`, {
        method: "GET",
      })
        .then((response) => {
          if (response.status === 200) {
            resolve();
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((error) => reject(error));
    });
  },
};

export const requestAdministrators = {
  findAllAdministrators() {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/admins/find-all`, { method: "GET" })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((err) => console.error(err));
    });
  },
  createAdministrator(body) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/admins/create-deprecated`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.status === 200) {
            resolve();
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((error) => reject(error));
    });
  },
};

export const requestInvoices = {
  findAllInvoices() {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/invoices/find-all?paginate=false`, { method: "GET" })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((data) => reject(data));
          }
        })
        .catch((err) => console.error(err));
    });
  },
  countAllInvoices() {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/invoices/count-all`, { method: "GET" })
        .then((response) => {
          if (response.status === 200) {
            response.text().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((error) => reject(error));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  findInvoiceByID(invoiceID) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/invoices/find-one?by=ID&ID=${invoiceID}`, {
        method: "GET",
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => resolve(data));
          } else if (response.status === 400) {
            response.text().then((error) => reject(error));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createInvoice(data) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/invoices/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 200) {
            resolve();
          } else if (response.status === 400) {
            response.text().then((error) => reject(error));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteInvoiceByID(invoiceID) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/invoices/${invoiceID}/delete`, {
        method: "GET",
      })
        .then((response) => {
          if (response.status === 200) {
            resolve();
          } else if (response.status === 400) {
            response.text().then((error) => reject(error));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  templates: {
    findAllInvoices() {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/invoices/templates/find-all?paginate=false`, {
          method: "GET",
        })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((data) => resolve(data));
            } else if (response.status === 400) {
              response.text().then((data) => reject(data));
            }
          })
          .catch((err) => console.error(err));
      });
    },
    countAllInvoices() {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/invoices/templates/count-all`, { method: "GET" })
          .then((response) => {
            if (response.status === 200) {
              response.text().then((data) => resolve(data));
            } else if (response.status === 400) {
              response.text().then((error) => reject(error));
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    findInvoiceByID(invoiceID) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/invoices/templates/find-one?by=ID&ID=${invoiceID}`, {
          method: "GET",
        })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((data) => resolve(data));
            } else if (response.status === 400) {
              response.text().then((error) => reject(error));
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    createInvoice(data) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/invoices/templates/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else if (response.status === 400) {
              response.text().then((error) => reject(error));
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteInvoiceByID(invoiceID) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/invoices/templates/${invoiceID}/delete`, {
          method: "GET",
        })
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else if (response.status === 400) {
              response.text().then((error) => reject(error));
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export const termRequests = {
  getTerm() {
    return new Promise((resolve, reject) => {});
  },
};

export function requestTerm() {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/term`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestSetTerm(body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/term/set-deprecated`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestSession() {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/session`)
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function requestSetSession(body) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/session/set`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

//REFACTORING ENDS HERE
