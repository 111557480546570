import { useState } from "react";
import {
  Button as MuiButton,
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@mui/material";

export default function PasswordAndSecurityTab() {
  return (
    <div className={"w-[100%] p-[20px]"}>
      <MuiGrid container={true} spacing={2}>
        <MuiGrid item={true} xs={4}>
          <MuiTypography>New password</MuiTypography>
          <MuiTextField variant={"outlined"} className={"w-[100%]"} />
        </MuiGrid>
        <MuiGrid item={true} xs={4}>
          <MuiTypography>Confirm new password</MuiTypography>
          <MuiTextField variant={"outlined"} className={"w-[100%]"} />
        </MuiGrid>
      </MuiGrid>
      <div className={"mt-[50px]"}>
        <MuiButton variant={"contained"}>{"Save changes"}</MuiButton>
      </div>
    </div>
  );
}
