import { useEffect, useState } from "react";
import { render } from "react-dom";
import { useHistory } from "react-router-dom";
import { requestClasses } from "../../../../../../utils/apiRequests";
import { useGlobalState } from "../../../../../../hooks";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Typography as MuiTypography,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
} from "@material-ui/core";

import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Inject,
  Page,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";

import { LayoutV1 } from "../../../../components";

export default function Classes() {
  const history = useHistory();
  const { globalState, getClasses, setGlobalState } = useGlobalState();
  const [state, setState] = useState({
    classes: {
      count: 0,
      data: [],
    },
  });
  const [classes, setClasses] = useState(globalState.classes);
  useEffect(() => {
    if (globalState.classes.length === 0) {
      requestClasses
        .findAllClasses()
        .then((data) => {
          setGlobalState((globalState) => {
            return {
              ...globalState,
              classes: [...data],
            };
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, []);

  useEffect(() => {
    setClasses((classes) => {
      return [...globalState.classes];
    });
  }, [globalState]);

  const [menuState, setMenuState] = useState({
    isOpen: false,
    userState: null,
    anchorElement: null,
    handleOpen: function (classState, anchorElement) {
      setMenuState({
        ...menuState,
        isOpen: true,
        classState: classState,
        anchorElement: anchorElement,
      });
    },
    handleClose: function () {
      setMenuState({
        ...menuState,
        isOpen: false,
        anchorElement: null,
      });
    },
  });

  return (
    <LayoutV1>
      <MuiGrid container={true}>
        <MuiGrid container={true} className={"px-3 my-3"}>
          <MuiTypography variant={"h3"}>{"Classes"}</MuiTypography>
        </MuiGrid>
        <MuiGrid
          container={true}
          justifyContent={"flex-end"}
          className={"px-3 my-3"}
        >
          <MuiButton
            size={"small"}
            variant={"contained"}
            color={"primary"}
            className={"me-2 rounded-0"}
            disableElevation={true}
            onClick={() => {
              requestClasses
                .findAllClasses()
                .then((data) => {
                  setGlobalState((globalState) => {
                    return {
                      ...globalState,
                      classes: [...data],
                    };
                  });
                })
                .catch((error) => {
                  alert(error);
                });
            }}
          >
            {"Refresh"}
          </MuiButton>
          <MuiButton
            size={"small"}
            variant={"contained"}
            color={"secondary"}
            className={"rounded-0"}
            disableElevation={true}
            onClick={() => {
              history.push("/classes/new");
            }}
          >
            {"create"}
          </MuiButton>
        </MuiGrid>
        <MuiGrid className={"px-3"}>
          <GridComponent
            dataSource={classes}
            toolbar={["Search"]}
            allowPaging={true}
            allowFiltering={true}
            allowSorting={true}
            pageSettings={{ pageSize: 20 }}
            filterSettings={{ ignoreAccent: true, type: "Excel" }}
            sortSettings={{
              columns: [{ field: "status", direction: "Ascending" }],
            }}
            queryCellInfo={(args) => {
              if (args.column.headerText === "Actions") {
                render(
                  <MuiButton
                    className={"fw-bold fs-5 p-0"}
                    onClick={(event) => {
                      menuState.handleOpen(args.data, event.currentTarget);
                    }}
                  >
                    {"..."}
                  </MuiButton>,
                  args.cell
                );
              }
            }}
          >
            <ColumnsDirective>
              <ColumnDirective field={"_id"} headerText={"ID"} />
              <ColumnDirective field={"name"} headerText={"Name"} />
              <ColumnDirective
                field={"students.length"}
                headerText={"Number Of Students"}
              />
              <ColumnDirective
                field={"teachers.length"}
                headerText={"Number Of Teachers"}
              />
              <ColumnDirective
                field={"subjects.length"}
                headerText={"Number Of Subjects"}
              />
              <ColumnDirective headerText={"Actions"} textAlign={"center"} />
            </ColumnsDirective>
            <Inject services={[Page, Filter, Sort, Toolbar]} />
          </GridComponent>
        </MuiGrid>

        <MuiMenu
          id="menu-appbar"
          anchorEl={menuState.anchorElement}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={menuState.isOpen}
          onClose={menuState.handleClose}
        >
          <MuiMenuItem
            onClick={() => {
              menuState.handleClose();
              history.push(
                `/class/dashboard?ID=${menuState.classState._id}&accessMode=administrator`
              );
            }}
          >
            View Dashboard
          </MuiMenuItem>
          {/*<MuiMenuItem
          onClick={() => {
            requestDeleteClass(
              menuState.userState._id.replaceAll("/", "-")
            ).then((data) => {
              if (data.error) {
                getClasses();
              } else {
                alert("successfully deleted");
                getClasses();
              }
            });
            menuState.handleClose();
          }}
        >
          Delete
        </MuiMenuItem>*/}
        </MuiMenu>
      </MuiGrid>
    </LayoutV1>
  );
}
