export const list1 = [
  {
    label: "Overview",
    path: "/teacher/dashboard/overview",
  },
  // {
  //   label: "Notifications",
  //   path: "/teacher/dashboard/notifications",
  // },
  {
    label: "Announcements",
    path: "/teacher/dashboard/announcements",
  },
  // {
  //   label: "Profile",
  //   path: "/teacher/dashboard/profile",
  // },
  // {
  //   label: "Messages",
  //   path: "/teacher/dashboard/messages",
  // },
  // {
  //   label: "Sign Out",
  //   path: "/teacher/dashboard/sign-out",
  // },
];
