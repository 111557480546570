import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Grid as MuiGrid,
  CircularProgress as MuiCircularProgress,
  Typography as MuiTypography,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Button as MuiButton,
} from "@material-ui/core";

import { Edit as MuiEditIcon } from "@material-ui/icons";

import { Header } from "../../components";
import img15 from "../../../../../../../assets/images/img15.png";
import {
  useUserState,
  useGlobalState,
  useWidth,
} from "../../../../../../hooks";
import { requestStudents } from "../../../../../../utils/apiRequests";

export default function Root() {
  const params = useParams();
  const history = useHistory();
  const width = useWidth();
  const { userState, setUserState } = useUserState();
  const { globalState, getClasses } = useGlobalState();
  const [profileData, setProfileData] = useState({});
  useEffect(() => {
    const ID = new URLSearchParams(window.location.search).get("ID");
    requestStudents
      .findStudentByID({ ID: ID })
      .then((data) => {
        setProfileData({ ...data });
      })
      .catch((error) => {
        alert(`Error: ${error}`);
      });
  }, []);

  return (
    <MuiGrid
      container={true}
      justify={"center"}
      className={"position-absolute w-100 pb-5"}
    >
      <Header title={"Profile"} />
      {profileData ? (
        <MuiGrid
          container={true}
          item={true}
          xl={8}
          lg={9}
          md={10}
          sm={11}
          xs={11}
          className={"bg-light mt-4"}
        >
          <MuiGrid
            container={true}
            item={true}
            xl={5}
            lg={5}
            className={"px-3 my-4"}
          >
            <MuiGrid
              container={true}
              item={true}
              xl={7}
              lg={7}
              md={5}
              sm={8}
              xs={11}
            >
              <div
                className={"w-100 rounded-circle overflow-hidden"}
                style={{
                  minWidth: "300px",
                  maxWidth: "300px",
                  height: "300px",
                }}
              >
                <img
                  src={`${
                    process.env.REACT_APP_API_BASE_URL
                  }/uploads/images/profile/${new URLSearchParams(
                    window.location.search
                  ).get("ID")}.jpg`}
                  onError={(event) => {
                    event.target.src = img15;
                  }}
                  alt={"avatar"}
                  className={"w-100"}
                />
              </div>
            </MuiGrid>

            <MuiGrid
              container={true}
              item={true}
              xl={8}
              lg={8}
              md={5}
              sm={8}
              xs={11}
              direction={"column"}
              justify={"center"}
            >
              <MuiTypography variant={"h3"} noWrap={true}>
                {`${
                  profileData.firstName?.charAt(0).toUpperCase() +
                  profileData.firstName?.slice(1)
                } ${
                  profileData.lastName?.charAt(0).toUpperCase() +
                  profileData.lastName?.slice(1)
                }`}
              </MuiTypography>
              <MuiTypography variant={"h4"} className={"text-primary"}>
                {`${profileData.role}`}
              </MuiTypography>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid
            container={true}
            item={true}
            xl={7}
            lg={7}
            className={"my-4"}
          >
            <MuiTable>
              <MuiTableBody>
                <MuiTableRow>
                  <MuiTableCell>Student ID</MuiTableCell>
                  <MuiTableCell>{profileData.id}</MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>First Name</MuiTableCell>
                  <MuiTableCell>{profileData.firstName}</MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>Last Name</MuiTableCell>
                  <MuiTableCell>{profileData.lastName}</MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>Gender</MuiTableCell>
                  <MuiTableCell>{profileData.gender}</MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>Class</MuiTableCell>
                  <MuiTableCell>
                    {profileData.class
                      ? profileData.populatedFields.class.name
                      : "Not Assigned"}
                  </MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>Email Address</MuiTableCell>
                  <MuiTableCell>{profileData.emailAddress}</MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>Phone Number</MuiTableCell>
                  <MuiTableCell>{profileData.phoneNumber}</MuiTableCell>
                </MuiTableRow>
                <MuiTableRow>
                  <MuiTableCell>Home Address</MuiTableCell>
                  <MuiTableCell>{profileData.homeAddress}</MuiTableCell>
                </MuiTableRow>
              </MuiTableBody>
            </MuiTable>
            <MuiGrid className={"my-5 w-100"}>
              <MuiButton
                variant={"contained"}
                startIcon={<MuiEditIcon />}
                onClick={() => history.push(`/profile/update`)}
              >
                {"Update Profile"}
              </MuiButton>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      ) : (
        <MuiGrid>
          <MuiCircularProgress />
        </MuiGrid>
      )}
    </MuiGrid>
  );
}
