import { useState, useEffect } from "react";
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Paper as MuiPaper,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  Button as MuiButton,
  Divider as MuiDivider,
  TextareaAutosize as MuiTextareaAutosize,
  IconButton as MuiIconButton,
  FormHelperText as MuiFormHelperText,
  Snackbar as MuiSnackbar,
  OutlinedInput as MuiOutlinedInput,
  InputAdornment as MuiInputAdornment,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import { Header } from "./components";

import {
  Visibility as MuiVisibilityIcon,
  VisibilityOff as MuiVisibilityOffIcon,
} from "@material-ui/icons";

import { useGlobalState } from "../../../../hooks";

import { requestStudents } from "../../../../utils/apiRequests";
import { validationSchema } from "./validationSchema";

export default function Create() {
  const [formState, setFormState] = useState({});
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const { globalState, getClasses } = useGlobalState();

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
    handleClose: function () {
      setSnackbarState({
        ...snackbarState,
        open: false,
        message: "",
        severity: "",
      });
    },
    handleOpen: function (message, severity) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: message,
        severity: severity,
      });
    },
  });

  const [submitProgress, setSubmitProgress] = useState(false);

  useEffect(() => {
    getClasses();
  }, []);
  return (
    <MuiGrid
      container={true}
      justify={"center"}
      className={"position-absolute w-100 h-100 py-5 my-5"}
    >
      <Header title={"Create Student"} />
      <MuiGrid item={true} xs={10} sm={9} md={8} lg={7} xl={6}>
        <MuiPaper className={"p-3"}>
          <form onSubmit={(e) => e.preventDefault()}>
            <MuiTypography variant={"h5"}>Please fill correctly</MuiTypography>
            <MuiDivider className={"mb-5"} />
            <MuiTypography className={"mt-3"}>First Name</MuiTypography>
            <MuiTextField
              name={"firstName"}
              fullWidth={true}
              size={"small"}
              type={"text"}
              variant={"outlined"}
              onChange={(e) => {
                formState["firstName"] = e.target.value;
                setFormState({ ...formState });
              }}
            />
            <MuiTypography className={"mt-3"}>Last Name</MuiTypography>
            <MuiTextField
              name={"lastName"}
              fullWidth={true}
              size={"small"}
              type={"text"}
              variant={"outlined"}
              onChange={(e) => {
                formState["lastName"] = e.target.value;
                setFormState({ ...formState });
              }}
            />
            <MuiTypography className={"mt-3"}>Email Address</MuiTypography>
            <MuiTextField
              name={"emailAddress"}
              fullWidth={true}
              size={"small"}
              type={"text"}
              variant={"outlined"}
              onChange={(e) => {
                formState["emailAddress"] = e.target.value;
                setFormState({ ...formState });
              }}
            />
            <MuiTypography className={"mt-3"}>Phone Number</MuiTypography>
            <MuiTextField
              name={"phoneNumber"}
              fullWidth={true}
              size={"small"}
              type={"tel"}
              variant={"outlined"}
              onChange={(e) => {
                formState["phoneNumber"] = e.target.value;
                setFormState({ ...formState });
              }}
            />
            <MuiTypography className={"mt-3"}>Gender</MuiTypography>
            <MuiSelect
              size={"small"}
              fullWidth={true}
              variant={"outlined"}
              onChange={(e) => {
                formState["gender"] = e.target.value;
                setFormState({ ...formState });
              }}
            >
              <MuiMenuItem value={"male"}>Male</MuiMenuItem>
              <MuiMenuItem value={"female"}>Female</MuiMenuItem>
            </MuiSelect>
            <MuiTypography className={"mt-3"}>Class</MuiTypography>
            <MuiSelect
              fullWidth={true}
              variant={"outlined"}
              onChange={(e) => {
                formState["class"] = e.target.value;
                setFormState({ ...formState });
              }}
            >
              {globalState.classes.length === 0 ? (
                <MuiMenuItem>No classes to show</MuiMenuItem>
              ) : (
                globalState.classes.map((item, index) => {
                  return (
                    <MuiMenuItem key={index} value={item._id}>
                      {item.name}
                    </MuiMenuItem>
                  );
                })
              )}
            </MuiSelect>
            <MuiTypography className={"mt-3"}>Address</MuiTypography>
            <MuiTextareaAutosize
              className={"w-100 form-control"}
              onChange={(e) => {
                formState["address"] = e.target.value;
                setFormState({ ...formState });
              }}
            />
            <MuiTypography className={"mt-3"}>Upload Image</MuiTypography>
            <MuiTextField
              variant={"outlined"}
              type={"file"}
              multiple={false}
              onChange={(e) => {
                const reader = new FileReader();
                reader.readAsBinaryString(e.target.files[0]);
                reader.onload = function () {
                  formState["image"] = btoa(reader.result);
                  setFormState({ ...formState });
                };
              }}
            />
            <MuiFormHelperText>
              *image must be in portrait mode
            </MuiFormHelperText>
            <MuiTypography className={"mt-3"}>Password</MuiTypography>
            <MuiOutlinedInput
              className={"my-2"}
              placeholder={"Password"}
              fullWidth={true}
              type={passwordVisibility ? "text" : "password"}
              onChange={(e) => {
                formState["password"] = e.target.value;
                setFormState({ ...formState });
              }}
              endAdornment={
                <MuiInputAdornment position="end">
                  <MuiIconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setPasswordVisibility(!passwordVisibility);
                    }}
                  >
                    {passwordVisibility ? (
                      <MuiVisibilityOffIcon />
                    ) : (
                      <MuiVisibilityIcon />
                    )}
                  </MuiIconButton>
                </MuiInputAdornment>
              }
            />
            <MuiTypography className={"mt-3"}>Repeat Password</MuiTypography>
            <MuiOutlinedInput
              className={"my-2"}
              placeholder={"Repeat Password"}
              fullWidth={true}
              type={passwordVisibility ? "text" : "password"}
              onChange={(e) => {
                formState["repeatPassword"] = e.target.value;
                setFormState({ ...formState });
              }}
              endAdornment={
                <MuiInputAdornment position="end">
                  <MuiIconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setPasswordVisibility(!passwordVisibility);
                    }}
                  >
                    {passwordVisibility ? (
                      <MuiVisibilityOffIcon />
                    ) : (
                      <MuiVisibilityIcon />
                    )}
                  </MuiIconButton>
                </MuiInputAdornment>
              }
            />
            <MuiDivider className={"my-4"} />
            <MuiButton
              className={"mb-3"}
              type={"submit"}
              color={"primary"}
              variant={"contained"}
              onClick={async () => {
                setSubmitProgress(true);
                const result = await validationSchema
                  .validate(formState, { abortEarly: false })
                  .catch((err) => {
                    return {
                      error: {
                        message: err.inner[0].message,
                      },
                    };
                  });
                if (result.error) {
                  snackbarState.handleOpen(`${result.error.message}`, "error");
                  setTimeout(() => {
                    setSubmitProgress(false);
                  }, 2000);
                } else {
                  if (formState.password !== formState.repeatPassword) {
                    snackbarState.handleOpen(`Password doesn't match`, "error");
                    return false;
                  }

                  requestStudents
                    .createStudent(formState)
                    .then((data) => {
                      snackbarState.handleOpen(
                        `Registration successful. Student's ID is ${data._id}. Please save it.`,
                        "success"
                      );
                      setTimeout(() => {
                        setSubmitProgress(false);
                      }, 2000);
                    })
                    .catch((error) => {
                      console.log(error);
                      snackbarState.handleOpen(error, "error");
                      setTimeout(() => {
                        setSubmitProgress(false);
                      }, 2000);
                    });
                }
              }}
            >
              {submitProgress ? (
                <MuiCircularProgress size={24} color="secondary" />
              ) : (
                "Submit"
              )}
            </MuiButton>
          </form>
        </MuiPaper>
      </MuiGrid>

      <MuiSnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarState.open}
        message={snackbarState.message}
        action={
          <>
            <MuiButton
              color="secondary"
              size="small"
              onClick={snackbarState.handleClose}
            >
              {"CLOSE"}
            </MuiButton>
          </>
        }
      />
    </MuiGrid>
  );
}
