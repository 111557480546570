import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Header, Spreadsheet } from "./components";
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Paper as MuiPaper,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  Button as MuiButton,
  Divider as MuiDivider,
  Snackbar as MuiSnackbar,
  TextareaAutosize as MuiTextareaAutosize,
  CircularProgress as MuiCircularProgress,
  Chip as MuiChip,
} from "@material-ui/core";

import { Spreadsheet as RsSpreadsheet } from "react-spreadsheet";

import {
  requestAddResult,
  requestTerm,
  requestSession,
  requestClasses,
} from "../../../../utils/apiRequests";
import { useGlobalState, useUserState } from "../../../../hooks";

import { _A1, _A2, _B1 } from "./scripts";

import services from "../../../../services";

export default function Create() {
  const history = useHistory();
  const { globalState, setGlobalState } = useGlobalState();
  const { userState } = useUserState();
  const [_class, setClass] = useState({ subjects: [] });
  const [student, setStudent] = useState({});
  const [session, setSession] = useState("");
  const [term, setTerm] = useState("");
  const [resultType, setResultType] = useState("midTerm");
  const [spreadsheetData, setSpreadsheetData] = useState({
    midTerm: {
      columns: ["Subject", "Score"],
      rows: [],
    },
    endOfTerm: {
      columns: ["Subject", "Test", "Exam"],
      rows: [],
    },
  });

  const [electivesData, setElectivesData] = useState([]);

  const [submitProgress, setSubmitProgress] = useState(false);

  const [formState, setFormState] = useState(_A2);

  const [wizardDialog, setWizardDialog] = useState(_A1);

  useEffect(() => {
    if (resultType === "endOfTerm") {
      services.api.results
        .find(
          {
            term: formState.term,
            session: formState.session,
            class: new URLSearchParams(window.location.search).get("classID"),
            student: new URLSearchParams(window.location.search).get(
              "studentID"
            ),
            type: "midTerm",
          },
          1,
          10
        )
        .then((data) => {
          if (data.data.results.length < 1) {
            alert(
              `Unable to find ${formState.term} ${formState.session} half term results for this student`
            );
            return false;
          }
          if (data.data.results.length > 1) {
            alert(
              "this student has duplicate results for the same term and session!"
            );
            return false;
          }
          console.log(data.data.results[0].scoreSheet);
          setSpreadsheetData((spreadsheetData) => {
            let newState = { ...spreadsheetData };

            newState["endOfTerm"].rows = JSON.parse(
              data.data.results[0].scoreSheet
            )
              .splice(1)
              .map((item) => item.splice(0, 2));

            return { ...newState };
          });

          setFormState((formState) => {
            let newState = { ...formState };
            let scoreSheet;

            scoreSheet = JSON.parse(data.data.results[0].scoreSheet).map(
              (item) => item.splice(0, 2)
            );

            newState.scoreSheet = JSON.stringify(scoreSheet);
            return { ...newState };
          });
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }, [resultType]);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
    handleClose: function () {
      setSnackbarState({
        ...snackbarState,
        open: false,
        message: "",
        severity: "",
      });
    },
    handleOpen: function (message, severity) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        message: message,
        severity: severity,
      });
    },
  });

  useEffect(() => {
    requestClasses
      .findAllClasses()
      .then((data) => {
        setGlobalState((globalState) => {
          return {
            ...globalState,
            classes: [...data],
          };
        });
      })
      .catch((error) => alert(error));
  }, []);

  useEffect(() => {
    const classID = new URLSearchParams(window.location.search).get("classID");
    if (classID) {
      requestClasses.findClassByID(classID).then((data) => {
        setClass((_class) => {
          return {
            ..._class,
            ...data,
          };
        });
      });
    }
  }, []);

  useEffect(() => {
    if (
      spreadsheetData.endOfTerm.rows.length === 0 ||
      spreadsheetData.midTerm.rows.length === 0
    ) {
      setSpreadsheetData((spreadsheetData) => {
        return {
          ...spreadsheetData,
          endOfTerm: {
            ...spreadsheetData.endOfTerm,
            rows: [
              ..._class?.subjects?.map((item) => {
                return [{ value: item }, { value: "" }];
              }),
            ],
          },
          midTerm: {
            ...spreadsheetData.midTerm,
            rows: [
              ..._class?.subjects?.map((item) => {
                return [{ value: item }, { value: "" }];
              }),
            ],
          },
        };
      });
    }
  }, [_class]);

  useEffect(() => {
    _B1(
      userState,
      history,
      requestTerm,
      requestSession,
      setTerm,
      setFormState,
      setSession
    );
  }, []);

  useEffect(() => {
    const student = new URLSearchParams(window.location.search).get(
      "studentID"
    );
    const _class = new URLSearchParams(window.location.search).get("classID");
    if (formState.class !== _class && formState.student !== student) {
      setFormState((formState) => {
        return {
          ...formState,
          class: _class,
          student: student,
        };
      });
    }
  }, [formState]);

  useEffect(() => {
    let data = [];
    electivesData.forEach((elective, index) => {
      data.push({ title: elective[0].value, grade: elective[1].value });
    });
    setFormState({ ...formState, electives: data });
  }, [electivesData]);

  useEffect(() => {
    console.log("formState", formState);
  }, [formState]);

  return (
    <MuiGrid container={true} justifyContent={"center"} className={"mt-5 pt-5"}>
      <Header />
      <MuiGrid item={true} xs={11} sm={9} md={8} lg={7} xl={6}>
        <MuiPaper elevation={8} className={"mb-5 px-3 py-4"}>
          <MuiTypography variant={"h5"}>
            Please fill in all details correctly
          </MuiTypography>
          <MuiDivider className={"mb-3"} />
          <MuiTypography className={"mt-3"}>School</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={formState.school}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, school: e.target.value });
            }}
          >
            <MuiMenuItem value={"Park British School"}>
              {"Park British School"}
            </MuiMenuItem>
            <MuiMenuItem value={"Park Royal College"}>
              {"Park Royal College"}
            </MuiMenuItem>
          </MuiSelect>
          <MuiTypography className={"mt-3"}>Title</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={formState.title}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, title: e.target.value });
            }}
          >
            <MuiMenuItem
              value={"PROVISIONAL RESULTS INFORMATION FOR SPRING TERM TEST"}
            >
              {"PROVISIONAL RESULTS INFORMATION FOR SPRING TERM TEST"}
            </MuiMenuItem>
            <MuiMenuItem
              value={
                "PROVISIONAL RESULTS INFORMATION FOR SPRING TERM EXAMINATION"
              }
            >
              {"PROVISIONAL RESULTS INFORMATION FOR SPRING TERM EXAMINATION"}
            </MuiMenuItem>
            <MuiMenuItem
              value={"PROVISIONAL RESULTS INFORMATION FOR AUTUMN TERM TEST"}
            >
              {"PROVISIONAL RESULTS INFORMATION FOR AUTUMN TERM TEST"}
            </MuiMenuItem>
            <MuiMenuItem
              value={
                "PROVISIONAL RESULTS INFORMATION FOR AUTUMN TERM EXAMINATION"
              }
            >
              {"PROVISIONAL RESULTS INFORMATION FOR AUTUMN TERM EXAMINATION"}
            </MuiMenuItem>
            <MuiMenuItem
              value={"PROVISIONAL RESULTS INFORMATION FOR SUMMER TERM TEST"}
            >
              {"PROVISIONAL RESULTS INFORMATION FOR SUMMER TERM TEST"}
            </MuiMenuItem>
            <MuiMenuItem
              value={
                "PROVISIONAL RESULTS INFORMATION FOR SUMMER TERM EXAMINATION"
              }
            >
              {"PROVISIONAL RESULTS INFORMATION FOR SUMMER TERM EXAMINATION"}
            </MuiMenuItem>
          </MuiSelect>

          <MuiTypography className={"mt-2"}>Student ID</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            value={formState.student}
            disabled
          />
          <MuiTypography className={"mt-3"}>Class</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            disabled={true}
            value={formState.class}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, class: e.target.value });
            }}
          >
            {globalState.classes.map((item, index) => {
              return (
                <MuiMenuItem key={index} value={item._id}>
                  {item.name}
                </MuiMenuItem>
              );
            })}
          </MuiSelect>
          <MuiTypography className={"mt-3"}>Session</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            value={formState.session}
          />
          <MuiTypography className={"mt-3"}>Term</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            value={formState.term}
          />
          <MuiDivider />
          <MuiTypography className={"mt-3"}>Grading Scale</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={formState.gradingScale}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, gradingScale: e.target.value });
            }}
          >
            <MuiMenuItem value={"foundation"}>{"Foundation"}</MuiMenuItem>
            <MuiMenuItem value={"pbs"}>{"PBS"}</MuiMenuItem>
            <MuiMenuItem value={"prc"}>{"PRC-junior"}</MuiMenuItem>
            <MuiMenuItem value={"igcse"}>{"IGCSE"}</MuiMenuItem>
            <MuiMenuItem value={"waec"}>{"WAEC"}</MuiMenuItem>
            <MuiMenuItem value={"aslevel"}>{"AS LEVEL"}</MuiMenuItem>
          </MuiSelect>
          <MuiTypography className={"mt-3"}>Result Type</MuiTypography>
          <MuiSelect
            fullWidth={true}
            id="class-select"
            value={formState.type}
            variant={"outlined"}
            onChange={(e) => {
              setFormState({ ...formState, type: e.target.value });
              setResultType(e.target.value);
            }}
          >
            <MuiMenuItem value={"midTerm"}>{"Mid-Term"}</MuiMenuItem>
            <MuiMenuItem value={"endOfTerm"}>{"Full-Term"}</MuiMenuItem>
          </MuiSelect>

          {_class ? (
            <Spreadsheet
              scoresheetData={spreadsheetData}
              setScoresheetData={(data) => {
                setFormState({
                  ...formState,
                  scoreSheet: JSON.stringify(data),
                });
                setSpreadsheetData((spreadsheetData) => {
                  console.log("SPSHEET DATA", data.slice(1));
                  let newState = { ...spreadsheetData };
                  newState.endOfTerm.rows = data.splice(1);
                  return { ...newState };
                });
              }}
              type={resultType}
            />
          ) : (
            ""
          )}

          {_class
            ? _class?.subjects?.map((item, index) => {
                return (
                  <MuiChip
                    key={index}
                    label={item}
                    className={"me-1 mb-2"}
                    onDelete={() => {
                      setSpreadsheetData((spreadsheetData) => {
                        return {
                          ...spreadsheetData,
                          endOfTerm: {
                            ...spreadsheetData.endOfTerm,
                            rows: [
                              ...spreadsheetData.endOfTerm.rows.filter(
                                (item2, index2) => {
                                  console.log("ITEM", item);
                                  console.log(item2);
                                  return item2[0].value !== item;
                                }
                              ),
                              // ..._class?.subjects?.map((item) => {
                              //   return [{ value: item }, { value: "" }];
                              // }),
                            ],
                          },
                          midTerm: {
                            ...spreadsheetData.midTerm,
                            rows: [
                              ..._class?.subjects?.map((item) => {
                                return [{ value: item }, { value: "" }];
                              }),
                            ],
                          },
                        };
                      });

                      setClass((_class) => {
                        return {
                          ..._class,
                          subjects: _class.subjects.filter(
                            (value, index2, arr) => {
                              return value !== item;
                            }
                          ),
                        };
                      });
                    }}
                  />
                );
              })
            : ""}
          <MuiDivider />
          <MuiTypography variant={"h5"}>Electives</MuiTypography>
          <RsSpreadsheet
            data={electivesData}
            onChange={(data) => {
              setElectivesData(data);
            }}
            columnLabels={["Title", "Grade"]}
          />
          <MuiGrid className={"my-2"}>
            <MuiButton
              size={"small"}
              onClick={() => {
                setElectivesData([
                  ...electivesData,
                  [{ value: "" }, { value: "" }],
                ]);
              }}
            >
              {"Add"}
            </MuiButton>
            <MuiButton
              size={"small"}
              onClick={() => {
                let state = [...electivesData];
                state.pop();
                setElectivesData([...state]);
              }}
            >
              {"Remove"}
            </MuiButton>
          </MuiGrid>

          <MuiDivider />
          <MuiTypography className={"mt-3"}>Teacher's Remark</MuiTypography>
          <MuiTextareaAutosize
            className={"w-100 form-control"}
            variant={"outlined"}
            type={"text"}
            onChange={(e) => {
              setFormState({ ...formState, teachersRemark: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Comments</MuiTypography>
          <MuiTextareaAutosize
            className={"w-100 form-control"}
            variant={"outlined"}
            type={"text"}
            onChange={(e) => {
              setFormState({ ...formState, comments: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Principal's Remark</MuiTypography>
          <MuiTextareaAutosize
            className={"w-100 form-control"}
            variant={"outlined"}
            type={"text"}
            disabled={userState.role !== "admin" ? true : false}
            onChange={(e) => {
              setFormState({ ...formState, principalsRemark: e.target.value });
            }}
          />
          <MuiTypography className={"mt-3"}>Resumption Date</MuiTypography>
          <MuiTextField
            fullWidth={true}
            variant={"outlined"}
            type={"text"}
            onChange={(e) => {
              setFormState({ ...formState, resumptionDate: e.target.value });
            }}
          />
          <MuiDivider />
          <MuiButton
            className={"mt-3"}
            color={"primary"}
            variant={"contained"}
            disabled={submitProgress}
            onClick={() => {
              setSubmitProgress(true);
              services.api.results
                .upload({
                  ...formState,
                })
                .then((data) => {
                  snackbarState.handleOpen(
                    `Result succesfully uploaded.`,
                    "success"
                  );
                  setTimeout(() => {
                    setSubmitProgress(false);
                  }, 2000);
                })
                .catch((error) => {
                  alert(error.message);
                  setTimeout(() => {
                    setSubmitProgress(false);
                  }, 2000);
                });
            }}
          >
            {submitProgress ? (
              <MuiCircularProgress size={24} color="secondary" />
            ) : (
              "upload"
            )}
          </MuiButton>
        </MuiPaper>
      </MuiGrid>

      <MuiSnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarState.open}
        message={snackbarState.message}
        action={
          <>
            <MuiButton
              color="secondary"
              size="small"
              onClick={snackbarState.handleClose}
            >
              {"CLOSE"}
            </MuiButton>
          </>
        }
      />
      {/* <Wizard /> */}
    </MuiGrid>
  );
}
