import React from "react";
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  IconButton as MuiIconButton,
} from "@material-ui/core";

import {
  ExpandMore as MuiExpandMoreIcon,
  Delete as MuiDeleteIcon,
  Edit as MuiEditIcon,
} from "@material-ui/icons";

export default function Fee(props) {
  return (
    <MuiGrid className={"py-2 px-4"}>
      <MuiAccordion>
        <MuiAccordionSummary
          className={"bg-light"}
          expandIcon={<MuiExpandMoreIcon />}
        >
          <MuiTypography className={"fw-bold"}>{props.title}</MuiTypography>
        </MuiAccordionSummary>
        <MuiAccordionDetails className={"p-0"}>
          <MuiGrid className={"w-100"}>
            {props.hasPriceVariety === true && (
              <MuiGrid className={"p-3"}>
                {props.prices.map((price, index) => {
                  return (
                    <MuiTypography key={index} className={"text-muted"}>
                      {`${price.variant} -:- ${price.price}`}
                    </MuiTypography>
                  );
                })}
              </MuiGrid>
            )}
            {props.hasPriceVariety === false && (
              <MuiGrid className={"p-3"}>
                <MuiTypography className={"text-muted"}>
                  {`Price -:- ${props.price}`}
                </MuiTypography>
              </MuiGrid>
            )}
            <MuiGrid className={"w-100"}>
              <MuiIconButton
                variant={"outlined"}
                color={"secondary"}
                onClick={() => {
                  props.deleteFee(props._id);
                }}
              >
                <MuiDeleteIcon className={"fs-6"} />
              </MuiIconButton>
              <MuiIconButton variant={"contained"}>
                <MuiEditIcon className={"fs-6"} />
              </MuiIconButton>
            </MuiGrid>
          </MuiGrid>
        </MuiAccordionDetails>
      </MuiAccordion>
    </MuiGrid>
  );
}
