import { useState, useEffect } from "react";
import {
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from "@material-ui/core";
import services from "../../../../../../../services";

export default function ResultFilter(props) {
  const [classes, setClasses] = useState([]);
  useEffect(() => {
    services.api.classes
      .findAll(1, 100)
      .then((data) => {
        setClasses(data.data.classes);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return (
    <div className={"pl-[25px] pr-[25px] py-[10px]"}>
      <MuiSelect defaultValue={" "} size={"small"} variant={"outlined"}>
        <MuiMenuItem
          onClick={() => {
            props.setPage(1);
            props.setFilters((filters) => {
              let newState = { ...filters };
              delete newState.isApproved;
              return { ...newState };
            });
          }}
          value={" "}
        >
          All
        </MuiMenuItem>
        <MuiMenuItem
          onClick={() => {
            props.setMode("filter");
            props.setPage(1);
            props.setFilters((filters) => {
              let newState = { ...filters };
              newState.isApproved = true;
              return { ...newState };
            });
          }}
          value={"Approved"}
        >
          Approved
        </MuiMenuItem>

        <MuiMenuItem
          onClick={() => {
            props.setMode("filter");
            props.setPage(1);
            props.setFilters((filters) => {
              let newState = { ...filters };
              newState.isApproved = false;
              return { ...newState };
            });
          }}
          value={"Not Approved"}
        >
          Not Approved
        </MuiMenuItem>
      </MuiSelect>
      <MuiSelect
        defaultValue={" "}
        size={"small"}
        variant={"outlined"}
        className={"mx-[10px]"}
      >
        <MuiMenuItem
          onClick={() => {
            props.setPage(1);
            props.setFilters((filters) => {
              let newState = { ...filters };
              delete newState.class;
              return { ...newState };
            });
          }}
          value={" "}
        >
          All
        </MuiMenuItem>
        {classes.map((_class, index) => {
          return (
            <MuiMenuItem
              onClick={() => {
                props.setMode("filter");
                props.setPage(1);
                props.setFilters((filters) => {
                  let newState = { ...filters };
                  newState.class = _class._id;
                  return { ...newState };
                });
              }}
              value={_class.name}
            >
              {_class.name}
            </MuiMenuItem>
          );
        })}
      </MuiSelect>
      <MuiSelect
        defaultValue={" "}
        size={"small"}
        variant={"outlined"}
        className={"mx-[10px]"}
      >
        <MuiMenuItem
          onClick={() => {
            props.setPage(1);
            props.setFilters((filters) => {
              let newState = { ...filters };
              delete newState.term;
              return { ...newState };
            });
          }}
          value={" "}
        >
          All
        </MuiMenuItem>
        {["summer", "autumn", "spring"].map((term, index) => {
          return (
            <MuiMenuItem
              className={"text-capitalize"}
              onClick={() => {
                props.setMode("filter");
                props.setPage(1);
                props.setFilters((filters) => {
                  let newState = { ...filters };
                  newState.term = term;
                  return { ...newState };
                });
              }}
              value={term}
            >
              {term}
            </MuiMenuItem>
          );
        })}
      </MuiSelect>
      <MuiSelect
        defaultValue={" "}
        size={"small"}
        variant={"outlined"}
        className={"mx-[10px]"}
      >
        <MuiMenuItem
          onClick={() => {
            props.setPage(1);
            props.setFilters((filters) => {
              let newState = { ...filters };
              delete newState.type;
              return { ...newState };
            });
          }}
          value={" "}
        >
          All
        </MuiMenuItem>
        <MuiMenuItem
          className={"text-capitalize"}
          onClick={() => {
            props.setMode("filter");
            props.setPage(1);
            props.setFilters((filters) => {
              let newState = { ...filters };
              newState.type = "midTerm";
              return { ...newState };
            });
          }}
          value={"Half Term"}
        >
          {"Half Term"}
        </MuiMenuItem>
        <MuiMenuItem
          className={"text-capitalize"}
          onClick={() => {
            props.setMode("filter");
            props.setPage(1);
            props.setFilters((filters) => {
              let newState = { ...filters };
              newState.type = "endOfTerm";
              return { ...newState };
            });
          }}
          value={"Full Term"}
        >
          {"Full Term"}
        </MuiMenuItem>
      </MuiSelect>
    </div>
  );
}
